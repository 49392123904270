import React, { Component } from 'react'
import UploadItem from 'components/UploadItem'
import Upload from 'components/Upload'
import Axios from 'axios'
import SlideShowModal from 'components/SlideShowModal'
import { Text, Heading } from 'grommet'
import { logError } from 'utils/sentry-log'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import styled from 'styled-components'
import { arrayMove } from 'utils/utils'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 260px));
  grid-gap: 4px;
  padding-bottom: 8px;
`

const SortableUploadItem = SortableElement(props => <UploadItem {...props} />)

const SortContainer = SortableContainer(({ children, className }) => {
  return <Grid columns={['medium', 'medium', 'medium']}>{children}</Grid>
})

class UploadView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isUploading: false,
      isError: false,
      file: null,
      isModalOpen: false,
      sliderUrl: null
    }

    this.onChange = this.onChange.bind(this)
    this.removeImage = this.removeImage.bind(this)
  }

  async onChange(files) {
    try {
      const { type } = this.props
      const fallbackType = type || 'image'
      this.setState({ isUploading: files && files.length, isError: false, file: files })

      const formData = new FormData()
      for (let file of files) {
        formData.append('file', file, file.name)
      }

      const response = await Axios.post(`/api/v1/file?type=${fallbackType}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })

      if (!response || !response.data || response.status !== 200) {
        throw new Error('wrong image format')
      }
      const uploadedFiles = response.data
      const images = [...this.props.images].concat(uploadedFiles)
      this.props.onChange(images, files, false)
      this.setState({ isUploading: false, file: null })
    } catch (e) {
      this.setState({ isError: true, isUploading: false, file: null })
    }
  }

  async removeImage(file) {
    try {
      const response = await Axios.delete(`/api/v1/file/${file._id}`).then(response => response.data)
      if (!response || response.error) throw new Error('[FILE-DELETE] error request')

      const images = [...this.props.images]
      const newImages = images.filter(image => image._id !== file._id)
      this.props.onChange(newImages, file, true)
    } catch (e) {
      logError(e)
    }
  }

  shouldCancelStart(e) {
    let { target } = e
    let iteration = 0
    while (iteration < 5) {
      if (target && target.classList && target.classList.contains('fa-trash')) {
        return true
      }
      iteration++
      target = target.parentNode
    }
    return false
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const images = [...this.props.images]
    const newImages = arrayMove(images, oldIndex, newIndex)
    this.props.onChange(newImages)
  }

  render() {
    const { isUploading, isError, file, isModalOpen, sliderUrl } = this.state
    const { isReadOnly, images, title, className, id, isWeb, filetypes, children, description } = this.props
    const fileTypes = filetypes || ['image/*']
    const imageUrls = images.map(img => img.url)

    let imagesList = images
      ? images.map((image, i) => {
          return (
            <SortableUploadItem
              disabled={isReadOnly}
              index={i}
              onClick={() => this.setState({ sliderUrl: image.url, isModalOpen: true })}
              key={i}
              image={image}
              onDelete={this.removeImage}
              isEdit={!isReadOnly}
            />
          )
        })
      : []

    if (isError) {
      imagesList.push(<UploadItem key={imagesList.length} isError={isError} image={file} />)
    } else if (isUploading && isUploading > 0) {
      for (let i = 0; i < isUploading; i++) {
        imagesList.push(<UploadItem key={Math.random()} isLoading={true} />)
      }
    }

    return (
      <div className={`w-100 ${className}`}>
        {isWeb && title ? <Heading level="3">{title}</Heading> : <h3>{title ? title : 'Immagini'}</h3>}
        {description && <Text size="small">{description}</Text>}
        {imagesList.length > 0 && (
          <SortContainer axis="xy" shouldCancelStart={this.shouldCancelStart} onSortEnd={this.onSortEnd} className="d-flex flex-wrap">
            {imagesList}
          </SortContainer>
        )}
        {!isReadOnly && (
          <Upload id={id} filetypes={fileTypes} isWeb={isWeb} onChange={this.onChange}>
            {children}
          </Upload>
        )}
        {isModalOpen && (
          <SlideShowModal
            images={imageUrls}
            isOpen={isModalOpen}
            start={imageUrls.indexOf(sliderUrl)}
            onClose={() => this.setState({ isModalOpen: false })}
          />
        )}
      </div>
    )
  }
}

export default UploadView
