import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import { Box, Heading, Text } from 'grommet'
import { ReactSVG } from 'react-svg'

/**
 * Simple page the user is redirected to after receiving the magic link email
 */
class Confirm extends Component {
  componentDidMount() {
    if (this.props.auth.isWebsiteAuth) {
      this.props.history.push('/account')
    }
  }

  render() {
    const { intl } = this.props

    return (
      <Box className="main-container" align="center" direction="column">
        <ReactSVG
          src="/website/mailsent.svg"
          className="d-flex justify-content-center mt-5"
          beforeInjection={svg => svg.setAttribute('style', 'width: 200px; height: 200px')}
        ></ReactSVG>
        <Heading className="pt-4" level="3">
          {intl.formatMessage({ id: 'website.confirm.title' })}
        </Heading>
        <Text className="pt-2 w-75 text-center">{intl.formatMessage({ id: 'website.confirm.text' })}</Text>
      </Box>
    )
  }
}

Confirm.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(injectIntl(withRouter(Confirm)))
