import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { FormattedMessage } from 'react-intl'
import RadioButton from './RadioButton'

class OrientationSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orientation: props.selected
    }
  }

  onChange = e => {
    const { onChange } = this.props
    const { orientation } = this.state
    const index = orientation.indexOf(e.target.value)
    if (index > -1) {
      orientation.splice(index, 1)
    } else {
      orientation.push(e.target.value)
    }

    if (onChange) {
      this.props.onChange(orientation)
    }
    this.setState({ orientation })
  }

  render() {
    const { orientation } = this.state
    const isReadOnly = this.props.isReadOnly

    return (
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column justify-content-around align-items-center">
          <RadioButton
            value="NW"
            checked={orientation.indexOf('NW') > -1}
            style={{ marginRight: '-40px' }}
            onChange={this.onChange}
            label={<FormattedMessage id="admin.property.orientation.NW" />}
            labelSize={isReadOnly ? 'xsmall' : null}
            disabled={isReadOnly}
          />
          <RadioButton
            name="orientation"
            value="W"
            checked={orientation.indexOf('W') > -1}
            onChange={this.onChange}
            label={<FormattedMessage id="admin.property.orientation.W" />}
            labelSize={isReadOnly ? 'xsmall' : null}
            disabled={isReadOnly}
          />
          <RadioButton
            value="SW"
            checked={orientation.indexOf('SW') > -1}
            style={{ marginRight: '-40px' }}
            onChange={this.onChange}
            label={<FormattedMessage id="admin.property.orientation.SW" />}
            labelSize={isReadOnly ? 'xsmall' : null}
            disabled={isReadOnly}
          />
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <RadioButton
            value="N"
            onChange={this.onChange}
            checked={orientation.indexOf('N') > -1}
            label={<FormattedMessage id="admin.property.orientation.N" />}
            labelSize={isReadOnly ? 'xsmall' : null}
            disabled={isReadOnly}
          />
          <ReactSVG src="/website/compass.svg" beforeInjection={svg => isReadOnly && svg.setAttribute('style', 'width: 128px; height: 128px;')} />
          <RadioButton
            value="S"
            checked={orientation.indexOf('S') > -1}
            onChange={this.onChange}
            label={<FormattedMessage id="admin.property.orientation.S" />}
            labelSize={isReadOnly ? 'xsmall' : null}
            disabled={isReadOnly}
          />
        </div>
        <div className="d-flex flex-column justify-content-around align-items-center">
          <RadioButton
            value="NE"
            checked={orientation.indexOf('NE') > -1}
            onChange={this.onChange}
            style={{ marginLeft: '-40px' }}
            label={<FormattedMessage id="admin.property.orientation.NE" />}
            labelSize={isReadOnly ? 'xsmall' : null}
            disabled={isReadOnly}
          />
          <RadioButton
            value="E"
            checked={orientation.indexOf('E') > -1}
            onChange={this.onChange}
            label={<FormattedMessage id="admin.property.orientation.E" />}
            labelSize={isReadOnly ? 'xsmall' : null}
            disabled={isReadOnly}
          />
          <RadioButton
            value="SE"
            checked={orientation.indexOf('SE') > -1}
            onChange={this.onChange}
            style={{ marginLeft: '-40px' }}
            label={<FormattedMessage id="admin.property.orientation.SE" />}
            labelSize={isReadOnly ? 'xsmall' : null}
            disabled={isReadOnly}
          />
        </div>
      </div>
    )
  }
}

export default OrientationSelector
