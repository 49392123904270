import React from 'react'
import { injectIntl } from 'react-intl'
import NumberFormat from 'react-number-format'

/**
 * This component preformats the input to make it easier to enter and validate
 * currency amounts
 *
 * @class CurrencyInput
 */
class CurrencyInput extends React.Component {
  onChange = values => {
    const { value } = values
    this.props.onChange(parseInt(value))
  }

  render() {
    const { name, disabled, value, readOnly } = this.props

    return (
      <NumberFormat
        className="ui-currency-input"
        name={name}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        thousandSeparator={'.'}
        decimalSeparator={','}
        suffix={'€'}
        decimalScale={2}
        allowNegative={false}
        allowLeadingZeros={false}
        onValueChange={this.onChange}
      />
    )
  }
}

export default injectIntl(CurrencyInput)
