import React from 'react'
import { Box, Text } from 'grommet'
import { FormattedNumber, FormattedMessage } from 'react-intl'
import { ReactSVG } from 'react-svg'
import LazyLoad from 'react-lazy-load'

import PropertyCardRooms from './PropertyCardRooms'
import ImageLoader from './ImageLoader'

export default (props) => {
  if (!props.property) {
    return <></>
  }

  const { images, type, price, rooms, rent, location, hotel, mainImage, property_id } = props.property

  const numOfRooms = type.type === 'residential' ? rooms.living + rooms.bed + rooms.dining : type.id === 'hotel' ? hotel.sleepingPlaces : null
  const numOfBaths = type.type === 'residential' ? rooms.wc + rooms.bath : null

  // set the language to 'it' if it is 'en' because we display italian municipality names when en is selected
  const language = props.language === 'en' ? 'it' : props.language

  const mainCardImage = (mainImage ? mainImage.url : images.length > 0 && images[0].url) || '/website/sale.svg'

  return (
    <Box
      className={`ui-property-card__box w-100 mb-2 ${props.state} ${props.className}`}
      direction="row"
      onClick={() => props.onClick && props.onClick()}
    >
      <LazyLoad width={400} height={250}>
        <ImageLoader fit={images.length > 0 ? 'cover' : 'contain'} src={mainCardImage} alt={'Immobilie'} height={250} width={300} />
      </LazyLoad>
      <Box pad="small" width="100%">
        <div className="d-flex justify-content-between flex-column flex-grow-1">
          {props.client && (
            <Text size="medium">
              Cliente: {props.client.name} {props.client.familyName}
            </Text>
          )}
          <Text size="medium" className="d-flex flex-grow-1 justify-content-end">
            Nr. {property_id}
          </Text>

          <div className="d-flex justify-content-end flex-column flex-grow-1">
            <Text size="large" weight="bold" className="pb-1">
              {type.name[props.language]}
            </Text>
            <div className="w-100 d-flex justify-content-between pb-2">
              <Text size="medium">
                {location.address.municipality ? location.address.municipality.name[language] + '' : ''},{' '}
                {location.zone ? location.zone.properties.name[props.language] : ''}
              </Text>
            </div>
            <div className="ui-property-card__rooms w-100 d-flex flex-wrap">
              <PropertyCardRooms property={props.property} />
            </div>
            <div className="w-100 d-flex justify-content-between">
              <Text size="small">
                <FormattedMessage id={`admin.property.rent.${rent}`} />
              </Text>
            </div>
            <div className="w-100 d-flex justify-content-between pt-1">
              <Text size="xlarge" color="brand" className="d-flex align-items-center price">
                <FormattedNumber value={price || 0} format="EUR" />
              </Text>
              <div className="d-flex flex-row">
                {numOfRooms > 0 && (
                  <div className="d-flex flex-row pr-2 align-items-center">
                    <ReactSVG
                      src="/website/room.svg"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 24px; height: 24px')}
                      className="mr-2"
                    />
                    <Text>{numOfRooms}</Text>
                  </div>
                )}
                {numOfBaths > 0 && (
                  <div className="d-flex flex-row pr-2 align-items-center">
                    <ReactSVG
                      src="/website/bath.svg"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 24px; height: 24px')}
                      className="mr-2"
                    />
                    <Text>{numOfBaths}</Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  )
}
