import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Heading } from 'grommet'
import { Container, Row } from 'react-bootstrap'

import { authFetch } from 'utils/website-utils'
import SearchBox from 'components/SearchBox'
import LoadingProperties from 'components/LoadingProperties'
import PropertyCard from 'components/PropertyCard'
import Pagination from 'components/Pagination'

class Search extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoadingProperties: [],
      properties: [],
      meta: null
    }
  }

  componentDidMount() {
    this.loadProperties()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.loadProperties()
    }
  }

  async loadProperties() {
    this.setState({ isLoadingProperties: true })

    const url = this.props.location.search
    const params = new URLSearchParams(url)
    params.append('lang', this.props.intl.locale)
    const query = params.toString()

    const properties = await authFetch('get', `/api/v1/properties-search?${query}`)
      .then(res => {
        if (res && res.data) {
          this.setState({ meta: res.data.meta })
          return res.data.result
        } else throw new Error()
      })
      .catch(e => this.setState({ isLoadingProperties: false }))

    const cards =
      properties && properties.length
        ? properties.map(property => (
            <PropertyCard key={property._id} property={property} language={this.props.intl.locale} isSearch={true}></PropertyCard>
          ))
        : []
    this.setState({ isLoadingProperties: false, properties: cards })
  }

  render() {
    const { properties, isLoadingProperties, meta } = this.state

    return (
      <Container className="search">
        <SearchBox></SearchBox>
        <Row style={{ margin: 0 }}>
          {isLoadingProperties ? (
            <LoadingProperties />
          ) : properties.length > 0 ? (
            properties
          ) : (
            <Heading level="3" style={{ margin: 'auto' }} color="light-5">
              <FormattedMessage id="website.search.empty"></FormattedMessage>
            </Heading>
          )}
        </Row>
        {meta && meta.total > 12 && <Pagination meta={meta} />}
      </Container>
    )
  }
}

Search.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(injectIntl(withRouter(Search)))
