import React, { Component } from 'react'
import { Box, RadioButton } from 'grommet'
import { injectIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'
import { Row } from 'react-bootstrap'

class PropertyTypeSelector extends Component {
  constructor(props) {
    super(props)

    this.state = { value: props.value && props.value.id }
  }

  onChange = event => {
    const { types, onChange } = this.props
    const type = types.find(type => type.id === event.target.value)
    this.setState({ value: type.id })
    return onChange(type)
  }

  render() {
    const { intl, types, disabled } = this.props
    const { value } = this.state

    const disabledTypes = (disabled || []).map(type => type.id)

    const filteredTypes = types.filter(type => disabledTypes.indexOf(type.id) < 0)
    const typeItems = filteredTypes.map(type => (
      <Box pad="small" direction="column" align="center" key={type.id} className="ui-property-type">
        <ReactSVG src={`/website/types/${type.id}.svg`} className="mb-2" />
        <RadioButton
          name="type"
          value={type.id}
          onChange={this.onChange}
          label={<span className="ellipsis-wrap">{type.name[intl.locale]}</span>}
          checked={value === type.id}
        />
      </Box>
    ))

    return <Row className="ui-property-type-selector w-100 justify-content-around">{typeItems}</Row>
  }
}

export default injectIntl(PropertyTypeSelector)
