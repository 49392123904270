import React, { Component } from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import { TextInput, Box, Text, Button } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { injectIntl, FormattedMessage } from 'react-intl'

import { authFetch } from 'utils/website-utils'
import PolygonMapDraw from './PolygonMapDraw'
import Map from './Map'
import { logError } from 'utils/sentry-log'

class LocationSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: '',
      value: '',
      location: props.location,
      locationSuggestions: [],
      draw: false
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.onSelectLocation = this.onSelectLocation.bind(this)
    this.handleLocationDelele = this.handleLocationDelele.bind(this)
  }

  async handleInputChange(event) {
    try {
      const { intl } = this.props
      const value = event.target.value
      this.setState({ value })
      const response = await authFetch('get', `/api/v1/locations/search?q=${value}&lang=${intl.locale}`)
      const locationSuggestions = response.data || []
      this.setState({ locationSuggestions })
    } catch (e) {
      logError(e)
      this.setState({ locationSuggestions: [] })
    }
  }

  onSelectLocation(e) {
    const { locations, onLocationsChange } = this.props
    const { locationSuggestions } = this.state

    const location = locationSuggestions.find(sug => sug.id === e.suggestion.value)
    this.setState({ value: '', locationSuggestions: [] })
    const newLocations = [...locations, location]
    onLocationsChange(newLocations)
  }

  onAddDrawing = ({ name, geoJson }) => {
    const { locations, onLocationsChange } = this.props

    const location = { type: 'polygon', name, geoJson }
    const newLocations = [...locations, location]
    this.setState({ draw: false })
    return onLocationsChange(newLocations)
  }

  handleLocationDelele(place) {
    const locations = this.props.locations
    const indexOfLocation = locations.indexOf(place)
    if (indexOfLocation >= 0) {
      locations.splice(indexOfLocation, 1)
      this.props.onLocationsChange(locations)
    }
  }

  render() {
    const { value, locationSuggestions, draw } = this.state
    const { isReadOnly, locations, intl } = this.props

    const suggestions = locationSuggestions.map(suggestion => {
      const type = intl.formatMessage({ id: `website.${suggestion.type}` })
      return {
        label: (
          <Box margin="xsmall">
            {suggestion.name}
            <Text size="xsmall"> {type}</Text>
          </Box>
        ),
        value: suggestion.id
      }
    })

    return (
      <div className="pb-3">
        {!isReadOnly && (
          <Box direction="row" align="center">
            <TextInput
              id="location"
              placeholder={intl.formatMessage({ id: 'admin.search.locations.placeholder' })}
              value={value}
              onChange={this.handleInputChange}
              onSelect={this.onSelectLocation}
              suggestions={suggestions}
            />

            <div className="flex justify-content-center align-items-center pl-2" onClick={() => this.setState({ draw: true })}>
              <Button size="small" label={<FormattedMessage id="global.actions.draw" />}></Button>
            </div>
          </Box>
        )}
        {locations && (
          <ListGroup className="mt-3">
            {locations.map(location => (
              <ListGroupItem key={Math.random()} className="d-flex">
                <Text size="medium" className="flex flex-grow-1">
                  {location.name} <Text size="small">({intl.formatMessage({ id: `website.${location.type}` })})</Text>
                </Text>
                {location.type === 'polygon' && <Map style={{ width: '150px', height: '150px' }} locations={[location.geoJson]}></Map>}
                {!isReadOnly && (
                  <Button onClick={() => this.handleLocationDelele(location)} style={{ marginBottom: 'auto' }}>
                    <FontAwesomeIcon className="ml-2 mr-1" icon={faTrash} />
                  </Button>
                )}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        {draw && <PolygonMapDraw onLocationAdd={this.onAddDrawing} onClose={() => this.setState({ draw: false })} />}
      </div>
    )
  }
}

export default injectIntl(LocationSelector)
