import React, { Component } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Map, TileLayer, GeoJSON, AttributionControl } from 'react-leaflet'

import DrawControl from 'utils/DrawControl'
import { Heading, TextInput, FormField, Button, Box, Text } from 'grommet'
import FormLabel from './FormLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDrawPolygon, faTimes } from '@fortawesome/free-solid-svg-icons'

/**
 * used to select a location, can be a polyglon not only a point
 */
class PolygonMapDraw extends Component {
  constructor(props) {
    super(props)

    this.state = {
      polygon: null,
      name: '',
      error: null
    }
  }

  onChange = layer => {
    const geoJson = layer.toGeoJSON()
    const polygon = <GeoJSON data={geoJson} />
    this.setState({ polygon, geoJson })
  }

  onSave = () => {
    const { geoJson, name } = this.state
    const { intl, onLocationAdd } = this.props
    if (!name || name.length === 0) {
      return this.setState({ error: intl.formatMessage({ id: 'global.errors.required' }) })
    }
    return onLocationAdd({ name, geoJson: geoJson.geometry })
  }

  render() {
    const { polygon, name, error } = this.state
    return (
      <Row className="polygon-map-draw bg-light">
        <Col xl="3" lg="3" md="4" xs="12" className="polygon-sidebar d-flex flex-column">
          <Card className="mt-4 ml-md-4 ml-2 mr-2">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <Heading level="4">
                  <FormattedMessage id="admin.search.draw" />
                </Heading>
                <Button onClick={() => this.props.onClose()}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>

              <Text className="mb-1" size="sm">
                <FormattedMessage id="website.find.draw.text" />
              </Text>

              <FormField label={<FormLabel label="global.properties.name" icon="" />} htmlFor="name" error={error}>
                <TextInput value={name} onChange={e => this.setState({ name: e.target.value, error: null })} id="name" name="name" />
              </FormField>

              {polygon && (
                <Box pad="small" direction="row" className="polygon-list-item mb-2 justify-content-between">
                  <Text className="flex-grow-0">
                    <FontAwesomeIcon icon={faDrawPolygon} className="mr-2" /> Polygon
                  </Text>
                  <Button onClick={() => this.setState({ polygon: null, geoJson: null })}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Box>
              )}
              <div className="d-flex justify-content-end">
                <Button onClick={this.onSave} disabled={!polygon} primary label={<FormattedMessage id="global.actions.save" />} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl="9" lg="9" md="8" xs="12" className="polygon-map bg-dark pl-3 pr-3 pl-md-0">
          <Map center={[46.6687123, 11.1619857]} zoom={11} sattributionControl={false} maxZoom={19} dr>
            <AttributionControl position="bottomright" prefix="Profi Immobilien" />
            <TileLayer attribution="&copy; OpenStreetMap" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <DrawControl onChange={this.onChange} />
            {polygon}
          </Map>
        </Col>
      </Row>
    )
  }
}

export default injectIntl(PolygonMapDraw)
