import React, { Component } from 'react'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Select, FormField, Box, Button, Form, CheckBox, Heading, Text, RadioButtonGroup, TextArea, RadioButton } from 'grommet'
import { Formik } from 'formik'
import { ReactSVG } from 'react-svg'

import FormLabel from './FormLabel'
import TextInput from './TextInput'
import EnergyClassSelector from './EnergyClassSelector'
import RoomNumberSelector from './RoomNumberSelector'
import AreaSelector from './AreaSelector'
import ParkingSelector from './ParkingSelector'
import OrientationSelector from './OrientationSelector'
import CultivationSelector from './CultivationSelector'
import CurrencyInput from './CurrencyInput'
import HouseValues from './HouseValues'
import HotelSelector from './HotelSelector'
import FormattedInput from './FormattedInput'
import { propertyFormik } from 'utils/property-utils'
import { oneOf, calculateArea } from 'utils/utils'
import { deepMerge } from 'grommet/utils'

class SelectDialog extends Component {
  constructor(props) {
    super(props)

    const { id } = props.type
    const schema = propertyFormik(id, props.rent)

    // edit of existing property
    if (props.property && props.property.type && props.property.type.type) {
      schema[props.property.type.type] = deepMerge(schema[props.property.type.type], props.property)
    } else if (props.property && props.type.type) {
      // on back button
      schema[props.type.type] = deepMerge(schema[props.type.type], props.property)
    }

    this.state = {
      ...schema,
      step: props.propertyStep || 0
    }
  }

  onValidate = (values) => {
    let errors = {}
    if (!values.price || values.price <= 0) errors.price = this.props.intl.formatMessage({ id: 'global.errors.required' })
    if (this.props.type.type === 'garage') {
      if (!values.numOfPlaces) errors.numOfPlaces = this.props.intl.formatMessage({ id: 'global.errors.required' })
    }
    return errors
  }

  render() {
    const { intl, onSubmit, type, rent, isAdmin } = this.props
    const { residential, garage, commercial, buildingland, agriculture, step } = this.state

    const townhousePositionOptions = [
      { value: 'center', name: intl.formatMessage({ id: `admin.property.townhousePosition.center` }) },
      { value: 'outside', name: intl.formatMessage({ id: `admin.property.townhousePosition.outside` }) }
    ]
    const conditionOptions = ['1', '2', '3', '4'].map((num) => {
      return { value: num, name: intl.formatMessage({ id: `admin.property.condition.${num}` }) }
    })

    return (
      <div className="sell-create">
        {type.type === 'residential' && (
          <Formik
            initialValues={residential}
            validate={this.onValidate}
            onSubmit={(values) => {
              values.area =
                type.id === 'house'
                  ? values.house.overGroundArea + values.house.underGroundArea
                  : calculateArea(values.nettoArea, values.areas, values.garden.area)
              return onSubmit(values)
            }}
          >
            {({ values, errors, handleSubmit, handleChange, validateForm, setFieldValue }) => (
              <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                <Heading level="3" className="d-flex w-100 justify-content-center pb-2" alignSelf="center">
                  <FormattedHTMLMessage id="website.sell.new.step3.title" values={{ type: type.name[intl.locale] }} />
                </Heading>

                {isAdmin && (
                  <FormField label={'ID'} htmlFor="property_id" className="flex-grow-1">
                    <TextInput type="number" name="property_id" onChange={handleChange} value={values.property_id}></TextInput>
                  </FormField>
                )}

                {type.id !== 'villa' && (
                  <div className="d-flex align-items-center mb-3">
                    <ReactSVG
                      src={`/website/unit${type.id === 'townhouse' ? 'townhouse' : 'appartment'}.svg`}
                      className="mr-1"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 80px; height: 80px')}
                    />
                    <FormField
                      label={<FormattedMessage id="admin.property.numOfUnits" />}
                      htmlFor="numOfUnits"
                      error={errors.numOfUnits}
                      className="flex-grow-1"
                    >
                      <TextInput type="number" name="numOfUnits" onChange={handleChange} value={values.numOfUnits}></TextInput>
                    </FormField>
                  </div>
                )}
                {type.id === 'townhouse' && (
                  <div className="d-flex align-items-center mb-3">
                    <ReactSVG
                      src={`/website/townhouseposition.svg`}
                      className="mr-1"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 80px; height: 80px')}
                    />
                    <FormField
                      margin="x"
                      label={<FormattedMessage id="admin.property.townhousePosition" />}
                      htmlFor="townhousePosition"
                      error={errors.townhousePosition}
                      className="flex-grow-1"
                    >
                      <Select
                        id="townhousePosition"
                        size="small"
                        name="townhousePosition"
                        valueLabel={
                          <Box pad="small">
                            {values.townhousePosition
                              ? intl.formatMessage({ id: `admin.property.townhousePosition.${values.townhousePosition}` })
                              : ''}
                          </Box>
                        }
                        options={townhousePositionOptions}
                        onChange={({ option }) => setFieldValue('townhousePosition', option.value)}
                        children={(option) => <Box pad="small">{option.name}</Box>}
                      ></Select>
                    </FormField>
                  </div>
                )}

                {type.id === 'appartment' ? (
                  <div className="d-flex align-items-center">
                    <ReactSVG
                      src="/website/floorappartment.svg"
                      className="mr-2"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 80px; height: 80px')}
                    />
                    <FormField
                      label={<FormattedMessage id="admin.property.floor" />}
                      htmlFor="minFloor"
                      error={errors.minFloor}
                      className="flex-grow-1 "
                    >
                      <TextInput type="number" name="minFloor" onChange={handleChange} value={values.minFloor} className="mb-2"></TextInput>
                      <FormattedMessage id="admin.property.floorsOf"></FormattedMessage>
                      <TextInput type="number" name="maxFloor" onChange={handleChange} value={values.maxFloor}></TextInput>
                    </FormField>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <ReactSVG
                      src="/website/floorhouse.svg"
                      className="mr-2"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 80px; height: 80px')}
                    />
                    <FormField
                      label={<FormattedMessage id="admin.property.floors" />}
                      htmlFor="minFloor"
                      error={errors.minFloor}
                      className="flex-grow-1"
                    >
                      <FormattedMessage id="admin.property.upperFloors"></FormattedMessage>
                      <TextInput type="number" name="maxFloor" onChange={handleChange} value={values.maxFloor}></TextInput>
                      <FormattedMessage id="admin.property.lowerFloors"></FormattedMessage>
                      <TextInput type="number" name="minFloor" onChange={handleChange} value={values.minFloor} className="mb-2"></TextInput>
                    </FormField>
                  </div>
                )}

                <Heading level="4" className="d-flex w-100 justify-content-start mt-3" alignSelf="start">
                  <FormattedMessage id="website.sell.new.step3.characteristics" />
                </Heading>
                <FormField
                  margin="x"
                  label={<FormLabel label="admin.property.orientation" icon="/website/compass.svg"></FormLabel>}
                  htmlFor="orientation"
                  error={errors.orientation}
                >
                  <OrientationSelector selected={values.orientation} onChange={(value) => setFieldValue('orientation', value)} />
                </FormField>
                <FormField
                  label={<FormLabel label="admin.property.condition" icon="/website/state.svg"></FormLabel>}
                  htmlFor="condition1"
                  error={errors.condition}
                >
                  <Select
                    id="condition1"
                    size="small"
                    name="condition1"
                    valueLabel={<Box pad="small">{intl.formatMessage({ id: `admin.property.condition.${values.condition}` })}</Box>}
                    options={conditionOptions}
                    onChange={({ option }) => setFieldValue('condition', option.value)}
                    children={(option) => <Box pad="small">{option.name}</Box>}
                  ></Select>
                </FormField>
                <FormField label={intl.formatMessage({ id: 'admin.property.energyClass' })} htmlFor="energyClass" error={errors.energyClass}>
                  <EnergyClassSelector onChange={(value) => setFieldValue('energyClass', value)} value={values.energyClass} />
                </FormField>
                <FormField
                  label={<FormLabel label="admin.property.constructionYear" icon="/website/year.svg"></FormLabel>}
                  htmlFor="constructionYear"
                  error={errors.constructionYear}
                >
                  <TextInput type="number" name="constructionYear" onChange={handleChange} value={values.constructionYear}></TextInput>
                </FormField>

                {(type.id === 'appartment' || type.id === 'house') && (
                  <FormField htmlFor="lift" error={errors.lift} className="mt-3">
                    <CheckBox
                      name="lift"
                      checked={values.lift}
                      label={<FormLabel label="admin.property.lift" append="?"></FormLabel>}
                      onChange={(event) => setFieldValue('lift', event.target.checked)}
                    />
                  </FormField>
                )}

                {type.id !== 'house' && (
                  <FormField htmlFor="rooms" error={errors.rooms}>
                    <RoomNumberSelector onChange={(value) => setFieldValue('rooms', value)} value={values.rooms} />
                  </FormField>
                )}

                {type.id === 'house' ? (
                  <HouseValues onChange={(value) => setFieldValue('house', value)} value={values.house} />
                ) : (
                  <>
                    <Heading level="3" className="d-flex w-100 justify-content-start mt-3" alignSelf="start">
                      <FormattedMessage id="website.sell.new.step3.areas" />
                    </Heading>

                    <FormField
                      label={<FormLabel label="admin.property.nettoArea" icon="/website/area-alt.svg"></FormLabel>}
                      htmlFor="nettoArea"
                      error={errors.nettoArea}
                    >
                      <Box align="center" direction="row" alignContent="center">
                        <ReactSVG src={`/website/area-inner.svg`} />
                        <FormattedInput
                          suffix={'m²'}
                          type="number"
                          name="nettoArea"
                          onChange={(value) => setFieldValue('nettoArea', value)}
                          value={values.nettoArea}
                        ></FormattedInput>
                      </Box>
                    </FormField>

                    <FormField
                      label={intl.formatMessage({ id: 'website.sell.new.step3.additionalAreas' })}
                      htmlFor="nettoArea"
                      error={errors.nettoArea}
                    >
                      <AreaSelector onChange={(value) => setFieldValue('areas', value)} value={values.areas} />
                    </FormField>
                    <FormField
                      label={<FormLabel label="admin.property.garden" icon="/website/garden.svg"></FormLabel>}
                      htmlFor="garden"
                      error={errors.garden}
                    >
                      <RadioButtonGroup
                        name="garden"
                        className="pb-2"
                        options={[
                          { label: intl.formatMessage({ id: 'admin.property.garden.private' }), value: 'private' },
                          { label: intl.formatMessage({ id: 'admin.property.garden.community' }), value: 'community' },
                          { label: intl.formatMessage({ id: 'admin.property.garden.no' }), value: 'no' }
                        ].filter((option) => (type.id === 'villa' ? option.value !== 'community' : true))}
                        value={values.garden.type}
                        onChange={(event) => setFieldValue('garden', { type: event.target.value, area: 0 })}
                      />
                      {values.garden.type === 'private' && (
                        <FormField label={intl.formatMessage({ id: 'admin.property.areaGarden' })} htmlFor="area">
                          <FormattedInput
                            suffix={'m²'}
                            type="number"
                            name="gardenArea"
                            onChange={(value) => setFieldValue('garden', { type: values.garden.type, area: value })}
                            value={values.garden.area}
                          ></FormattedInput>
                        </FormField>
                      )}
                    </FormField>
                    <FormField label={intl.formatMessage({ id: 'admin.property.area' })} htmlFor="area">
                      <Box direction="row" align="center" round="small">
                        <FormattedInput
                          suffix={'m²'}
                          type="number"
                          name="area"
                          readOnly
                          style={{ pointerEvents: 'none', border: 'none' }}
                          value={calculateArea(values.nettoArea, values.areas, values.garden.area)}
                        ></FormattedInput>
                      </Box>
                    </FormField>
                  </>
                )}
                {type.id !== 'house' && (
                  <>
                    <FormField
                      label={<FormLabel label="admin.property.furnishing" icon="/website/furniture.svg" append="?"></FormLabel>}
                      htmlFor="furnishing2"
                      error={errors.furnishing}
                    >
                      <Select
                        id="furnishing2"
                        size="small"
                        name="furnishing2"
                        valueLabel={<Box pad="small">{intl.formatMessage({ id: `admin.property.furnishing.${values.furnishing}` })}</Box>}
                        options={['empty', 'part', 'full']}
                        onChange={({ option }) => setFieldValue('furnishing', option)}
                        children={(option) => <Box pad="small">{intl.formatMessage({ id: `admin.property.furnishing.${option}` })}</Box>}
                      ></Select>
                    </FormField>
                    {type.id !== 'villa' && (
                      <FormField
                        label={<FormLabel label="admin.property.heating" icon="/website/thermometer.svg" append="?"></FormLabel>}
                        htmlFor="heating1"
                        error={errors.heating}
                      >
                        <Select
                          id="heating1"
                          size="small"
                          name="heating1"
                          valueLabel={<Box pad="small">{intl.formatMessage({ id: `admin.property.heating.${values.heating}` })}</Box>}
                          options={['central', 'centralWithCal', 'autonom']}
                          onChange={({ option }) => setFieldValue('heating', option)}
                          children={(option) => <Box pad="small">{intl.formatMessage({ id: `admin.property.heating.${option}` })}</Box>}
                        ></Select>
                      </FormField>
                    )}
                  </>
                )}
                <FormField
                  label={<FormLabel label="admin.property.heatingType" icon="/website/thermometer.svg" append="?"></FormLabel>}
                  htmlFor="heatingType1"
                  error={errors.heatingType}
                >
                  <Select
                    id="heatingType"
                    size="small"
                    name="heatingType"
                    valueLabel={<Box pad="small">{intl.formatMessage({ id: `admin.property.heatingType.${values.heatingType}` })}</Box>}
                    options={['gas', 'oil', 'district', 'pellets', 'renewable']}
                    onChange={({ option }) => setFieldValue('heatingType', option)}
                    children={(option) => <Box pad="small">{intl.formatMessage({ id: `admin.property.heatingType.${option}` })}</Box>}
                  ></Select>
                </FormField>

                <FormField htmlFor="conventionated" error={errors.type}>
                  <CheckBox
                    name="conventionated"
                    checked={values.conventionated}
                    label={`${intl.formatMessage({ id: `admin.property.conventionated` })}?`}
                    onChange={(event) => setFieldValue('conventionated', event.target.checked)}
                  />
                </FormField>
                <FormField
                  label={intl.formatMessage({
                    id: `admin.property.price${rent === 'true' ? 'Rent' : ''}`
                  })}
                  htmlFor="price"
                  error={errors.price}
                >
                  <CurrencyInput name="price" value={values.price} onChange={(price) => setFieldValue('price', price)} />
                </FormField>
                <FormField
                  label={intl.formatMessage({ id: `admin.property.${type.id === 'appartment' ? 'condominiumFees' : 'fees'}` })}
                  htmlFor="fees"
                  error={errors.fees}
                >
                  <CurrencyInput name="fees" value={values.fees} onChange={(fees) => setFieldValue('fees', fees)} />
                </FormField>
                <FormField label={intl.formatMessage({ id: 'admin.property.informations' })} htmlFor="informations" error={errors.informations}>
                  <TextArea type="text" name="informations" onChange={handleChange} value={values.informations}></TextArea>
                </FormField>

                {/* <FormField label={intl.formatMessage({ id: 'admin.property.cataster' })} htmlFor="cataster" error={errors.cataster}>
                  <Text size="sm" className="pl-2 pr-2">
                    <FormattedMessage id="admin.property.cataster.description" />
                  </Text>
                  <TextArea type="text" name="cataster" onChange={handleChange} value={values.cataster}></TextArea>
                </FormField> */}
                {/* {type.id === 'appartment' && (
                  <>
                    <Heading level={4}>
                      <FormattedMessage id="admin.property.administrator" />
                    </Heading>
                    <FormField label={intl.formatMessage({ id: 'global.properties.name' })} htmlFor="administrator.name" error={errors.administrator}>
                      <TextInput
                        type="text"
                        name="administrator.name"
                        onChange={e => setFieldValue('administrator.name', e.target.value)}
                        value={values.administrator.name}
                      ></TextInput>
                    </FormField>
                    <FormField label={intl.formatMessage({ id: 'global.properties.tel' })} htmlFor="administrator.tel" error={errors.administrator}>
                      <TextInput type="tel" name="administrator.tel" onChange={handleChange} value={values.administrator.tel}></TextInput>
                    </FormField>
                    <FormField
                      label={intl.formatMessage({ id: 'global.properties.email' })}
                      htmlFor="administrator.email"
                      error={errors.administrator}
                    >
                      <TextInput type="email" name="administrator.email" onChange={handleChange} value={values.administrator.email}></TextInput>
                    </FormField>
                  </>
                )} */}
                <FormField label={intl.formatMessage({ id: 'admin.property.parking' })} htmlFor="nettoArea" error={errors.nettoArea}>
                  <ParkingSelector onChange={(value) => setFieldValue('parking', value)} value={values.parking} hideCommon={type.id === 'villa'} />
                </FormField>
                <div className="d-flex w-100">
                  {step > 0 && (
                    <Button
                      style={{ maxWidth: '150px', marginTop: '16px' }}
                      primary
                      label={intl.formatMessage({ id: 'global.actions.back' })}
                      onClick={() => this.previousStep(10)}
                    ></Button>
                  )}
                  <div className="d-flex flex-grow-1"></div>
                  <Button
                    type={'button'}
                    style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                    primary
                    label={intl.formatMessage({ id: 'global.actions.next' })}
                    onClick={() => handleSubmit(values)}
                  ></Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
        {type.type === 'garage' && (
          <Formik initialValues={garage} validate={this.onValidate} onSubmit={(values) => onSubmit(values)}>
            {({ values, errors, handleSubmit, handleChange, isSubmitting, setFieldValue }) => (
              <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                <Heading level="4" className="d-flex w-100 justify-content-center" alignSelf="center">
                  {type.name[intl.locale]}
                </Heading>

                {isAdmin && (
                  <FormField label={'ID'} htmlFor="property_id" className="flex-grow-1">
                    <TextInput type="number" name="property_id" onChange={handleChange} value={values.property_id}></TextInput>
                  </FormField>
                )}
                <FormField
                  label={intl.formatMessage({ id: `admin.property.numOfPlaces.${type.id}` })}
                  htmlFor="numOfPlaces"
                  error={errors.numOfPlaces}
                  className="mw-form-field"
                >
                  <Box align="end" direction="row" alignContent="center">
                    <ReactSVG
                      src={`/website/types/${type.id}.svg`}
                      beforeInjection={(svg) => svg.setAttribute('style', 'margin-top: -50px;')}
                      className="mr-1"
                    />
                    <TextInput
                      type="number"
                      name="numOfPlaces"
                      onChange={handleChange}
                      value={values.numOfPlaces}
                      onFocus={() => (values.numOfPlaces === 0 ? setFieldValue('numOfPlaces', '') : null)}
                      onBlur={() => (values.numOfPlaces === '' ? setFieldValue('numOfPlaces', 0) : null)}
                    ></TextInput>
                  </Box>
                </FormField>

                {type.id === 'garage' && (
                  <div className="d-flex align-items-center mw-form-field">
                    <ReactSVG
                      src="/website/floorgarage.svg"
                      className="mr-2"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 80px; height: 80px')}
                    />
                    <FormField label={<FormattedMessage id="admin.property.floor" />} htmlFor="floor" error={errors.floor} className="flex-grow-1">
                      <TextInput type="number" name="floor" onChange={handleChange} value={values.floor} className="mb-2"></TextInput>
                      <span className="pb-2 pl-1">
                        <FormattedMessage id="admin.property.floorsOf"></FormattedMessage>
                      </span>
                      <TextInput type="number" name="floors" onChange={handleChange} value={values.floors}></TextInput>
                    </FormField>
                  </div>
                )}

                <FormField htmlFor="power" error={errors.power} label={intl.formatMessage({ id: `admin.property.power` })}>
                  <Box align="center" alignContent="center" direction="row">
                    <ReactSVG
                      src={`/website/power.svg`}
                      className="mr-1"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 48px; height: 48px')}
                    />
                    <Box pad="small" direction="column" flex="grow">
                      <RadioButton
                        label={intl.formatMessage({ id: 'admin.property.true' })}
                        name="power1"
                        value={true}
                        checked={values.power === true}
                        onChange={() => setFieldValue('power', true)}
                      />
                      <div className="mb-1"></div>
                      <RadioButton
                        label={intl.formatMessage({ id: 'admin.property.false' })}
                        name="power2"
                        value={false}
                        checked={values.power === false}
                        onChange={() => setFieldValue('power', false)}
                      />
                    </Box>
                  </Box>
                </FormField>

                <FormField htmlFor="runningWater" error={errors.runningWater} label={intl.formatMessage({ id: `admin.property.runningWater` })}>
                  <Box align="center" alignContent="center" direction="row">
                    <ReactSVG
                      src={`/website/water.svg`}
                      className="mr-1"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 48px; height: 48px')}
                    />
                    <Box pad="small" direction="column" flex="grow">
                      <RadioButton
                        label={intl.formatMessage({ id: 'admin.property.true' })}
                        name="runningWater1"
                        value={true}
                        checked={values.runningWater === true}
                        onChange={() => setFieldValue('runningWater', true)}
                      />
                      <div className="mb-1"></div>
                      <RadioButton
                        label={intl.formatMessage({ id: 'admin.property.false' })}
                        name="runningWater2"
                        value={false}
                        checked={values.runningWater === false}
                        onChange={() => setFieldValue('runningWater', false)}
                      />
                    </Box>
                  </Box>
                </FormField>

                <FormField htmlFor="light" error={errors.light} label={intl.formatMessage({ id: `admin.property.light` })}>
                  <Box align="center" alignContent="center" direction="row">
                    <ReactSVG
                      src={`/website/light.svg`}
                      className="mr-1"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 48px; height: 48px')}
                    />
                    <Box pad="small" direction="column" flex="grow">
                      <RadioButton
                        label={intl.formatMessage({ id: 'admin.property.true' })}
                        name="light1"
                        value={true}
                        checked={values.light === true}
                        onChange={() => setFieldValue('light', true)}
                      />
                      <div className="mb-1"></div>
                      <RadioButton
                        label={intl.formatMessage({ id: 'admin.property.false' })}
                        name="light2"
                        value={false}
                        checked={values.light === false}
                        onChange={() => setFieldValue('light', false)}
                      />
                    </Box>
                  </Box>
                </FormField>

                <FormField
                  label={intl.formatMessage({ id: 'admin.property.constructionYear' })}
                  htmlFor="constructionYear"
                  error={errors.constructionYear}
                >
                  <TextInput type="number" name="constructionYear" onChange={handleChange} value={values.constructionYear}></TextInput>
                </FormField>

                <FormField label={intl.formatMessage({ id: 'admin.property.condition' })} htmlFor="condition2" error={errors.energyClass}>
                  <Select
                    id="condition2"
                    size="small"
                    name="condition2"
                    valueLabel={<Box pad="small">{intl.formatMessage({ id: `admin.property.condition.${values.condition}` })}</Box>}
                    options={['1', '2', '3', '4']}
                    onChange={({ option }) => setFieldValue('condition', option)}
                    children={(option) => <Box pad="small">{intl.formatMessage({ id: `admin.property.condition.${option}` })}</Box>}
                  ></Select>
                </FormField>

                <FormField label={intl.formatMessage({ id: 'admin.property.nettoArea' })} htmlFor="area" error={errors.area}>
                  <Box align="center" alignContent="center" direction="row">
                    <ReactSVG src={`/website/area-inner.svg`} className="mr-1" />
                    <FormattedInput
                      suffix={'m²'}
                      type="number"
                      name="area"
                      onChange={(value) => setFieldValue('area', value)}
                      value={values.area}
                    ></FormattedInput>
                  </Box>
                </FormField>
                <FormField
                  label={intl.formatMessage({
                    id: `admin.property.price${rent === 'true' ? 'Rent' : ''}`
                  })}
                  htmlFor="price"
                  error={errors.price}
                >
                  <CurrencyInput name="price" onChange={(price) => setFieldValue('price', price)} value={values.price}></CurrencyInput>
                </FormField>
                {rent === 'true' && (
                  <FormField label={intl.formatMessage({ id: 'admin.property.rentPrice' })} htmlFor="price" error={errors.rentPrice}>
                    <CurrencyInput name="rentPrice" onChange={(price) => setFieldValue('rentPrice', price)} value={values.rentPrice}></CurrencyInput>
                  </FormField>
                )}

                <FormField label={intl.formatMessage({ id: 'admin.property.fees' })} htmlFor="fees" error={errors.fees}>
                  <CurrencyInput name="fees" onChange={(fees) => setFieldValue('fees', fees)} value={values.fees}></CurrencyInput>
                </FormField>

                <FormField label={intl.formatMessage({ id: 'admin.property.informations' })} htmlFor="informations" error={errors.informations}>
                  <TextArea type="text" name="informations" onChange={handleChange} value={values.informations}></TextArea>
                </FormField>

                <FormField label={intl.formatMessage({ id: 'admin.property.cataster' })} htmlFor="cataster" error={errors.cataster}>
                  <Text size="sm" className="pl-2 pr-2">
                    <FormattedMessage id="admin.property.cataster.description" />
                  </Text>
                  <TextArea type="text" name="cataster" onChange={handleChange} value={values.cataster}></TextArea>
                </FormField>
                <Button
                  type="submit"
                  style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                  primary
                  disabled={isSubmitting}
                  label={intl.formatMessage({ id: 'global.actions.next' })}
                ></Button>
              </Form>
            )}
          </Formik>
        )}
        {type.type === 'commercial' && (
          <Formik initialValues={commercial} validate={this.onValidate} onSubmit={(values) => onSubmit(values)}>
            {({ values, errors, handleSubmit, handleChange, isSubmitting, setFieldValue }) => (
              <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                <Heading level="4" className="d-flex w-100 justify-content-center" alignSelf="center">
                  {type.name[intl.locale]}
                </Heading>

                {isAdmin && (
                  <FormField label={'ID'} htmlFor="property_id" className="flex-grow-1">
                    <TextInput type="number" name="property_id" onChange={handleChange} value={values.property_id}></TextInput>
                  </FormField>
                )}
                <FormField
                  label={
                    <FormLabel
                      label={`admin.property.${
                        oneOf(type.id, ['hotel', 'industry']) ? 'upperFloors' : oneOf(type.id, ['warehouse']) ? 'floors' : 'floor'
                      }`}
                    />
                  }
                  className="mw-form-field"
                  htmlFor="floor"
                  error={errors.floor}
                >
                  <Box align="center" direction="row" alignContent="center">
                    <ReactSVG
                      src="/website/floorhouse.svg"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 128px; height: 128px')}
                      className="pr-1"
                    />
                    <TextInput type="number" name="floor" onChange={handleChange} value={values.floor}></TextInput>
                  </Box>
                </FormField>

                {oneOf(type.id, ['hotel', 'industry']) && (
                  <FormField
                    label={<FormLabel label={`admin.property.lowerFloors`} icon="" />}
                    htmlFor="fllowerFloorsoor"
                    error={errors.lowerFloors}
                    className="mw-form-field"
                  >
                    <Box align="center" direction="row" alignContent="center">
                      <ReactSVG
                        src="/website/floorhouse.svg"
                        beforeInjection={(svg) => svg.setAttribute('style', 'width: 128px; height: 128px')}
                        className="pr-1"
                      />
                      <TextInput type="number" name="lowerFloors" onChange={handleChange} value={values.lowerFloors}></TextInput>
                    </Box>
                  </FormField>
                )}

                {oneOf(type.id, ['store', 'bar', 'restaurant', 'office', 'workshop', 'industry']) && (
                  <FormField htmlFor="lift" error={errors.lift} className="mt-3">
                    <CheckBox
                      name="lift"
                      checked={values.lift}
                      label={<FormLabel label="admin.property.lift" icon="/website/elevator.svg"></FormLabel>}
                      onChange={(event) => setFieldValue('lift', event.target.checked)}
                    />
                  </FormField>
                )}

                {oneOf(type.id, ['warehouse', 'workshop']) && (
                  <FormField htmlFor="reachableTruck" error={errors.reachableTruck} className="mt-3">
                    <CheckBox
                      name="reachableTruck"
                      checked={values.reachableTruck}
                      label={<FormLabel label="admin.property.reachableTruck" icon=""></FormLabel>}
                      onChange={(event) => setFieldValue('reachableTruck', event.target.checked)}
                    />
                  </FormField>
                )}

                {type.id !== 'hotel' && type.id !== 'workshop' && type.id !== 'industry' && type.id !== 'warehouse' && (
                  <FormField
                    margin="x"
                    label={<FormLabel label="admin.property.orientation" icon="/website/compass.svg"></FormLabel>}
                    htmlFor="orientation"
                    error={errors.orientation}
                  >
                    <OrientationSelector selected={values.orientation} onChange={(value) => setFieldValue('orientation', value)} />
                  </FormField>
                )}

                <FormField
                  label={<FormLabel label="admin.property.condition" icon="/website/state.svg"></FormLabel>}
                  htmlFor="condition3"
                  error={errors.condition}
                >
                  <Select
                    id="condition3"
                    size="small"
                    name="condition3"
                    valueLabel={<Box pad="small">{intl.formatMessage({ id: `admin.property.condition.${values.condition}` })}</Box>}
                    options={['1', '2', '3', '4']}
                    onChange={({ option }) => setFieldValue('condition', option)}
                    children={(option) => <Box pad="small">{intl.formatMessage({ id: `admin.property.condition.${option}` })}</Box>}
                  ></Select>
                </FormField>

                <FormField label={intl.formatMessage({ id: 'admin.property.energyClass' })} htmlFor="energyClass" error={errors.energyClass}>
                  <EnergyClassSelector onChange={(value) => setFieldValue('energyClass', value)} value={values.energyClass} />
                </FormField>

                <FormField
                  label={<FormLabel label="admin.property.constructionYear" icon="/website/year.svg"></FormLabel>}
                  htmlFor="constructionYear"
                  error={errors.constructionYear}
                >
                  <TextInput type="number" name="constructionYear" onChange={handleChange} value={values.constructionYear}></TextInput>
                </FormField>

                {type.id === 'hotel' ? (
                  <HotelSelector values={values.hotel} onChange={(values) => setFieldValue('hotel', values)}></HotelSelector>
                ) : (
                  <FormField
                    label={
                      <FormLabel
                        label={`admin.property.${oneOf(type.id, ['store', 'bar', 'restaurant']) ? 'wareDescription' : 'commercialDescription'}`}
                      />
                    }
                    htmlFor="commercialDescription"
                    error={errors.commercialDescription}
                  >
                    <TextArea type="text" name="commercialDescription" onChange={handleChange} value={values.commercialDescription}></TextArea>
                  </FormField>
                )}

                {oneOf(type.id, ['industry']) && (
                  <HouseValues onChange={(values) => setFieldValue('industry', values)} value={values.industry} hidePurpose />
                )}

                {type.id !== 'hotel' && type.id !== 'industry' && (
                  <FormField label={''} htmlFor="rooms" error={errors.rooms} className="mt-4">
                    <RoomNumberSelector onChange={(value) => setFieldValue('rooms', value)} value={values.rooms} />
                  </FormField>
                )}

                {type.id !== 'industry' && (
                  <FormField
                    label={
                      <FormLabel
                        label={`admin.property.${
                          type.id === 'warehouse'
                            ? 'nettoAreaInside'
                            : type.id === 'hotel'
                            ? 'bruttoAreaOverGround'
                            : `sumNettoArea${oneOf(type.id, ['bar', 'store', 'restaurant']) ? `.${type.id}` : ''}`
                        }`}
                      />
                    }
                    htmlFor="area"
                    error={errors.hotel}
                  >
                    <Box align="center" direction="row" alignContent="center">
                      <ReactSVG src="/website/area-inner.svg" />
                      <FormattedInput
                        suffix={'m²'}
                        type="number"
                        name="area"
                        onChange={(value) => setFieldValue('area', value)}
                        value={values.area}
                      ></FormattedInput>
                    </Box>
                  </FormField>
                )}

                {oneOf(type.id, ['warehouse', 'hotel']) && (
                  <FormField
                    label={<FormLabel label={`admin.property.${type.id === 'hotel' ? 'bruttoAreaUnderGround' : 'nettoAreaOutside'}`} />}
                    htmlFor="exteriorArea"
                    error={errors.hotel}
                  >
                    <Box align="center" direction="row" alignContent="center">
                      <ReactSVG src={`/website/area-${type.id === 'hotel' ? 'inner.svg' : 'outside.svg'}`} />
                      <FormattedInput
                        suffix={'m²'}
                        type="number"
                        name="exteriorArea"
                        onChange={(value) => setFieldValue('exteriorArea', value)}
                        value={values.exteriorArea}
                      ></FormattedInput>
                    </Box>
                  </FormField>
                )}
                {type.id !== 'hotel' && type.id !== 'industry' && type.id !== 'workshop' && (
                  <FormField
                    label={<FormLabel label="website.sell.new.step3.additionalAreas" icon="" />}
                    htmlFor="nettoArea"
                    error={errors.nettoArea}
                  >
                    <AreaSelector onChange={(value) => setFieldValue('areas', value)} value={values.areas} />
                  </FormField>
                )}

                <FormField
                  label={<FormLabel label="admin.property.furnishing" icon="/website/furniture.svg"></FormLabel>}
                  htmlFor="furnishing1"
                  error={errors.furnishing}
                >
                  <Select
                    id="furnishing1"
                    size="small"
                    name="furnishing1"
                    valueLabel={<Box pad="small">{intl.formatMessage({ id: `admin.property.furnishing.${values.furnishing}` })}</Box>}
                    options={['empty', 'part', 'full']}
                    onChange={({ option }) => setFieldValue('furnishing', option)}
                    children={(option) => <Box pad="small">{intl.formatMessage({ id: `admin.property.furnishing.${option}` })}</Box>}
                  ></Select>
                </FormField>
                {type.id !== 'office' && type.id !== 'warehouse' && (
                  <>
                    <Heading level={4}>
                      <FormattedMessage id="admin.property.commercialInfo" />
                    </Heading>

                    <FormField
                      label={<FormLabel label="admin.property.commercialInfo.avgAnnualTurnover" icon="" />}
                      htmlFor="commercialInfo.avgAnnualTurnover"
                      error={errors.commercialInfo}
                    >
                      <CurrencyInput
                        name="commercialInfo.avgAnnualTurnover"
                        onChange={(price) => setFieldValue('commercialInfo.avgAnnualTurnover', price)}
                        value={values.commercialInfo.avgAnnualTurnover}
                      ></CurrencyInput>
                    </FormField>

                    <FormField
                      label={<FormLabel label="admin.property.commercialInfo.avgAnnualProfit" icon="" />}
                      htmlFor="commercialInfo.avgAnnualProfit"
                      error={errors.commercialInfo}
                    >
                      <CurrencyInput
                        name="commercialInfo.avgAnnualProfit"
                        onChange={(price) => setFieldValue('commercialInfo.avgAnnualProfit', price)}
                        value={values.commercialInfo.avgAnnualProfit}
                      ></CurrencyInput>
                    </FormField>
                  </>
                )}
                {type.id !== 'office' && (
                  <FormField label={intl.formatMessage({ id: `admin.property.rent.${rent}` })} htmlFor="sellType">
                    <Select
                      id="sellType"
                      size="small"
                      name="sellType"
                      valueLabel={<Box pad="small">{intl.formatMessage({ id: `admin.property.sellType.${rent}.${values.sellType}` })}</Box>}
                      options={rent === 'true' ? ['both', 'activity', 'walls'] : ['activity', 'walls']}
                      onChange={({ option }) => setFieldValue('sellType', option)}
                      children={(option) => <Box pad="small">{intl.formatMessage({ id: `admin.property.sellType.${rent}.${option}` })}</Box>}
                    ></Select>
                  </FormField>
                )}

                <FormField
                  label={intl.formatMessage({
                    id: `admin.property.price${rent === 'true' ? 'Rent' : ''}`
                  })}
                  htmlFor="price"
                  error={errors.price}
                >
                  <CurrencyInput name="price" onChange={(price) => setFieldValue('price', price)} value={values.price}></CurrencyInput>
                </FormField>

                {values.sellType !== 'both' && rent === 'true' && (
                  <FormField
                    label={<FormLabel label={`admin.property.rentPrice.${type.id === 'office' ? 'walls' : values.sellType}`} />}
                    htmlFor="rentPrice"
                    error={errors.rentPrice}
                  >
                    <CurrencyInput name="rentPrice" onChange={(price) => setFieldValue('rentPrice', price)} value={values.rentPrice}></CurrencyInput>
                  </FormField>
                )}

                <FormField
                  label={<FormLabel label="admin.property.maintenenceCost" icon="" />}
                  htmlFor="maintenenceCost"
                  error={errors.maintenenceCost}
                >
                  <CurrencyInput
                    name="maintenenceCost"
                    onChange={(maintenenceCost) => setFieldValue('maintenenceCost', maintenenceCost)}
                    value={values.maintenenceCost}
                  ></CurrencyInput>
                </FormField>

                <Heading level={4}>
                  <FormattedMessage id="admin.property.parking" />
                </Heading>

                <ParkingSelector
                  types={oneOf(type.id, ['workshop', 'industry', 'warehouse']) ? ['parkingLot', 'truckLot'] : ['garage', 'parkingLot']}
                  value={values.parking}
                  onChange={(values) => setFieldValue('parking', values)}
                  hideCommon
                  hidePrice
                ></ParkingSelector>

                <FormField label={<FormLabel label="admin.property.informations" icon="" />} htmlFor="informations" error={errors.informations}>
                  <TextArea type="text" name="informations" onChange={handleChange} value={values.informations}></TextArea>
                </FormField>

                <FormField label={intl.formatMessage({ id: 'admin.property.cataster' })} htmlFor="cataster" error={errors.cataster}>
                  <Text size="sm" className="pl-2 pr-2">
                    <FormattedMessage id="admin.property.cataster.description" />
                  </Text>
                  <TextArea type="text" name="cataster" onChange={handleChange} value={values.cataster}></TextArea>
                </FormField>

                <Button
                  type="submit"
                  style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                  primary
                  disabled={isSubmitting}
                  label={intl.formatMessage({ id: 'global.actions.next' })}
                ></Button>
              </Form>
            )}
          </Formik>
        )}
        {type.type === 'buildingland' && (
          <Formik initialValues={buildingland} validate={this.onValidate} onSubmit={(values) => onSubmit(values)}>
            {({ values, errors, handleSubmit, handleChange, isSubmitting, setFieldValue }) => (
              <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                <Heading level="4" className="d-flex w-100 justify-content-center" alignSelf="center">
                  {type.name[intl.locale]}
                </Heading>

                {isAdmin && (
                  <FormField label={'ID'} htmlFor="property_id" className="flex-grow-1">
                    <TextInput type="number" name="property_id" onChange={handleChange} value={values.property_id}></TextInput>
                  </FormField>
                )}

                <FormField label={<FormLabel label="admin.property.catastralArea" icon="" />} htmlFor="catastralArea" error={errors.catastralArea}>
                  <Box align="center" direction="row" alignContent="center">
                    <ReactSVG src="/website/area-outer.svg" />
                    <FormattedInput
                      suffix={'m²'}
                      type="number"
                      name="catastralArea"
                      onChange={(value) => setFieldValue('catastralArea', value)}
                      value={values.catastralArea}
                    ></FormattedInput>
                  </Box>
                </FormField>

                <FormField label={<FormLabel label="admin.property.cubic" icon="" />} htmlFor="cubic" error={errors.cubic}>
                  <FormattedInput
                    suffix={'m³'}
                    type="number"
                    name="cubic"
                    onChange={(value) => setFieldValue('cubic', value)}
                    value={values.cubic}
                  ></FormattedInput>
                </FormField>

                {type.id === 'industrialbuildingland' && (
                  <FormField label={<FormLabel label="admin.property.grossArea" icon="" />} htmlFor="grossArea" error={errors.grossArea}>
                    <FormattedInput
                      suffix={'m²'}
                      type="number"
                      name="grossArea"
                      onChange={(value) => setFieldValue('grossArea', value)}
                      value={values.grossArea}
                    ></FormattedInput>
                  </FormField>
                )}

                <FormField label={<FormLabel label="admin.property.landUseZone" icon="" />} htmlFor="landUseZone" error={errors.landUseZone}>
                  <TextInput type="text" name="landUseZone" onChange={handleChange} value={values.landUseZone}></TextInput>
                </FormField>

                <FormField label={<FormLabel label="admin.property.cubicIndex" icon="" />} htmlFor="cubicIndex" error={errors.cubicIndex}>
                  <FormattedInput
                    suffix={'m³/m²'}
                    type="number"
                    name="cubicIndex"
                    onChange={(value) => setFieldValue('cubicIndex', value)}
                    value={values.cubicIndex}
                  ></FormattedInput>
                </FormField>

                {type.id !== 'residentialbuildingland' && (
                  <FormField label={<FormLabel label="admin.property.surfaceIndex" icon="" />} htmlFor="surfaceIndex" error={errors.surfaceIndex}>
                    <FormattedInput
                      suffix={'m²/m²'}
                      type="number"
                      name="surfaceIndex"
                      onChange={(value) => setFieldValue('surfaceIndex', value)}
                      value={values.surfaceIndex}
                    ></FormattedInput>
                  </FormField>
                )}

                <FormField label={<FormLabel label="admin.property.maxHeight" icon="" />} htmlFor="maxHeight" error={errors.maxHeight}>
                  <FormattedInput
                    suffix={'m'}
                    type="number"
                    name="maxHeight"
                    onChange={(value) => setFieldValue('maxHeight', value)}
                    value={values.maxHeight}
                  ></FormattedInput>
                </FormField>

                <FormField label={<FormLabel label="admin.property.maxGroundCover" icon="" />} htmlFor="maxGroundCover" error={errors.maxGroundCover}>
                  <FormattedInput
                    suffix={'%'}
                    type="number"
                    name="maxGroundCover"
                    onChange={(value) => setFieldValue('maxGroundCover', value)}
                    value={values.maxGroundCover}
                  ></FormattedInput>
                </FormField>

                <FormField label={<FormLabel label="admin.property.maxSoilSeal" icon="" />} htmlFor="maxSoilSeal" error={errors.maxSoilSeal}>
                  <FormattedInput
                    suffix={'%'}
                    type="number"
                    name="maxSoilSeal"
                    onChange={(value) => setFieldValue('maxSoilSeal', value)}
                    value={values.maxSoilSeal}
                  ></FormattedInput>
                </FormField>

                {type.id === 'hotelbuildingland' && (
                  <FormField label={<FormLabel label="admin.property.maxBeds" icon="" />} htmlFor="maxBeds" error={errors.maxBeds}>
                    <TextInput
                      type="number"
                      name="maxBeds"
                      onChange={handleChange}
                      value={values.maxBeds}
                      onFocus={() => (values.maxSoilSeal === 0 ? setFieldValue('maxSoilSeal', '') : null)}
                      onBlur={() => (values.maxSoilSeal === '' ? setFieldValue('maxSoilSeal', 0) : null)}
                    ></TextInput>
                  </FormField>
                )}

                <FormField label={<FormLabel label="admin.property.price" icon="" />} htmlFor="price" error={errors.price}>
                  <CurrencyInput name="price" onChange={(price) => setFieldValue('price', price)} value={values.price}></CurrencyInput>
                </FormField>

                <FormField label={<FormLabel label="admin.property.informations" icon="" />} htmlFor="informations" error={errors.informations}>
                  <TextArea type="text" name="informations" onChange={handleChange} value={values.informations}></TextArea>
                </FormField>

                <FormField label={intl.formatMessage({ id: 'admin.property.cataster' })} htmlFor="cataster" error={errors.cataster}>
                  <Text size="sm" className="pl-2 pr-2">
                    <FormattedMessage id="admin.property.cataster.descriptionLand" />
                  </Text>
                  <TextArea type="text" name="cataster" onChange={handleChange} value={values.cataster}></TextArea>
                </FormField>

                <Button
                  type="submit"
                  style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                  primary
                  disabled={isSubmitting}
                  label={intl.formatMessage({ id: 'global.actions.next' })}
                ></Button>
              </Form>
            )}
          </Formik>
        )}
        {type.type === 'agriculture' && (
          <Formik initialValues={agriculture} validate={this.onValidate} onSubmit={(values) => onSubmit(values)}>
            {({ values, errors, handleSubmit, handleChange, isSubmitting, setFieldValue }) => (
              <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                <Heading level="4" className="d-flex w-100 justify-content-center" alignSelf="center">
                  {type.name[intl.locale]}
                </Heading>

                {isAdmin && (
                  <FormField label={'ID'} htmlFor="property_id" className="flex-grow-1">
                    <TextInput type="number" name="property_id" onChange={handleChange} value={values.property_id}></TextInput>
                  </FormField>
                )}

                <FormField label={<FormLabel label="admin.property.catastralAreas" icon="" />} htmlFor="area" error={errors.area}>
                  <Box align="center" alignContent="center" direction="row">
                    <ReactSVG src={`/website/area-inner.svg`} className="mr-1" />
                    <FormattedInput
                      suffix={'m²'}
                      type="number"
                      name="area"
                      onChange={(value) => setFieldValue('area', value)}
                      value={values.area}
                    ></FormattedInput>
                  </Box>
                </FormField>

                <FormField label={<FormLabel label="admin.property.fountains" icon="" />} htmlFor="fountains" error={errors.fountains}>
                  <Box align="center" alignContent="center" direction="row">
                    <ReactSVG
                      src={`/website/water.svg`}
                      className="mr-1"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 48px; height: 48px')}
                    />
                    <TextInput
                      type="number"
                      name="fountains"
                      onChange={handleChange}
                      value={values.fountains}
                      onFocus={() => (values.fountains === 0 ? setFieldValue('fountains', '') : null)}
                      onBlur={() => (values.fountains === '' ? setFieldValue('fountains', 0) : null)}
                    ></TextInput>
                  </Box>
                </FormField>

                <FormField htmlFor="sprinklers" error={errors.sprinklers} label={intl.formatMessage({ id: `admin.property.sprinklers` })}>
                  <Box align="center" alignContent="center" direction="row">
                    <ReactSVG
                      src={`/website/sprinkler.svg`}
                      className="mr-1"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 48px; height: 48px')}
                    />
                    <Box pad="small" direction="column" flex="grow">
                      <RadioButton
                        label={intl.formatMessage({ id: 'admin.property.true' })}
                        name="sprinklers1"
                        value={true}
                        checked={values.sprinklers === true}
                        onChange={() => setFieldValue('sprinklers', true)}
                      />
                      <div className="mb-1"></div>
                      <RadioButton
                        label={intl.formatMessage({ id: 'admin.property.false' })}
                        name="sprinklers2"
                        value={false}
                        checked={values.sprinklers === false}
                        onChange={() => setFieldValue('sprinklers', false)}
                      />
                    </Box>
                  </Box>
                </FormField>

                <FormField htmlFor="hailnets" error={errors.hailnets} label={intl.formatMessage({ id: `admin.property.hailnets` })}>
                  <Box align="center" alignContent="center" direction="row">
                    <ReactSVG
                      src={`/website/hail.svg`}
                      className="mr-1"
                      beforeInjection={(svg) => svg.setAttribute('style', 'width: 48px; height: 48px')}
                    />
                    <Box pad="small" direction="column" flex="grow">
                      <RadioButton
                        label={intl.formatMessage({ id: 'admin.property.true' })}
                        name="hailnets1"
                        value={true}
                        checked={values.hailnets === true}
                        onChange={() => setFieldValue('hailnets', true)}
                      />
                      <div className="mb-1"></div>
                      <RadioButton
                        label={intl.formatMessage({ id: 'admin.property.false' })}
                        name="hailnets2"
                        value={false}
                        checked={values.hailnets === false}
                        onChange={() => setFieldValue('hailnets', false)}
                      />
                    </Box>
                  </Box>
                </FormField>

                <FormField label={<FormLabel label="admin.property.cultivation" icon="" />} htmlFor="fountains" error={errors.fountains}>
                  <CultivationSelector onChange={(value) => setFieldValue('cultivation', value)} value={values.cultivation} />
                </FormField>

                <FormField
                  label={intl.formatMessage({
                    id: `admin.property.price${rent === 'true' ? 'Rent' : ''}`
                  })}
                  htmlFor="price"
                  error={errors.price}
                >
                  <CurrencyInput name="price" onChange={(price) => setFieldValue('price', price)} value={values.price}></CurrencyInput>
                </FormField>

                <FormField label={<FormLabel label="admin.property.informations" />} htmlFor="informations" error={errors.informations}>
                  <TextArea type="text" name="informations" onChange={handleChange} value={values.informations}></TextArea>
                </FormField>

                <FormField label={intl.formatMessage({ id: 'admin.property.cataster' })} htmlFor="cataster" error={errors.cataster}>
                  <Text size="sm" className="pl-2 pr-2">
                    <FormattedMessage id="admin.property.cataster.descriptionLand" />
                  </Text>
                  <TextArea type="text" name="cataster" onChange={handleChange} value={values.cataster}></TextArea>
                </FormField>

                <Button
                  type="submit"
                  style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                  primary
                  disabled={isSubmitting}
                  label={intl.formatMessage({ id: 'global.actions.next' })}
                ></Button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    )
  }
}

export default injectIntl(SelectDialog)
