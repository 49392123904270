import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Heading, TextInput, Form, TextArea, Button, FormField } from 'grommet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Formik } from 'formik'
import { withRouter } from 'react-router-dom'
import LazyLoad from 'react-lazy-load'
import Axios from 'axios'

import Map from 'components/Map'
import Toast from 'components/Toast'

const Contact = props => {
  const [isContactError, setContactError] = useState(false)
  const [isContactSuccess, setContactSuccess] = useState(false)

  const { intl } = props
  return (
    <Container className="main-container pt-5 pb-3 pl-5 pr-5" id="contact">
      <Container>
        <Heading level={2} className="pt-5 pb-2">
          <FormattedMessage id="website.menu.contact"></FormattedMessage>
        </Heading>
      </Container>
      <Row>
        <Col sm={12} className="d-flex flex-column justify-content-center pl-3 pr-3 pb-4 pt-3">
          <Formik
            initialValues={{ name: '', email: '', message: '' }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              return Axios.post('/api/v1/contact', values)
                .then(response => {
                  if (response.status !== 200) throw new Error()
                  setSubmitting(false)
                  resetForm()
                  setContactSuccess(true)
                  setTimeout(() => setContactSuccess(true), 4000)
                })
                .catch(() => {
                  setContactError(true)
                  setTimeout(() => setContactError(false), 4000)
                  setSubmitting(false)
                })
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                <FormField label={intl.formatMessage({ id: 'global.properties.name' })} htmlFor="name">
                  <TextInput type="text" name="name" id="name" onChange={handleChange} value={values.name} required></TextInput>
                </FormField>
                <FormField label={intl.formatMessage({ id: 'global.properties.email' })} htmlFor="email">
                  <TextInput type="email" name="email" id="email" onChange={handleChange} value={values.email} required></TextInput>
                </FormField>
                <FormField label={intl.formatMessage({ id: 'global.properties.message' })} htmlFor="message">
                  <TextArea className="mb-3" name="message" id="message" onChange={handleChange} value={values.message} required />
                </FormField>
                <Button
                  type="submit"
                  style={{ maxWidth: '150px', marginLeft: 'auto' }}
                  primary
                  disabled={isSubmitting}
                  label={<FormattedMessage id="global.actions.send" />}
                ></Button>
                {isContactError && <Toast title={intl.formatMessage({ id: 'contact.error' })} isWebsite={true} isError={true} />}
                {isContactSuccess && <Toast title={intl.formatMessage({ id: 'contact.success' })} isWebsite={true} isSuccess={true} />}
              </Form>
            )}
          </Formik>
        </Col>
        <Col sm={12}>
          <LazyLoad height="400">
            <Map markerPosition={[46.6747582, 11.154163]} exactPosition />
          </LazyLoad>
        </Col>
      </Row>
    </Container>
  )
}

export default injectIntl(withRouter(Contact))
