import React, { PureComponent } from 'react'

/**
 * multipurpose loading skeleton, renders lines
 */
class LoadingPlaceholder extends PureComponent {
  render() {
    const count = this.props.count || 10
    const height = this.props.isTable ? '40px' : this.props.height || '80px'
    const loadingItems = []

    for (let i = 0; i < count; i++) {
      loadingItems.push(<div key={i} className="ui-loading-placeholder__row" style={{ height }}></div>)
    }

    return <div className={`ui-loading-placeholder ${this.props.className ? this.props.className : ''}`}>{loadingItems}</div>
  }
}

export default LoadingPlaceholder
