import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTrash, faFilePdf, faFileImage } from '@fortawesome/free-solid-svg-icons'
import Spinner from './Spinner'
import { Text } from 'grommet'

export default props => {
  const style = props.image && props.image.url ? { background: `url("${props.image.url}"` } : null
  const isPdf = props.image && props.image.pdfUrl
  const className = props.className || ''

  const onDelete = e => {
    e.stopPropagation()
    props.onDelete(props.image)
  }

  return (
    <div
      className={`ui-upload-item d-flex justify-content-center align-items-center col cpointer ${
        props.isLoading ? 'ui-image--loading' : ''
      } ${className}`}
      style={style}
      onClick={isPdf && !props.onClickPdf ? () => window.open(props.image.pdfUrl) : props.onClick}
    >
      {props.isLoading && !props.isError && <Spinner />}
      {props.isError && <FontAwesomeIcon icon={faTimes} color="red" size="3x" />}
      {props.image && props.image.name && (
        <div className={`ui-upload-item__file-name p-2 ${props.isEdit ? 'edit' : ''}`}>
          <FontAwesomeIcon icon={isPdf ? faFilePdf : faFileImage} color="#fff" />
          <Text size="small" color="white" textAlign="center" className="pl-1">
            {props.image.name}
          </Text>
        </div>
      )}
      <div className={`ui-upload-item__top-fade w-100 ${props.isEdit ? 'edit' : ''}`}></div>
      {props.isEdit && (
        <div className={`ui-upload-item__delete-button p-2 d-flex justify-content-end`}>
          <FontAwesomeIcon icon={faTrash} color="#fff" className="cpointer" onClick={onDelete} />
        </div>
      )}
      {props.number && (
        <div className="ui-upload-item__number d-flex justify-content-center align-items-center">
          <Text weight="bold" color="white" textAlign="center">
            {props.number}
          </Text>
        </div>
      )}
    </div>
  )
}
