import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import Dropzone from 'react-dropzone'

class Upload extends Component {
  constructor(props) {
    super(props)

    this.state = { isDrag: false }
  }

  render() {
    const { isDrag } = this.state
    const { id, isWeb, children, filetypes, onChange } = this.props

    return (
      <Dropzone
        onDrop={acceptedFiles => onChange(acceptedFiles) && this.setState({ isDrag: false })}
        onDragEnter={() => this.setState({ isDrag: true })}
        onDragLeave={() => this.setState({ isDrag: false })}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={`ui-image-upload ${isDrag && 'ui-image-upload--drag'}`} {...getRootProps()}>
            <label htmlFor={id} className="ui-image-upload__field bg-light d-flex w-100 align-items-center justify-content-center">
              <FontAwesomeIcon icon={faFileImage} size="3x" className="mr-3 pb-2"></FontAwesomeIcon>
              <span className={isWeb ? 'h5' : 'h3'}>{children}</span>
            </label>
            <input {...getInputProps()} multiple={true} accept={filetypes.join(',') || 'image/*'} />
          </div>
        )}
      </Dropzone>
    )
  }
}

export default Upload
