import React from 'react'
import { Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

const Toast = props => (
  <div className="ui-toast-container w-100 pb-sm-3 pl-sm-3 pr-sm-3">
    <div className={`ui-toast d-flex justify-content-between align-items-center ${props.isWebsite ? 'ui-toast-website' : ''}`}>
      <div>{props.title}</div>
      {props.isLoading && <Spinner animation="border" role="status" size="sm" />}
      {props.isSuccess && <FontAwesomeIcon icon={faCheck} color="#2ecc71" size="2x" />}
      {props.isError && <FontAwesomeIcon icon={faTimes} color="#e74c3c" size="2x" />}
      {props.children}
    </div>
  </div>
)

export default Toast
