import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactSVG } from 'react-svg'

/**
 * this component allows to display an icon left of the label
 *
 * @param {String} props.icon the svg uri
 * @param {String} label the intl id of the label
 * @param {String} append
 */
const FormLabel = props => (
  <span className="d-flex align-items-center">
    {props.icon && (
      <ReactSVG
        src={props.icon}
        className="mr-1"
        beforeInjection={svg => {
          svg.setAttribute('style', 'width: 32; height: 32px;')
        }}
      />
    )}
    <FormattedMessage id={props.label}></FormattedMessage>
    {props.append ? props.append : ''}
  </span>
)

export default FormLabel
