import React from 'react'

const LetterIcon = props => {
  const style = {
    borderColor: props.borderColor || '#343a40',
    color: props.color || '#000',
    backgroundColor: props.backgroundColor || 'transparent',
    marginRight: props.marginRight || '0.25rem',
    marginLeft: props.marginLeft || '0',
    width: props.width || '32px',
    height: props.height || '32px'
  }

  return (
    <div className="ui-letter-icon" style={style}>
      {props.letter}
    </div>
  )
}

export default LetterIcon
