import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Heading, Text } from 'grommet'
import { Container } from 'react-bootstrap'

class Impressum extends Component {
  render() {
    return (
      <Container className="main-container w-100 pb-2" direction="column">
        <Heading className="pt-2 pb-2 text-center">
          <FormattedMessage id="website.impressum.title" />
        </Heading>
        <Heading level={4}>
          <FormattedMessage id="website.impressum.owner" />:
        </Heading>
        <p>
          <Text>
            <FormattedHTMLMessage id="website.impressum.address"></FormattedHTMLMessage>
          </Text>
        </p>
        <Heading level={4}>
          <FormattedMessage id="website.menu.contact" />:
        </Heading>
        <div className="pb-1">
          <Text>+39 0473 201095</Text>
        </div>
        <div className="pb-1">
          <Text>
            <a href="mailto:info@profiimobilien.com">info@profiimobilien.com</a>
          </Text>
        </div>
        <div className="pb-5">
          <Text>
            <a href="http://www.profiimmobilien.com">http://www.profiimmobilien.com</a>
          </Text>
        </div>
        <Heading level={4}>
          <FormattedMessage id="website.impressum.liability.title" />:
        </Heading>
        <Text size="small" className="pt-2">
          <FormattedMessage id="website.impressum.liability.text" />:
        </Text>
      </Container>
    )
  }
}

export default injectIntl(withRouter(Impressum))
