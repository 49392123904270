import React, { Component } from 'react'
import { Button, Heading, Box, Form, FormField, Select, RadioButtonGroup, Text } from 'grommet'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'

import CurrencyInput from './CurrencyInput'

/**
 *
 * TODO: Load interests from database, on hold since this is not needed yet
 *
 *
 * @class BudgetDialog
 */
class BudgetDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      interests: { fixed: 2, variable: 1.9 }
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  /**
   * calculates budget
   */
  _calculateBudget(values) {
    const { interests } = this.state
    const interest = interests[values.interest]
    const r = 0.4 * values.income
    const d = 1 / (Math.pow(1 + interest / 1200, 12 * values.duration) - 1)

    const f = (1 + d) * (2 / 1200)
    const K = r / f
    return values.funds + K
  }

  onSubmit(values) {
    const { onSave, onClose } = this.props
    onSave(this._calculateBudget(values))
    onClose()
  }

  render() {
    const { intl, onClose } = this.props
    const durationOptions = [
      { name: intl.formatMessage({ id: 'format.years' }, { years: 10 }), value: 10 },
      { name: intl.formatMessage({ id: 'format.years' }, { years: 15 }), value: 15 },
      { name: intl.formatMessage({ id: 'format.years' }, { years: 20 }), value: 20 },
      { name: intl.formatMessage({ id: 'format.years' }, { years: 25 }), value: 25 },
      { name: intl.formatMessage({ id: 'format.years' }, { years: 30 }), value: 30 }
    ]

    return (
      <Formik
        initialValues={{
          funds: 0,
          income: 0,
          interest: 'fixed',
          duration: 20
        }}
        onSubmit={this.onSubmit}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => (
          <Modal show={true} onHide={onClose} size="md" aria-labelledby="contained-modal-title-vcenter" className="ui-select-seller-dialog" centered>
            <Modal.Header closeButton>
              <Heading level={3}>
                <FormattedMessage id="website.find.budget.title" />
              </Heading>
            </Modal.Header>
            <Modal.Body className="pl-4 pr-4" style={{ maxHeight: '70vh', overflow: 'auto' }}>
              <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                <FormField label={<FormattedMessage id="website.find.budget.funds" />} htmlFor="funds" error={errors.funds}>
                  <CurrencyInput name="funds" onChange={value => setFieldValue('funds', value)} value={values.funds}></CurrencyInput>
                </FormField>

                <FormField label={<FormattedMessage id="website.find.budget.income" />} htmlFor="income" error={errors.income}>
                  <CurrencyInput name="income" onChange={value => setFieldValue('income', value)} value={values.income}></CurrencyInput>
                </FormField>

                <FormField label={intl.formatMessage({ id: 'website.find.budget.duration' })} htmlFor="duration">
                  <Select
                    id="duration"
                    size="small"
                    name="duration"
                    valueLabel={<Box pad="small">{intl.formatMessage({ id: 'format.years' }, { years: values.duration })}</Box>}
                    options={durationOptions}
                    onChange={({ option }) => setFieldValue('duration', option.value)}
                  >
                    {option => <Box pad="small">{option.name}</Box>}
                  </Select>
                </FormField>
                <FormField className="d-flex align-items-center" htmlFor="rent" error={errors.interest}>
                  <RadioButtonGroup
                    name="rent"
                    className="w-50 pb-2 d-flex flex-row justify-content-around"
                    value={values.interest}
                    options={[
                      { disabled: false, id: 'fixed', value: 'fixed', label: intl.formatMessage({ id: `website.find.budget.interest.fixed` }) },
                      {
                        disabled: false,
                        id: 'variable',
                        className: 'pl-2',
                        value: 'variable',
                        label: intl.formatMessage({ id: `website.find.budget.interest.variable` })
                      }
                    ]}
                    onChange={event => setFieldValue('interest', event.target.value)}
                  />
                </FormField>

                <FormField label={<FormattedMessage id="website.find.budget.title" />} htmlFor="budget">
                  <CurrencyInput name="budget" readOnly onChange={() => {}} value={this._calculateBudget(values)}></CurrencyInput>
                </FormField>
                <Text className="mt-1 mb-1" size="sm">
                  <FormattedMessage id="website.find.budget.help" />
                </Text>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                primary
                onClick={handleSubmit}
                label={intl.formatMessage({ id: 'global.actions.save' })}
              ></Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    )
  }
}

export default injectIntl(BudgetDialog)
