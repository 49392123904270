import React, { Component } from 'react'
import { Button, Heading, Form, FormField, TableCell, Table, TableHeader, TableRow, TableBody } from 'grommet'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Row, Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import FormLabel from './FormLabel'
import FormattedInput from './FormattedInput'
import TextInput from './TextInput'

/**
 * This component allows the user to specify the cultivation details using a dialog
 *
 * @class CultivationSelector
 */
class CultivationSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      values: props.value,
      showDialog: false
    }
  }

  onChange = cultivation => {
    const { onChange } = this.props
    const { values } = this.state
    values.push(cultivation)
    this.setState({ values, showDialog: false })
    return onChange && onChange(values)
  }

  onValidate = values => {
    const { intl } = this.props
    const errors = {}

    if (!values.description) {
      errors.description = intl.formatMessage({ id: 'global.errors.required' })
    }
    if (!values.area || values.area <= 0) {
      errors.area = intl.formatMessage({ id: 'global.errors.required' })
    }
    return errors
  }

  render() {
    const { values, showDialog } = this.state
    const { intl, isReadOnly } = this.props

    const rows = values.map((val, i) => (
      <TableRow key={i}>
        <TableCell scope="row">
          <strong>{val.description}</strong>
        </TableCell>
        <TableCell>{intl.formatMessage({ id: 'format.area' }, { area: val.area })}</TableCell>
      </TableRow>
    ))

    return (
      <Row className="ui-cultivation-selector d-flex flex-column pl-2 pr-2">
        {values && values.length > 0 && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" border="bottom">
                  <FormattedMessage id="admin.property.cultivation.description" />
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <FormattedMessage id="admin.property.cultivation.area" />
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>{rows}</TableBody>
          </Table>
        )}
        {!isReadOnly && (
          <>
            <div className="pt-2">
              <Button label={<FormattedMessage id="global.actions.add" />} onClick={() => this.setState({ showDialog: true })} />
            </div>
            <Modal
              show={showDialog}
              onHide={() => this.setState({ showDialog: false })}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Heading level={3}>{<FormattedMessage id="admin.property.cultivation" />}</Heading>
              </Modal.Header>
              <Modal.Body>
                <Formik initialValues={{ description: '', area: 0 }} validate={this.onValidate} onSubmit={this.onChange}>
                  {({ values, errors, handleSubmit, handleChange, isSubmitting, setFieldValue }) => (
                    <Form className="w-100 d-flex flex-column">
                      <FormField
                        label={<FormLabel label="admin.property.cultivation.description" icon="" />}
                        htmlFor="description"
                        error={errors.description}
                      >
                        <TextInput type="text" name="description" onChange={handleChange} value={values.description}></TextInput>
                      </FormField>

                      <FormField label={<FormLabel label="admin.property.cultivation.area" icon="" />} htmlFor="area" error={errors.area}>
                        <FormattedInput
                          suffix={'m²'}
                          type="number"
                          name="area"
                          onChange={value => setFieldValue('area', value)}
                          value={values.area}
                        ></FormattedInput>
                      </FormField>

                      <Button
                        style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                        primary
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                        label={<FormattedMessage id="global.actions.add" />}
                      ></Button>
                    </Form>
                  )}
                </Formik>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Row>
    )
  }
}

export default injectIntl(CultivationSelector)
