import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import ImageLoader from './ImageLoader'

/**
 * Big image carousel to opened when the user clicks on an image
 *
 * @class ImageCarousel
 */
class ImageCarousel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 0
    }
  }

  next = () => {
    const { index } = this.state
    const { images } = this.props
    if (index === images.length - 1) {
      this.setState({ index: 0 })
    } else {
      this.setState({ index: index + 1 })
    }
  }

  prev = () => {
    const { index } = this.state
    const { images } = this.props
    if (index === 0) {
      this.setState({ index: images.length - 1 })
    } else {
      this.setState({ index: index - 1 })
    }
  }

  render() {
    const { images, className, onClickImage } = this.props
    const { index } = this.state
    return (
      images.length > 0 && (
        <div className={`ui-carousel d-sm-none d-flex ${className}`}>
          <div onClick={this.prev} className="ui-carousel__prev">
            <div>
              <FontAwesomeIcon icon={faChevronLeft} color="#212529" />
            </div>
          </div>
          <ImageLoader className="w-100" src={images[index]} onClick={() => onClickImage(index)} fit="cover" />
          <div onClick={this.next} className="ui-carousel__next">
            <div>
              <FontAwesomeIcon icon={faChevronRight} color="#212529" />
            </div>
          </div>
        </div>
      )
    )
  }
}

export default injectIntl(ImageCarousel)
