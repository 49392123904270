import 'react-app-polyfill/ie11'
import 'core-js/features/object/assign'
import 'core-js/features/url-search-params'
import 'core-js/features/promise'
import 'core-js/features/array/includes'
import 'core-js/features/array/find'
import 'core-js/features/string/repeat'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

/**
 * TODO: the current google tag manager tag does not work, wait for activation
 */
// import TagManager from 'react-gtm-module'
// if (process.env.NODE_ENV === 'production') {
//   const tagManagerArgs = {
//     gtmId: 'GTM-NJ3TGQ8'
//   }
//   TagManager.initialize(tagManagerArgs)
// }

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement)
} else {
  ReactDOM.render(<App />, rootElement)
}

serviceWorker.unregister()
