import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Row, Pagination } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

class Paginate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: props.page
    }
  }

  onChange = page => {
    const searchParams = new URLSearchParams(this.props.location.search)

    searchParams.set('page', page)

    const urlParams = searchParams.toString()
    // redirect to search with params
    this.props.history.push(`/search?${urlParams}`)
  }

  render() {
    const meta = this.props.meta
    const items = []

    for (let i = 1; i <= meta.numOfPages; i++) {
      items.push(
        <Pagination.Item key={i} active={meta.page === i} onClick={() => this.onChange(i)}>
          {i}
        </Pagination.Item>
      )
    }

    return (
      <Row className="d-flex justify-content-center">
        <Pagination>
          <Pagination.Prev />
          {items}
          <Pagination.Next />
        </Pagination>
      </Row>
    )
  }
}

export default withRouter(injectIntl(Paginate))
