import React, { Component } from 'react'
import Spinner from './Spinner'
import { authFetch } from 'utils/website-utils'
import { Heading } from 'grommet'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal, Button } from 'react-bootstrap'
import UploadItem from './UploadItem'
import { logError } from 'utils/sentry-log'

/**
 * This component is a dialog that allows to select the main Images of a property
 *
 * TODO: Remove image selection ability in this dialog maybe, just make it a simple confirm
 *
 * @class ConfirmDialog
 */
class ConfirmDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      show: false,
      step: 1
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  /**
   * The dialog is opened and the images are fetched when the id in the props changes
   *
   * @param {*} prevProps
   */
  async componentDidUpdate(prevProps) {
    const { type, id } = this.props
    if (prevProps.id !== this.props.id && type && id) {
      this.setState({ show: true, isLoading: true, step: 1 })

      const response = await authFetch('get', `/api/v1/properties/${type}/${id}?populate=[{"path":"images"},{"path":"plans"},{"path":"type"}]`)
      if (response && response.data) {
        this.setState({ property: response.data })
      }
      this.setState({ isLoading: false })
    }
  }

  selectMainImage = image => {
    const { property } = this.state
    property['mainImage'] = image._id
    this.setState({ property })
  }

  toggleMainImages = image => {
    const { property } = this.state
    const index = property.mainImages.indexOf(image._id)

    // if image is not select, select it
    if (index < 0) {
      property.mainImages.length < 4 && property.mainImages.push(image._id)
    } else {
      // else remove it
      property.mainImages.splice(index, 1)
    }
    this.setState({ property })
  }

  nextStep = () => {
    const { step } = this.state
    if (step === 1) return this.setState({ step: 2 })
    return this.onSubmit()
  }

  /**
   * patches the current property with the mainImage(s)
   *
   * @function onSubmit
   */
  async onSubmit() {
    const { property } = this.state
    const { type, id, onClose } = this.props
    try {
      property.isConfirmed = true
      const response = await authFetch('patch', `/api/v1/properties/${type}/${id}`, property)

      if (!response || !response.status === 200) throw new Error('[CONFIRM DIALOG]: Submit')
      this.setState({ show: false })

      onClose && onClose(response.data)
    } catch (e) {
      logError(e)
    }
  }

  _isNextStepDisabled() {
    const { property, step } = this.state
    if (step === 1) {
      return property && !property.mainImage && property.images.length > 0
    }
    return !(property && (property.mainImages.length === 4 || property.plans.length + property.images.length === property.mainImages.length))
  }

  render() {
    const { show, isLoading, property, step } = this.state
    const { intl } = this.props

    // checks whether the next step is disabled
    const nextDisabled = this._isNextStepDisabled()
    let images = null

    if (step === 1) {
      images = ((property && property.images) || []).map(image => (
        <UploadItem
          key={image._id}
          className={property.mainImage && property.mainImage === image._id && 'selected'}
          onClick={() => this.selectMainImage(image)}
          image={image}
          onClickPdf={true}
        />
      ))
    } else {
      images = ((property && property.images.concat(property.plans)) || []).map(image => (
        <UploadItem
          key={image._id}
          number={property.mainImages.indexOf(image._id) > -1 ? property.mainImages.indexOf(image._id) + 1 : null}
          className={property.mainImages.indexOf(image._id) > -1 && 'selected'}
          onClick={() => this.toggleMainImages(image)}
          image={image}
          onClickPdf={true}
        />
      ))
    }

    return (
      <Modal show={show} onHide={() => {}} size="xl" aria-labelledby="contained-modal-title-vcenter" className="ui-select-seller-dialog" centered>
        <Modal.Header>
          <Heading level={3}>{property && property.type.name[intl.locale]}</Heading>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            {isLoading ? (
              <Spinner isSuspense={true} height={300} />
            ) : (
              <div className="d-flex flex-column">
                <Heading level={3}>
                  <FormattedMessage id={step === 1 ? 'admin.confirm.selectMainImage' : 'admin.confirm.selectMainImages'} />{' '}
                </Heading>
                <div className="d-flex flex-wrap">{images}</div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {step === 2 && (
            <Button variant="outline-warning" onClick={() => this.setState({ step: 1 })}>
              <FormattedMessage id="global.actions.back" />
            </Button>
          )}
          <Button variant="warning" disabled={nextDisabled} onClick={this.nextStep}>
            <FormattedMessage id={step === 1 ? 'global.actions.next' : 'global.actions.save'} />
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default injectIntl(ConfirmDialog)
