import React, { useState } from 'react'
import { Tabs, Tab, Box, FormField, TextArea, Button } from 'grommet'
import { injectIntl, FormattedMessage } from 'react-intl'

import TextInput from './TextInput'
import { Formik } from 'formik'

/**
 *
 * @class DescriptionForm
 */
const DescriptionForm = props => {
  const [activeIndex, setIndex] = useState(0)
  const { intl, onSubmit } = props

  return (
    <Formik initialValues={{ description: { it: '', de: '', en: '' }, title: { it: '', de: '', en: '' } }} onSubmit={() => {}}>
      {({ values, handleChange }) => (
        <>
          <Tabs className="pt-3" activeIndex={activeIndex} onActive={i => setIndex(i)}>
            <Tab title={intl.formatMessage({ id: 'global.properties.language.de' })}>
              <Box direction="column" pad="small">
                <FormField label={<FormattedMessage id="admin.property.title" />} htmlFor="title.de" className="flex-grow-1">
                  <TextInput type="text" name="title.de" onChange={handleChange} value={values.title.de}></TextInput>
                </FormField>

                <FormField label={<FormattedMessage id="admin.property.description" />} htmlFor="description.de" className="flex-grow-1">
                  <TextArea name="description.de" rows="10" onChange={handleChange} value={values.description.de}></TextArea>
                </FormField>
              </Box>
            </Tab>

            <Tab title={intl.formatMessage({ id: 'global.properties.language.it' })}>
              <Box direction="column" pad="small">
                <FormField label={<FormattedMessage id="admin.property.title" />} htmlFor="title.it" className="flex-grow-1">
                  <TextInput type="text" name="title.it" onChange={handleChange} value={values.title.it}></TextInput>
                </FormField>

                <FormField label={<FormattedMessage id="admin.property.description" />} htmlFor="description.it" className="flex-grow-1">
                  <TextArea name="description.it" rows="10" onChange={handleChange} value={values.description.it}></TextArea>
                </FormField>
              </Box>
            </Tab>

            <Tab title={intl.formatMessage({ id: 'global.properties.language.en' })}>
              <Box direction="column" pad="small">
                <FormField label={<FormattedMessage id="admin.property.title" />} htmlFor="title.en" className="flex-grow-1">
                  <TextInput type="text" name="title.en" onChange={handleChange} value={values.title.en}></TextInput>
                </FormField>

                <FormField label={<FormattedMessage id="admin.property.description" />} htmlFor="description.en" className="flex-grow-1">
                  <TextArea name="description.en" rows="10" onChange={handleChange} value={values.description.en}></TextArea>
                </FormField>
              </Box>
            </Tab>
          </Tabs>
          <Button
            style={{ marginLeft: 'auto', marginTop: '16px', display: `flex` }}
            primary
            onClick={() => onSubmit(values)}
            label={intl.formatMessage({ id: 'global.actions.next' })}
          ></Button>
        </>
      )}
    </Formik>
  )
}

export default injectIntl(DescriptionForm)
