import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

import messages_it from 'translations/it.json'
import messages_de from 'translations/de.json'
import messages_en from 'translations/en.json'
import { formats } from 'translations/formats'

// create object with all translation files
const messages = {
  it: messages_it,
  de: messages_de,
  en: messages_en
}

// map the locale set inside the state directly to the intl-provider props
function mapStateToProps(state) {
  const { locale } = state.language
  return { locale, messages: messages[locale], formats }
}

/**
 * This "component" connects the intl provider to the application
 */
export default connect(mapStateToProps)(IntlProvider)
