import React, { Component, lazy, Suspense } from 'react'
import { withRouter, Switch, Route, Link } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Navbar, Container, Nav } from 'react-bootstrap'
import { Menu, Text } from 'grommet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faLock, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'

// routes
import Home from './Website/Home'
import SellCreate from './Website/SellCreate'
import Account from './Website/Account'
import Confirm from './Website/Confirm'
import Search from './Website/Search'
import Property from './Website/Property'
import Login from './Website/Login'
import Find from './Website/Find'

//redux
import { setLanguage } from 'store/actions/language'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LoadingPlaceholder from 'components/LoadingPlaceholder'
import ImageLoader from 'components/ImageLoader'
import Impressum from './Website/Impressum'
import CookieConsent from 'components/CookieConsent'
import Privacy from './Website/Privacy'
import Logout from './Website/Logout'
import ErrorRoute from './ErrorRoute'
import FindSearch from './Website/FindSearch'
import ReactPixel from 'react-facebook-pixel'
import AboutUs from './Website/AboutUs'
import Testimonies from './Website/Testimonies'
import Contact from './Website/Contact'
import { ReactSVG } from 'react-svg'

const WebsiteFooter = lazy(() => import('components/WebsiteFooter'))

class Website extends Component {
  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      ReactPixel.init('522413515119157')
      ReactPixel.pageView()
    }
  }

  changeLanguage = (locale) => {
    window.localStorage.setItem('language', locale)
    window.location.reload()
  }

  render() {
    const { intl, location, auth } = this.props

    // don't render if the user is in the admin panel. hack that allows nested routes with the current url structure
    if (location.pathname.indexOf('/admin') >= 0) {
      return <></>
    }

    const items = [
      { label: intl.formatMessage({ id: 'languages.it' }), onClick: () => this.changeLanguage('it'), locale: 'it' },
      { label: intl.formatMessage({ id: 'languages.de' }), onClick: () => this.changeLanguage('de'), locale: 'de' },
      { label: intl.formatMessage({ id: 'languages.en' }), onClick: () => this.changeLanguage('en'), locale: 'en' }
    ].filter((lang) => lang.locale !== intl.locale)

    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage({ id: 'website.pageTitle' })}</title>
          <meta name="description" content={intl.formatMessage({ id: 'website.description' })} />
        </Helmet>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Container fluid style={{ maxWidth: '1300px' }}>
            <Navbar.Brand className="pt-0 pb-0 d-flex align-items-center" href="/">
              <ImageLoader src="/logo.png" className="d-inline-block align-top" alt="Profi Immobilien logo" height="39" />
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="nav-menu w-100 d-flex justify-content-end p-2 p-md-0">
                <div className="d-flex">
                  <Nav.Link as={Link} to="/" href="/" className="flex-grow-1 pb-2 pb-md-0 pt-4 pt-md-2 ">
                    <FormattedMessage id="website.menu.home" />
                  </Nav.Link>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ui-navigation-bar-close">
                    <ReactSVG src="website/x.svg" />
                  </Navbar.Toggle>
                </div>

                <Nav.Link as={Link} to="/search" href="/search" className="pb-2 pb-md-0 pt-4 pt-md-2 ">
                  <FormattedMessage id="website.menu.properties" />
                </Nav.Link>
                {intl.locale !== 'en' && (
                  <>
                    <Nav.Link href="/about-us" className="pb-2 pb-md-0 pt-4 pt-md-2">
                      <FormattedMessage id="website.menu.aboutus" />
                    </Nav.Link>
                    <Nav.Link href="/testimonies" className="pb-2 pb-md-0 pt-4 pt-md-2">
                      <FormattedMessage id="website.menu.testimonies" />
                    </Nav.Link>
                  </>
                )}

                <Nav.Link href="/contact" className="pb-2 pb-md-0 pt-4 pt-md-2">
                  <FormattedMessage id="website.menu.contact" />
                </Nav.Link>

                <Nav.Link href="tel:+390473201095" className="d-flex align-items-center text-primary pb-2 pb-md-0 pt-4 pt-md-2">
                  <span className="d-flex d-lg-none">
                    <FormattedMessage id="contact.call" />
                  </span>
                  <FontAwesomeIcon icon={faPhoneAlt} className="ml-2"></FontAwesomeIcon>
                </Nav.Link>

                {auth.isWebsiteAuth ? (
                  <>
                    <Nav.Link as={Link} href="/account" to="/account" className="pb-2 pb-md-0 pt-4 pt-md-2">
                      Account
                      <FontAwesomeIcon icon={faUserCircle} className="ml-2"></FontAwesomeIcon>
                    </Nav.Link>
                    {auth.websiteUser.isAdmin && (
                      <Nav.Link as={Link} href="/admin" to="/admin" className="danger pb-2 pb-md-0 pt-4 pt-md-2">
                        Admin
                      </Nav.Link>
                    )}
                    {auth.websiteUser.isAgent && !auth.websiteUser.isAdmin && (
                      <Nav.Link as={Link} href="/admin" to="/admin" className="danger pb-2 pb-md-0 pt-4 pt-md-2">
                        <FormattedMessage id="admin.privateArea"></FormattedMessage>
                      </Nav.Link>
                    )}
                    <Nav.Link as={Link} href="/logout" to="/logout" className="pb-2 pb-md-0 pt-4 pt-md-2">
                      Logout
                    </Nav.Link>
                  </>
                ) : (
                  <Nav.Link as={Link} href="/login" to="/login" className="pb-2 pb-md-0 text-primary pt-4 pt-md-2">
                    Login
                    <FontAwesomeIcon icon={faLock} className="ml-2"></FontAwesomeIcon>
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
            <Menu
              className="pl-sm-2 pt-1 ml-auto mr-sm-1"
              small
              border
              label={<Text size="small">{intl.formatMessage({ id: `languages.${intl.locale}` })}</Text>}
              items={items}
            />
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ui-navigation-bar-close">
              <ReactSVG src="website/menu.svg" />
            </Navbar.Toggle>
          </Container>
        </Navbar>
        {/* CONTENT */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/sell" component={SellCreate} />
          <Route exact path="/edit/:type/:id" component={SellCreate} />
          <Route exact path="/find" component={Find} />
          <Route exact path="/find/searches/:id" component={FindSearch} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/confirm" component={Confirm} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/impressum" component={Impressum} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/testimonies" component={Testimonies} />
          <Route exact path="/contact" component={Contact} />

          <Route path="/search" component={Search} />
          <Route path="/property/:type/:id" component={Property} />
          <Route path="/logout" component={Logout} />
          <Route render={ErrorRoute} />
        </Switch>
        {/* FOOTER */}
        <Suspense fallback={<LoadingPlaceholder count={1} />}>
          <WebsiteFooter />
        </Suspense>
        <CookieConsent />
      </div>
    )
  }
}

Website.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { setLanguage })(injectIntl(withRouter(Website)))
