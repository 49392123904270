import React, { Component } from 'react'
import { withRouter, Prompt } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Container, Row, Col } from 'react-bootstrap'
import { Box, Heading, Text, TextInput, FormField, Button, Form, Meter, Select, RadioButtonGroup } from 'grommet'
import { Formik } from 'formik'
import { ReactSVG } from 'react-svg'
import { deepMerge } from 'grommet/utils'

import LoadingPlaceholder from 'components/LoadingPlaceholder'
import WebPropertyForm from 'components/WebPropertyForm'
import { authFetch } from 'utils/website-utils'
import PositionSelector from 'components/PositionSelector'
import SelectSellerDialog from 'components/SelectSellerDialog'
import PropertyTypeSelector from 'components/PropertyTypeSelector'
import CatasterUploadView from 'components/CatasterUploadView'
import DescriptionForm from 'components/DescriptionForm'
import { logError } from 'utils/sentry-log'
import UploadView from 'containers/hocs/UploadView'

const initialSellerValues = (isAdmin, isAgent, email, intl, client) => {
  return {
    email: isAdmin || isAgent ? '' : email,
    sellerType: 'private',
    salutation: 'Signor',
    name: client ? client.name : '',
    familyName: client ? client.familyName : '',
    companyName: '',
    tel: '',
    language: intl.locale,
    isSelfInserted: !isAdmin,
    location: null,
    pec: '',
    taxCode: ''
  }
}

class SellCreate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      step: 0,
      seller: null,
      types: [],
      type: '',
      rent: 'false',
      property: null,
      location: null,
      video: '',
      plans: [],
      images: [],
      step3Percent: 20,
      me: null,
      // the seller model of the agent is added to the property if it's sold by an agent
      agent: null,
      // whether the seller is an external agent
      isAgent: false,
      // whether the user is an administrator
      isAdmin: false,
      isProfiAgent: false,
      isSuperAdmin: false,
      isEdit: false,
      propertyStep: 0,
      isSubmitDisabled: false
    }
    this.onSellerSubmit = this.onSellerSubmit.bind(this)
    this.onPropertySubmit = this.onPropertySubmit.bind(this)
  }

  /**
   * load me and types, go to correct step depending on whether isAdmin, externalAgent or normal Seller
   */
  async componentDidMount() {
    await this.setState({ isLoading: true })
    const { history, auth, match, intl } = this.props

    if (!auth.isWebsiteAuth) {
      return history.push('/login?callback=sell')
    }

    const me = await authFetch('get', '/api/v1/account/me').then(response => response.data)
    // if is admin set admin to true
    if (me.admin) {
      await this.setState({ isAdmin: true, isProfiAgent: me.admin.isAgent, isSuperAdmin: me.admin.isAdmin })
    } else if (me.seller) {
      // if is external agent set agent to true
      if (me.seller.sellerType === 'agent') {
        await this.setState({ isAgent: true, agent: me.seller })
      } else {
        // else skip the first step
        await this.setState({ seller: me.seller, step: 1 })
      }
    }
    const { email } = this.props.auth.websiteUser
    const { isAdmin, isAgent } = this.state
    const seller = me.seller && me.seller.sellerType !== 'agent' ? me.seller : {}
    await this.setState({ initialValuesSeller: deepMerge(initialSellerValues(isAdmin, isAgent, email, intl, me.client), seller || {}) })
    await authFetch('get', '/api/v1/types?sort=-type').then(response => (response && response.data ? this.setState({ types: response.data }) : null))

    const { id, type } = match.params

    if (id && type) {
      const property = await authFetch(
        'get',
        `/api/v1/properties/${type}/${id}?populate=[{"path":"type"},{"path":"plans"},{"path":"images"},{"path":"owner"}]`
      ).then(result => result.data)
      this.setState({
        isEdit: true,
        property,
        plans: property.plans,
        seller: property.owner,
        location: property.location,
        type: property.type,
        rent: property.rent.toString()
      })
    }

    this.setState({ isLoading: false, me })
  }

  onValidate = values => {
    const { step, isAgent } = this.state

    let errors = {}
    if (step === 0) {
      if (isAgent && !values.name && !values.familyName) {
        return errors
      }

      if (!values.email && !values.tel) {
        errors.email = this.props.intl.formatMessage({ id: 'global.errors.emailOrTel' })
        errors.tel = this.props.intl.formatMessage({ id: 'global.errors.emailOrTel' })
      }
      if (!values.name) errors.name = this.props.intl.formatMessage({ id: 'global.errors.required' })
      if (!values.familyName) errors.familyName = this.props.intl.formatMessage({ id: 'global.errors.required' })
    } else if (step === 2) {
      if (!values.type) errors.type = this.props.intl.formatMessage({ id: 'global.errors.required' })
    }

    return errors
  }

  /**
   * submit the seller and either set it as owner or owner and add it to account
   */
  async onSellerSubmit(values, { setErrors, setSubmitting }) {
    const { intl, auth } = this.props
    const { isAdmin, isAgent, isEdit, seller } = this.state

    if (values && values.sellerType === 'private') {
      delete values.pec
    }

    // dont send request if agent does not input seller data
    if (isAgent && !values.name && !values.familyName) {
      this.setState({ step: 1, seller: null })
      return
    }
    try {
      // remove empty strings
      Object.keys(values).forEach(key => {
        if (values[key] === '') delete values[key]
      })

      const response = await authFetch(isEdit && seller ? 'patch' : 'post', `/api/v1/sellers${isEdit && seller ? `/${seller._id}` : ''}`, values)

      if (!response || !response.data) {
        setErrors({ email: intl.formatMessage({ id: 'global.errors.duplicateEmail' }) })
        throw new Error('[CREATE-SELLER]')
      }
      const newSeller = response.data

      // add seller to account if user is not agent or admin
      if (newSeller && newSeller._id && !isAdmin && !isAgent) {
        await authFetch('patch', `/api/v1/account/${auth.websiteUser.id}`, { seller: newSeller._id })
      }

      if (newSeller.sellerType === 'agent') {
        window.location.reload()
      }

      this.setState({ step: 1, seller: newSeller })
    } catch (e) {
      logError(e)
      setSubmitting(false)
    }
  }

  async onPropertySubmit() {
    this.setState({ isSubmitDisabled: true })
    const { seller, plans, type, rent, location, property, agent, isEdit, isProfiAgent, isSuperAdmin, images, video } = this.state
    const videoLink = video === '' ? null : video
    const additionalProperties = {
      owner: seller ? seller._id : undefined,
      plans,
      images,
      video: videoLink,
      type: type._id,
      rent: rent === 'true' ? true : false,
      location,
      // property is directly confirmed if entered by an administrator
      isConfirmed: isSuperAdmin,
      externalAgent: agent ? (agent._id ? agent._id : agent) : undefined,
      mainImages: [],
      mainImage: null
    }
    const propertyObject = Object.assign(property, additionalProperties)

    try {
      const url = `/api/v1/properties/${type.type}${isEdit ? `/${property._id}` : ''}`
      const response = await authFetch(isEdit ? 'patch' : 'post', url, propertyObject)

      if (!response || !response.status === 201 || !response.data) throw new Error('[SELL-CREATE] create property')

      if (isSuperAdmin && !isProfiAgent) {
        return this.props.history.push(`/account?newproperty=${response.data._id}&type=${type.type}`)
      }
      return this.props.history.push('/account')
    } catch (e) {
      logError(e)
    }
  }

  render() {
    const {
      step,
      types,
      isLoading,
      type,
      rent,
      location,
      plans,
      step3Percent,
      isAdmin,
      isAgent,
      seller,
      property,
      isEdit,
      propertyStep,
      initialValuesSeller,
      isSubmitDisabled,
      images
    } = this.state
    const intl = this.props.intl

    // options of the selects
    // TODO: move to constants/util
    const sellerTypeOptions = [
      { name: intl.formatMessage({ id: 'global.properties.sellerType.private' }), value: 'private' },
      { name: intl.formatMessage({ id: 'global.properties.sellerType.agent' }), value: 'agent' },
      { name: intl.formatMessage({ id: 'global.properties.sellerType.construction' }), value: 'construction' }
    ]
    const salutationOptions = [
      { name: intl.formatMessage({ id: 'global.properties.salutation.m' }), value: 'Signor' },
      { name: intl.formatMessage({ id: 'global.properties.salutation.f' }), value: 'Signora' }
    ]
    const languageOptions = [
      { name: intl.formatMessage({ id: 'global.properties.language.it' }), value: 'it' },
      { name: intl.formatMessage({ id: 'global.properties.language.de' }), value: 'de' },
      { name: intl.formatMessage({ id: 'global.properties.language.en' }), value: 'en' }
    ]

    const percent = step < 2 ? 0 : step === 2 ? 10 : step === 3 ? step3Percent : 90
    return (
      <Container className="main-container sell pb-5">
        <React.Fragment>
          <Prompt when={step > 0 && !isSubmitDisabled} message={intl.formatMessage({ id: 'global.unsavedChanges' })} />
        </React.Fragment>
        <Row>
          <Col sm={12} lg={4} className="d-flex flex-column justify-content-center align-content-center pb-4">
            <Box fill alignContent="center" align="center" direction="column" animation="fadeIn">
              <Heading level="5" className="w-100 text-center">
                <FormattedMessage id={isEdit ? 'website.sell.edit.title' : 'website.sell.new.title'}></FormattedMessage>
              </Heading>
              <ReactSVG
                src="/website/sale.svg"
                className="w-50 p-3"
                beforeInjection={svg => svg.setAttribute('style', 'width: 100%; height: 10%;')}
                style={{ fill: '#212529' }}
              />
              <Meter
                values={[
                  {
                    value: percent,
                    onClick: () => {},
                    color: 'brand'
                  }
                ]}
                aria-label="meter"
              />
              <Text className="text-center">
                {percent}
                <FormattedMessage id="website.sell.new.percent"></FormattedMessage>
              </Text>
              {step >= 2 && !(step === 3 && type.type === 'residential' && step3Percent !== 20) && (
                <Button
                  type="button"
                  primary
                  label={<FormattedMessage id="global.actions.back" />}
                  className="mt-2"
                  onClick={() => this.setState({ step: step - 1, propertyStep: (step3Percent - 20) / 10 })}
                />
              )}
            </Box>
          </Col>
          {isLoading ? (
            <LoadingPlaceholder isTable={true} className="col"></LoadingPlaceholder>
          ) : (
            <Col sm={12} lg={8}>
              {/* STEP 0: PERSONAL DATA */}
              {step === 0 && (
                <>
                  <Heading level="3" className="text-center">
                    <FormattedMessage id={`website.sell.new.step0.title${isAdmin || isAgent ? '.customer' : ''}`}></FormattedMessage>
                  </Heading>
                  {isAdmin && (
                    <>
                      <SelectSellerDialog selectSeller={seller => this.setState({ seller })} />
                      {seller && (
                        <div className="d-flex flex-column">
                          <Text weight="bold">
                            {seller.name} {seller.familyName}
                          </Text>
                          <Text>{seller.email}</Text>
                          <Button
                            style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                            label={intl.formatMessage({ id: 'global.actions.next' })}
                            onClick={() => this.setState({ step: 1 })}
                            primary
                          ></Button>
                        </div>
                      )}
                    </>
                  )}
                  {(!seller || isEdit) && (
                    <Formik initialValues={initialValuesSeller} validate={this.onValidate} onSubmit={this.onSellerSubmit}>
                      {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                        <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                          {(isAdmin || isAgent) && (
                            <Heading level="4">
                              <FormattedMessage id={`website.sell.new.step0.${isAdmin ? 'newCustomer' : 'optional'}`} />
                            </Heading>
                          )}
                          <FormField label="Email" htmlFor="email" error={errors.email}>
                            <TextInput
                              type="email"
                              name="email"
                              required
                              disabled={!isAdmin && !isAgent}
                              onChange={handleChange}
                              value={values.email}
                            ></TextInput>
                          </FormField>
                          <FormField label={intl.formatMessage({ id: 'global.properties.sellerType' })} htmlFor="sellerType">
                            <Select
                              id="sellerType"
                              size="small"
                              name="sellerType"
                              valueLabel={
                                <Box pad="small">
                                  {values.sellerType && intl.formatMessage({ id: `global.properties.sellerType.${values.sellerType}` })}
                                </Box>
                              }
                              options={isAgent ? sellerTypeOptions.filter(opt => opt.value !== 'agent') : sellerTypeOptions}
                              children={option => <Box pad="small">{option.name}</Box>}
                              onChange={({ option }) => setFieldValue('sellerType', option.value)}
                            />
                          </FormField>
                          <FormField label={intl.formatMessage({ id: 'global.properties.salutation' })} htmlFor="salutation">
                            <Select
                              id="salutation"
                              size="small"
                              name="salutation"
                              valueLabel={
                                <Box pad="small">
                                  {values.salutation &&
                                    intl.formatMessage({ id: `global.properties.salutation.${values.salutation === 'Signor' ? 'm' : 'f'}` })}
                                </Box>
                              }
                              options={salutationOptions}
                              children={option => <Box pad="small">{option.name}</Box>}
                              onChange={({ option }) => setFieldValue('salutation', option.value)}
                            />
                          </FormField>
                          <FormField label={intl.formatMessage({ id: 'global.properties.name' })} htmlFor="name" error={errors.name}>
                            <TextInput type="text" name="name" onChange={handleChange} value={values.name}></TextInput>
                          </FormField>
                          <FormField
                            label={intl.formatMessage({ id: 'global.properties.familyName' })}
                            htmlFor="familyName"
                            error={errors.familyName}
                          >
                            <TextInput type="text" name="familyName" onChange={handleChange} value={values.familyName}></TextInput>
                          </FormField>
                          {values.sellerType === 'construction' && (
                            <FormField
                              label={intl.formatMessage({ id: 'global.properties.companyName' })}
                              htmlFor="companyName"
                              error={errors.companyName}
                            >
                              <TextInput type="text" name="companyName" onChange={handleChange} value={values.companyName}></TextInput>
                            </FormField>
                          )}
                          <FormField label={intl.formatMessage({ id: 'global.properties.tel' })} htmlFor="tel" error={errors.tel}>
                            <TextInput type="text" name="tel" onChange={handleChange} value={values.tel}></TextInput>
                          </FormField>
                          <FormField label={intl.formatMessage({ id: 'global.properties.language' })} htmlFor="language" error={errors.language}>
                            <Select
                              id="language"
                              size="small"
                              name="language"
                              valueLabel={
                                <Box pad="small">
                                  {values.language && intl.formatMessage({ id: `global.properties.language.${values.language}` })}
                                </Box>
                              }
                              options={languageOptions}
                              children={option => <Box pad="small">{option.name}</Box>}
                              onChange={({ option }) => setFieldValue('language', option.value)}
                            />
                          </FormField>
                          {values.sellerType !== 'private' && (
                            <FormField label="PEC" htmlFor="pec">
                              <TextInput type="text" name="pec" onChange={handleChange} value={values.pec}></TextInput>
                            </FormField>
                          )}
                          <Button
                            type="submit"
                            style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                            primary
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            label={intl.formatMessage({ id: 'global.actions.next' })}
                          ></Button>
                        </Form>
                      )}
                    </Formik>
                  )}
                </>
              )}
              {/* STEP 1: TYPE */}
              {step === 1 && (
                <Formik
                  initialValues={{ type, rent }}
                  validate={this.onValidate}
                  onSubmit={values => {
                    this.setState({ type: values.type, rent: values.rent, step: 2 })
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }}
                >
                  {({ values, errors, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                      <Heading alignSelf="center" level="4" className="text-center">
                        <FormattedMessage id={`admin.property.rent.title`}></FormattedMessage>
                      </Heading>
                      <FormField htmlFor="rent" error={errors.rent}>
                        <RadioButtonGroup
                          name="rent"
                          className="pb-2"
                          value={values.rent}
                          options={[
                            { disabled: false, id: 'false', value: 'false', label: intl.formatMessage({ id: `admin.property.rent.false` }) },
                            { disabled: false, id: 'true', value: 'true', label: intl.formatMessage({ id: `admin.property.rent.true` }) }
                          ]}
                          onChange={event => setFieldValue('rent', event.target.value)}
                        />
                      </FormField>
                      <Heading alignSelf="center" level="4" className="text-center">
                        {intl.formatMessage({ id: 'website.sell.new.step2.title' })}
                      </Heading>
                      <FormField htmlFor="type" error={errors.type}>
                        <PropertyTypeSelector
                          types={types}
                          value={type}
                          disabled={values.rent === 'true' ? types.filter(type => type.type === 'buildingland') : []}
                          onChange={type => {
                            setFieldValue('type', type)
                            // set values to null when type changes
                            this.setState({ property: null })
                          }}
                        ></PropertyTypeSelector>
                      </FormField>
                      <Button
                        type="submit"
                        style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                        primary
                        disabled={isSubmitting}
                        label={intl.formatMessage({ id: 'global.actions.next' })}
                      ></Button>
                    </Form>
                  )}
                </Formik>
              )}
              {/* STEP 2: LOCATION */}
              {step === 2 && (
                <div className="flex flex-column">
                  <Heading level="3" className="text-center">
                    <FormattedMessage id={`website.sell.new.step1.title`}></FormattedMessage>
                  </Heading>
                  <PositionSelector location={location} onLocationChange={location => this.setState({ location })} hideTitle={true} exactPosition />

                  <Button
                    style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px', display: `flex` }}
                    primary
                    disabled={location ? !location.address.streetNumber : true}
                    onClick={() => {
                      this.setState({ step: 3 })
                      window.scrollTo({ top: 0, behavior: 'smooth' })
                    }}
                    label={intl.formatMessage({ id: 'global.actions.next' })}
                  ></Button>
                </div>
              )}
              {/* STEP 3: PROPERTY INFORMATION */}
              {step === 3 && (
                <WebPropertyForm
                  type={type}
                  rent={rent}
                  property={property}
                  onSubmit={values => {
                    this.setState({ property: values, step: 4 })
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }}
                  increasePercent={value => this.setState({ step3Percent: step3Percent + value })}
                  propertyStep={propertyStep}
                  isAdmin={isAdmin}
                ></WebPropertyForm>
              )}
              {/* STEP 4: PROPERTY DESCRIPTION */}
              {step === 4 && (
                <DescriptionForm
                  onSubmit={values => {
                    const { property } = this.state
                    property.description = values.description
                    property.title = values.title
                    this.setState({ property, step: 5 })
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }}
                />
              )}
              {/* STEP 5: IMAGES */}
              {step === 5 && (
                <div>
                  <UploadView
                    images={images}
                    onChange={images => this.setState({ images })}
                    title={intl.formatMessage({ id: 'admin.property.images' })}
                    id="image-upload"
                    isWeb={true}
                  >
                    <FormattedMessage id="admin.property.uploadImages"></FormattedMessage>
                  </UploadView>

                  <CatasterUploadView plans={plans} onChange={plans => this.setState({ plans })} isWeb={true} />

                  {isAdmin && (
                    <FormField label={<FormattedMessage id="admin.property.video" />} htmlFor="video" className="flex-grow-1">
                      <TextInput
                        type="text"
                        name="video"
                        onChange={e => this.setState({ video: e.target.value })}
                        value={this.state.video}
                      ></TextInput>
                    </FormField>
                  )}

                  <Button
                    style={{ marginLeft: 'auto', marginTop: '16px', display: `flex` }}
                    primary
                    onClick={this.onPropertySubmit}
                    disabled={isSubmitDisabled}
                    label={intl.formatMessage({ id: isEdit ? 'global.actions.save' : 'global.actions.submit' })}
                  ></Button>
                </div>
              )}
            </Col>
          )}
        </Row>
      </Container>
    )
  }
}

SellCreate.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(injectIntl(withRouter(SellCreate)))
