import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import { Heading, Text } from 'grommet'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

class AboutUs extends Component {
  componentDidMount() {
    if (this.props.intl.locale === 'en') {
      this.props.history.push('/')
    }
  }

  render() {
    const { intl } = this.props
    return (
      <Container className="main-container w-100 pb-4" direction="column">
        <Helmet>
          <title>
            {intl.formatMessage({ id: 'website.aboutus.title' })} - {intl.formatMessage({ id: 'website.pageTitle' })}
          </title>
          <meta name="description" content={intl.formatMessage({ id: 'website.aboutus.text1' })}></meta>
        </Helmet>
        <Heading level={1} className="pt-2 pb-2 text-center">
          <FormattedMessage id="website.aboutus.title" />
        </Heading>
        <Text>
          <FormattedHTMLMessage id="website.aboutus.text1" />
        </Text>
        <Heading level={3} className="pt-5 pb-2">
          <FormattedMessage id="website.aboutus.whois" />
        </Heading>
        <Text>
          <FormattedHTMLMessage id="website.aboutus.text2" />
        </Text>

        <Heading level={3} className="pt-5 pb-2">
          <FormattedMessage id="website.vision.title" />
        </Heading>
        <Text>
          <FormattedHTMLMessage id="website.vision.text1" />
        </Text>

        <Heading level={3} className="pt-5 pb-2">
          <FormattedMessage id="website.mission.title" />
        </Heading>
        <Heading level={4} className="pt-4">
          <FormattedMessage id="website.mission.title1" />
        </Heading>
        <Heading level={4} className="pt-4">
          <FormattedMessage id="website.mission.title2" />
        </Heading>
        <Text>
          <FormattedHTMLMessage id="website.mission.text1" />
        </Text>
        <Heading level={4} className="pt-4">
          <FormattedMessage id="website.mission.title3" />
        </Heading>
        <Text>
          <FormattedHTMLMessage id="website.mission.text2" />
        </Text>
        <Heading level={4} className="pt-4">
          <FormattedMessage id="website.mission.title4" />
        </Heading>
        <Text>
          <FormattedHTMLMessage id="website.mission.text3" />
        </Text>
        <Heading level={4} className="pt-4">
          <FormattedMessage id="website.mission.title5" />
        </Heading>
        <Text>
          <FormattedHTMLMessage id="website.mission.text4" />
        </Text>
      </Container>
    )
  }
}

export default injectIntl(withRouter(AboutUs))
