import { SET_LANGUAGE } from 'store/actions/types'

const initialState = {
	locale: 'it',
	languages: ['de', 'it', 'en']
}

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_LANGUAGE:
			localStorage.setItem('language', action.payload)
			return {
				...state,
				locale: action.payload,
			}
		default:
			return state
	}
}