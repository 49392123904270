import { SET_WEBSITE_USER } from 'store/actions/types'
import isEmpty from 'helpers/isEmpty'

const initialState = {
  isWebsiteAuth: false,
  websiteUser: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WEBSITE_USER:
      return {
        isWebsiteAuth: !isEmpty(action.payload),
        websiteUser: action.payload
      }
    default:
      return state
  }
}
