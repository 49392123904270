import React, { Component } from 'react'
import { RadioButton, Box, FormField, Select, Heading } from 'grommet'
import { injectIntl, FormattedMessage } from 'react-intl'
import { ReactSVG } from 'react-svg'
import { Row } from 'react-bootstrap'
import FormLabel from './FormLabel'
import TextInput from './TextInput'
import FormattedInput from './FormattedInput'

/**
 * this component is used to enter all informations that regard only the hotel type such as stars, spa etc.
 *
 * @class HotelSelector
 */
class HotelSelector extends Component {
  constructor(props) {
    super(props)

    this.state = Object.assign(
      {
        bar: props.values.barPlaces > 0,
        restaurant: props.values.restaurantPlaces > 0,
        spaR: props.values.spa > 0,
        conferenceRoomsR: props.values.conferenceRooms > 0
      },
      props.values
    )
  }

  onChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
    this.props.onChange(this.state)
  }

  onFocus = e => {
    const { name, value } = e.target

    if (value === '0') {
      this.setState({ [name]: '' })
    }
  }

  onBlur = e => {
    const { name, value } = e.target

    if (value === '') {
      this.setState({ [name]: 0 })
    }
  }

  onChangeRadio(field, valueField, value) {
    this.setState({ [field]: value, [valueField]: 0 })
    this.props.onChange(this.state)
  }

  onSimpleChange = (field, value) => {
    this.setState({ [field]: value })
    this.props.onChange(this.state)
  }

  render() {
    const { intl } = this.props
    const values = this.state

    return (
      <Row className="ui-room-number-selector d-flex flex-column">
        <Heading level={4} className="pt-3">
          <FormattedMessage id="admin.property.hotel" />
        </Heading>

        <FormField label={<FormLabel label="admin.property.hotel.stars" icon=""></FormLabel>} htmlFor="stars">
          <Select
            id="stars"
            size="small"
            name="stars"
            valueLabel={<Box pad="small">{intl.formatMessage({ id: `admin.property.hotel.stars.${values.stars}` })}</Box>}
            options={['1', '2', '3', '4', '4.5', '5', '5.5']}
            onChange={({ option }) => this.onSimpleChange('stars', option)}
          >
            {option => <Box pad="small">{intl.formatMessage({ id: `admin.property.hotel.stars.${option}` })}</Box>}
          </Select>
        </FormField>

        <FormField label={<FormLabel label="admin.property.hotel.sleepingPlaces" icon="" />} htmlFor="sleepingPlaces" className="mw-form-field">
          <Box align="center" alignContent="center" direction="row">
            <ReactSVG src="/website/rooms/bed.svg" className="mr-2" />
            <TextInput
              type="number"
              name="sleepingPlaces"
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              value={values.sleepingPlaces}
            ></TextInput>
          </Box>
        </FormField>

        <FormField label={<FormLabel label="admin.property.hotel.barPlaces" icon="" />} htmlFor="hotel.barPlaces" className="mw-form-field">
          <Box align="center" alignContent="center" direction="row">
            <Box pad="small" direction="column" flex="grow">
              <RadioButton
                label={intl.formatMessage({ id: 'admin.property.true' })}
                name="bar1"
                value={true}
                checked={this.state.bar === true}
                onChange={() => this.onChangeRadio('bar', 'barPlaces', true)}
              />
              <div className="mb-1"></div>
              <RadioButton
                label={intl.formatMessage({ id: 'admin.property.false' })}
                name="bar2"
                value={false}
                checked={this.state.bar === false}
                onChange={() => this.onChangeRadio('bar', 'barPlaces', false)}
              />
            </Box>
            <ReactSVG src="/website/rooms/terrace.svg" className="mr-2" />
            <TextInput
              disabled={!values.bar}
              type="number"
              name="barPlaces"
              onChange={this.onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              value={values.barPlaces}
            ></TextInput>
          </Box>
        </FormField>

        <FormField
          label={<FormLabel label="admin.property.hotel.restaurantPlaces" icon="" />}
          htmlFor="hotel.restaurantPlaces"
          className="mw-form-field"
        >
          <Box align="center" alignContent="center" direction="row">
            <Box pad="small" direction="column" flex="grow">
              <RadioButton
                label={intl.formatMessage({ id: 'admin.property.true' })}
                name="restaurant1"
                value={true}
                checked={this.state.restaurant === true}
                onChange={() => this.onChangeRadio('restaurant', 'restaurantPlaces', true)}
              />
              <div className="mb-1"></div>
              <RadioButton
                label={intl.formatMessage({ id: 'admin.property.false' })}
                name="restaurant2"
                value={false}
                checked={this.state.restaurant === false}
                onChange={() => this.onChangeRadio('restaurant', 'restaurantPlaces', false)}
              />
            </Box>
            <ReactSVG src="/website/rooms/dining.svg" className="mr-2" />
            <TextInput
              type="number"
              name="restaurantPlaces"
              disabled={!values.restaurant}
              onChange={this.onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              value={values.restaurantPlaces}
            ></TextInput>
          </Box>
        </FormField>

        <FormField label={<FormLabel label="admin.property.hotel.spa" />} htmlFor="spa" className="mt-3 mw-form-field">
          <Box align="center" alignContent="center" direction="row">
            <Box pad="small" direction="column">
              <RadioButton
                label={intl.formatMessage({ id: 'admin.property.true' })}
                name="spa1"
                value={true}
                checked={this.state.spaR === true}
                onChange={() => this.onChangeRadio('spaR', 'spa', true)}
              />
              <div className="mb-1"></div>
              <RadioButton
                label={intl.formatMessage({ id: 'admin.property.false' })}
                name="spa2"
                value={false}
                checked={this.state.spaR === false}
                onChange={() => this.onChangeRadio('spaR', 'spa', false)}
              />
            </Box>
            <ReactSVG src="/website/rooms/hobby.svg" className="mr-2" />
            <FormattedInput
              suffix={'m²'}
              type="number"
              name="spa"
              onChange={value => this.onSimpleChange('spa', value)}
              disabled={!values.spaR}
              value={values.spa}
            ></FormattedInput>
          </Box>
        </FormField>

        <FormField label={<FormLabel label="admin.property.hotel.conferenceRooms" />} htmlFor="conferenceRooms" className="mt-3 mw-form-field">
          <Box align="center" alignContent="center" direction="row">
            <Box pad="small" direction="column">
              <RadioButton
                label={intl.formatMessage({ id: 'admin.property.true' })}
                name="conferenceRooms1"
                value={true}
                checked={this.state.conferenceRoomsR === true}
                onChange={() => this.onChangeRadio('conferenceRoomsR', 'conferenceRooms', true)}
              />
              <div className="mb-1"></div>
              <RadioButton
                label={intl.formatMessage({ id: 'admin.property.false' })}
                name="conferenceRooms2"
                value={false}
                checked={this.state.conferenceRoomsR === false}
                onChange={() => this.onChangeRadio('conferenceRoomsR', 'conferenceRooms', false)}
              />
            </Box>
            <ReactSVG src="/website/rooms/conference.svg" className="mr-2" />

            <FormattedInput
              type="number"
              name="conferenceRooms"
              suffix={'m²'}
              disabled={!values.conferenceRoomsR}
              onChange={value => this.onSimpleChange('conferenceRooms', value)}
              value={values.conferenceRooms}
            ></FormattedInput>
          </Box>
        </FormField>
      </Row>
    )
  }
}

export default injectIntl(HotelSelector)
