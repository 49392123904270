import React, { Component } from 'react'
import { Image } from 'grommet'

const _loaded = {}

/**
 * loads images asynchronously displaying a placeholder
 *
 * @class ImageLoader
 */
class ImageLoader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: _loaded[this.props.src]
    }
  }

  static defaultProps = {
    className: '',
    fit: 'cover',
    loadingClassName: 'ui-img-loading',
    loadedClassName: 'ui-img-loaded',
    src: '',
    webP: ''
  }

  //image onLoad handler to update state to loaded
  onLoad = () => {
    _loaded[this.props.src] = true
    this.setState(() => ({ loaded: true }))
  }

  render() {
    const { className, loadedClassName, loadingClassName, alt, src, webP, fit, height, width } = this.props

    return (
      <div className={this.state.loaded ? loadedClassName : loadingClassName} style={{ height: height || 'auto' }}>
        <picture>
          {webP && <source srcSet={webP} type="image/webp" />}
          <source srcSet={src} type="image/jpeg" />
          <Image
            fit={fit}
            src={src}
            alt={alt || 'Image'}
            height={height || 'auto'}
            width={width || 'auto'}
            className={className}
            onLoad={this.onLoad}
            onClick={this.props.onClick}
          />
          <span></span>
        </picture>
      </div>
    )
  }
}

export default ImageLoader
