import React, { Component } from 'react'
import { Map, TileLayer, GeoJSON, Marker, AttributionControl, CircleMarker } from 'react-leaflet'
import L from 'leaflet'

class LeafletMap extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lat: 46.75,
      lng: 11.4,
      zoom: 9
    }
  }

  render() {
    const { markerPosition, exactPosition, maxZoom, style } = this.props
    let center = markerPosition ? markerPosition : [this.state.lat, this.state.lng]
    let zoom = markerPosition ? 17 : this.state.zoom

    const { locations } = this.props
    let rectangles = []

    if (locations && locations.length > 0) {
      const polyglonBounds = L.polygon(locations[locations.length - 1].coordinates)
        .getBounds()
        .getCenter()
      center = [polyglonBounds.lng, polyglonBounds.lat]
      zoom = 11
      rectangles = this.props.locations.map(loc => {
        return <GeoJSON key={Math.random()} data={loc}></GeoJSON>
      })
    }

    return (
      <Map center={center} zoom={zoom} style={{ borderRadius: '8px', ...style }} attributionControl={false} maxZoom={maxZoom}>
        <AttributionControl position="bottomright" prefix="Profi Immobilien" />
        <TileLayer attribution="&copy; OpenStreetMap" url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
        {this.props.locations && this.props.locations.length > 0 && rectangles}
        {markerPosition &&
          (exactPosition ? (
            <Marker
              position={markerPosition}
              icon={L.icon({
                iconUrl: '/website/house.svg',
                iconSize: [24, 24]
              })}
            ></Marker>
          ) : (
            <CircleMarker center={markerPosition} radius={70} color="#ffc107" />
          ))}
      </Map>
    )
  }
}

export default LeafletMap
