import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'

import { Heading, Text } from 'grommet'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { authFetch } from '../../utils/website-utils'

const TestimonyCard = props => {
  return (
    <div className="d-flex flex-column pt-4 pb-4">
      <Heading level={4}>{props.name}</Heading>
      <Text>"{props.text}"</Text>
    </div>
  )
}

class Testimonies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      testimonies: []
    }
  }
  async componentDidMount() {
    if (this.props.intl.locale === 'en') {
      this.props.history.push('/')
    }

    const response = await authFetch('get', '/api/v1/testimonies')
    const testimonies = response.data
    this.setState({ testimonies })
  }

  render() {
    const { testimonies } = this.state
    const { locale } = this.props.intl
    const { intl } = this.props

    const filteredTestimonies = (testimonies || []).filter(
      testimony => testimony.name && testimony.name[intl.locale] && testimony.text && testimony.text[intl.locale]
    )
    return (
      <Container className="main-container w-100 pb-4" direction="column">
        <Helmet>
          <title>
            {intl.formatMessage({ id: 'website.testimonies.title' })} - {intl.formatMessage({ id: 'website.pageTitle' })}
          </title>
          <meta name="description" content={intl.formatMessage({ id: 'website.testimonies.title' })}></meta>
        </Helmet>
        <Heading level={1} className="pt-2 pb-2 text-center">
          <FormattedMessage id="website.testimonies.title" />
        </Heading>
        {filteredTestimonies.map(testimony => (
          <TestimonyCard name={testimony.name[locale]} key={testimony.name[locale]} text={testimony.text[locale]} />
        ))}

        <Text weight="bold" className="pt-4">
          <FormattedMessage id="website.testimonies.text" />
        </Text>
      </Container>
    )
  }
}

export default injectIntl(withRouter(Testimonies))
