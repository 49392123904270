import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Container, Card } from 'react-bootstrap'
import { Text, Heading, Button } from 'grommet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons'

import Spinner from 'components/Spinner'
import SearchPropertyCard from 'components/SearchPropertyCard'
import PropertyDetailDialog from 'components/PropertyDetailDialog'
import Toast from 'components/Toast'
import PropertyCard from 'components/PropertyCard'
import { timeout } from 'utils/utils'
import { logError } from 'utils/sentry-log'
import { sortMatches } from 'utils/match-utils'
import { authFetch } from 'utils/website-utils'

class Find extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.match.params['id'],
      isLoading: false,
      activeMatch: null,
      sortedMatches: null,
      reccomendations: null
    }

    this.onMatchConfirm = this.onMatchConfirm.bind(this)
  }

  async componentDidMount() {
    const { id } = this.state

    this.setState({ isLoading: true })
    try {
      const fetches = []
      fetches.push(authFetch('get', `/api/v1/searches/${id}?populate=[{"path":"type"}]`))
      fetches.push(authFetch('get', `/api/v1/searches/${id}/matches`))

      const response = await Promise.all(fetches)
      if (!response[0] || !response[1] || !response[0].data || !response[1].data) throw new Error()

      const matches = response[1].data
      const yesterday = new Date().getTime() - 24 * 3600 * 1000
      const newMatches = matches.filter((m) => new Date(m.createdAt).getTime() >= yesterday)
      const oldMatches = matches.filter((m) => new Date(m.createdAt).getTime() < yesterday)

      console.log(matches[0].createdAt, new Date(matches[0].createdAt).getTime(), yesterday)

      this.setState({ search: response[0].data, newMatches, oldMatches })

      if (response[1].data.length === 0) {
        const reccomendationResponse = await authFetch('get', `/api/v1/searches/${id}/reccomend`)
        if (!reccomendationResponse || !reccomendationResponse.data || reccomendationResponse.data.length < 1) return

        this.setState({ reccomendations: reccomendationResponse.data })
      }
    } catch (e) {
      logError(e)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  onMatchClick = (match) => {
    this.setState({ activeMatch: match })
  }

  async onMatchConfirm(type, match) {
    try {
      const response = await authFetch('patch', `/api/v1/matches/${match._id}`, { state: type })
      if (!response || !response.data) throw new Error()

      const newMatch = response.data
      const newMatches = this.state.newMatches.map((match) => {
        if (match._id === newMatch._id) {
          return Object.assign(match, { state: newMatch.state })
        }
        return match
      })
      const oldMatches = this.state.oldMatches.map((match) => {
        if (match._id === newMatch._id) {
          return Object.assign(match, { state: newMatch.state })
        }
        return match
      })

      await this.setState({ newMatches, oldMatches, activeMatch: null })
      if (type === 'visit' || type === 'consulting') {
        this.setState({ showToast: true })
        await timeout(2500)
        this.setState({ showToast: false })
      }
    } catch (e) {
      console.log(e)

      this.setState({ activeMatch: null })
    }
  }

  render() {
    const { isLoading, search, newMatches, oldMatches, activeMatch, showToast, reccomendations, showAll } = this.state
    const { intl } = this.props

    const sortedMatches = sortMatches(newMatches)

    const sortedOldMatches = sortMatches(oldMatches)

    return (
      <Container className="main-container sell pb-5 d-flex flex-column align-items-center">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {search && (
              <Card className="w-100 mb-3" key={search._id}>
                <Card.Body className="d-flex justify-content-between flex-column flex-md-row">
                  <div className="d-flex flex-column">
                    <Text size="large" weight="bold">
                      {search.type.name[intl.locale]}
                    </Text>
                    {search.locations.map((location) => (
                      <Text key={location.name} size="medium" className="text-dark pt-2" level={4}>
                        {location.name}
                      </Text>
                    ))}
                  </div>
                  <div className="d-flex flex-column">
                    <Text size="large" weight="bold">
                      {intl.formatNumber(search.budget, { style: 'currency', currency: 'EUR' })}
                    </Text>
                    {search.rooms && search.rooms.bed > 0 && (
                      <Text size="medium" className="text-dark pt-2">
                        <FormattedMessage id="admin.property.rooms.room" /> {search.rooms.bed}
                      </Text>
                    )}
                  </div>
                </Card.Body>
              </Card>
            )}
            {sortedMatches && (
              <>
                {sortedMatches.map((match) => (
                  <SearchPropertyCard
                    key={match._id}
                    state={match.state}
                    property={match.property}
                    language={this.props.intl.locale}
                    onClick={() => this.onMatchClick(match)}
                  ></SearchPropertyCard>
                ))}
              </>
            )}

            {sortedOldMatches?.length > 0 && sortedMatches.length > 0 && !showAll && (
              <div className="d-flex w-100 justify-content-center pt-3 pb-3">
                <Button onClick={() => this.setState({ showAll: true })} primary label={intl.formatMessage({ id: 'website.find.showMore' })}></Button>
              </div>
            )}

            {sortedOldMatches && (showAll || sortedMatches.length == 0) && (
              <>
                {sortedOldMatches.map((match) => (
                  <SearchPropertyCard
                    key={match._id}
                    state={match.state}
                    property={match.property}
                    language={this.props.intl.locale}
                    onClick={() => this.onMatchClick(match)}
                  ></SearchPropertyCard>
                ))}
              </>
            )}

            {sortedMatches && sortedOldMatches && sortedMatches.length + sortedOldMatches.length === 0 && (
              <div className="d-flex flex-column justify-content-center align-items-center p-4 w-100">
                <FontAwesomeIcon icon={faHeartBroken} style={{ color: '#aaa' }} size="5x"></FontAwesomeIcon>
                <Text textAlign="center" className=" mt-2" color="#aaa">
                  <FormattedHTMLMessage id="website.find.matches.empty" />
                </Text>
                {reccomendations && (
                  <div className="mt-5 pt-5 d-flex flex-column w-100">
                    <Heading level="3" className="mt-5 pt-5 mb-3">
                      <FormattedMessage id="website.find.matches.reccomendations" />
                    </Heading>
                    {reccomendations.map((property) => (
                      <PropertyCard key={property._id} property={property} language={intl.locale} isSearch={true}></PropertyCard>
                    ))}

                    <div className="d-flex w-100 justify-content-center pt-3 pb-3">
                      <Button
                        onClick={() => this.props.history.push('/search')}
                        primary
                        label={intl.formatMessage({ id: 'website.find.actionSearch' })}
                      ></Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <PropertyDetailDialog match={activeMatch} onConfirm={this.onMatchConfirm} onClose={() => this.setState({ activeMatch: null })} />
        {showToast && <Toast isWebsite isSuccess title={<FormattedMessage id="website.find.matches.toast" />} />}
      </Container>
    )
  }
}

Find.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps)(injectIntl(withRouter(Find)))
