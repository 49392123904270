import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { Container, Card, Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Button, Text, Heading } from 'grommet'
import { ReactSVG } from 'react-svg'

import Spinner from 'components/Spinner'

const HomePage = lazy(() => import('components/HomePage'))

class Home extends Component {
  render() {
    const intl = this.props.intl

    return (
      <div className="home " style={{ overflow: 'hidden' }}>
        <div className="home__head-container bg-light d-flex flex-column position-relative pt-5  justify-content-center align-items-center">
          <div className="overlay"></div>
          <div className="d-flex flex-column align-items-center mt-5 pl-2 pr-2">
            <Heading className="text-center">
              <div className="pb-1 pt-2 text-uppercase text-dark text-center">
                <Text size="xxxxlarge" color="#ffc107" weight="bold">
                  PROFI{' '}
                </Text>
                <Text size="xxxlarge" color="#ffc107" weight="normal">
                  <FormattedMessage id="website.title2" />
                </Text>
              </div>
            </Heading>
            {intl.locale !== 'en' && (
              <Text className="text-center">
                <FormattedMessage id="website.slogan" />
              </Text>
            )}
          </div>
          <Container className="d-flex flex-column justify-content-end align-items-center pt-4 pl-0 pr-0">
            <Row>
              <Col xs={12} md={6}>
                <Card className="container">
                  <Card.Body>
                    <Container className="d-flex flex-column align-items-center justify-content-center">
                      <ReactSVG
                        src="/website/search.svg"
                        beforeInjection={svg => svg.setAttribute('style', 'width: 200px; height: 200px; padding-bottom: 8px;')}
                      />
                      <Text textAlign="center" className="pb-4">
                        <FormattedMessage id="website.home.find" />
                      </Text>
                      <Button
                        alignSelf="center"
                        className="ml-3"
                        primary
                        hoverIndicator
                        label={intl.formatMessage({ id: 'website.login.buy' })}
                        onClick={() => this.props.history.push('/find')}
                      />
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card className="container">
                  <Card.Body>
                    <Container className="d-flex flex-column align-items-center justify-content-center">
                      <ReactSVG
                        src="/website/sale.svg"
                        beforeInjection={svg => svg.setAttribute('style', 'width: 200px; height: 200px; padding-bottom: 8px;')}
                      />
                      <Text textAlign="center" className="pb-4">
                        <FormattedMessage id="website.home.yourListing" />
                      </Text>
                      <Button
                        alignSelf="center"
                        className="ml-3"
                        primary
                        hoverIndicator
                        label={intl.formatMessage({ id: 'website.login.sell' })}
                        onClick={() => this.props.history.push('/sell')}
                      />
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Suspense fallback={<Spinner isSuspense={true} />}>
          <HomePage />
        </Suspense>
      </div>
    )
  }
}

Home.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(injectIntl(withRouter(Home)))
