import React, { Component } from 'react'
import { Button, Heading, Box, Form, FormField, Select, CheckBox, Text } from 'grommet'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal, Spinner } from 'react-bootstrap'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { loginWebUser } from 'store/actions/authentication-web'

import { validateEmail } from 'utils/validations'
import TextInput from './TextInput'
import Axios from 'axios'
import { logError } from 'utils/sentry-log'

/**
 *
 * @class NewClientDialog
 */
class NewClientDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      duplicateEmail: false
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(values) {
    const { user, onSave, history, loginWebUser } = this.props
    this.setState({ isLoading: true })

    // return if privacy was not accepted
    if (!values.privacy) {
      return
    } else {
      delete values.privacy
    }

    try {
      const response = await Axios.post('/api/v1/clients-create', { me: user, client: values })
      if (!response) throw new Error('[NEW-SEARCH] client create error')

      if (!response.data && response.response && response.response.status === 400) {
        this.setState({ duplicateEmail: true })
        return
      }
      if (response.data.token) {
        await loginWebUser(response.data.token, history)
      }

      return onSave(response.data.client)
    } catch (e) {
      logError(e)
      this.setState({ isLoading: false })
    }
  }

  onValidate = values => {
    const { intl } = this.props
    let errors = {}
    if (!validateEmail(values.email)) errors.email = intl.formatMessage({ id: 'global.errors.email' })

    if (!values.name) errors.name = intl.formatMessage({ id: 'global.errors.required' })
    if (!values.familyName) errors.familyName = intl.formatMessage({ id: 'global.errors.required' })
    if (!values.privacy) errors.privacy = 'errore'

    return errors
  }

  render() {
    const { intl, user, show } = this.props
    const { isLoading, duplicateEmail } = this.state
    const salutationOptions = [
      { name: intl.formatMessage({ id: 'global.properties.salutation.m' }), value: 'Signor' },
      { name: intl.formatMessage({ id: 'global.properties.salutation.f' }), value: 'Signora' }
    ]
    const languageOptions = [
      { name: intl.formatMessage({ id: 'global.properties.language.it' }), value: 'it' },
      { name: intl.formatMessage({ id: 'global.properties.language.de' }), value: 'de' },
      { name: intl.formatMessage({ id: 'global.properties.language.en' }), value: 'en' }
    ]
    const emailError = duplicateEmail && intl.formatMessage({ id: 'global.errors.duplicateEmail' })

    return (
      <Formik
        initialValues={{
          email: user ? user.email : '',
          name: '',
          familyName: '',
          salutation: 'Signor',
          tel: '',
          language: intl.locale,
          privacy: false
        }}
        validate={this.onValidate}
        onSubmit={this.onSubmit}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <Modal show={show} onHide={() => {}} size="lg" aria-labelledby="contained-modal-title-vcenter" className="ui-select-seller-dialog" centered>
            <Modal.Header>
              <Heading level={3}>{intl.formatMessage({ id: 'website.sell.new.step0.title' })}</Heading>
            </Modal.Header>
            <Modal.Body className="pl-4 pr-4" style={{ maxHeight: '70vh', overflow: 'auto' }}>
              <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                <FormField label="Email *" htmlFor="email" error={emailError || errors.email}>
                  <TextInput type="email" readOnly={user && user.email} name="email" onChange={handleChange} value={values.email}></TextInput>
                </FormField>

                <FormField label={intl.formatMessage({ id: 'global.properties.salutation' })} htmlFor="salutation">
                  <Select
                    id="salutation"
                    size="small"
                    name="salutation"
                    valueLabel={
                      <Box pad="small">
                        {intl.formatMessage({ id: `global.properties.salutation.${values.salutation === 'Signor' ? 'm' : 'f'}` })}
                      </Box>
                    }
                    options={salutationOptions}
                    children={option => <Box pad="small">{option.name}</Box>}
                    onChange={({ option }) => setFieldValue('salutation', option.value)}
                  />
                </FormField>
                <FormField label={intl.formatMessage({ id: 'global.properties.name' }) + ' *'} htmlFor="name" error={errors.name}>
                  <TextInput type="text" name="name" onChange={handleChange} value={values.name}></TextInput>
                </FormField>
                <FormField label={intl.formatMessage({ id: 'global.properties.familyName' }) + ' *'} htmlFor="familyName" error={errors.familyName}>
                  <TextInput type="text" name="familyName" onChange={handleChange} value={values.familyName}></TextInput>
                </FormField>
                <FormField label={intl.formatMessage({ id: 'global.properties.tel' })} htmlFor="tel">
                  <TextInput type="number" name="tel" onChange={handleChange} value={values.tel}></TextInput>
                </FormField>
                <FormField label={intl.formatMessage({ id: 'global.properties.language' })} htmlFor="language">
                  <Select
                    id="language"
                    size="small"
                    name="language"
                    valueLabel={<Box pad="small">{intl.formatMessage({ id: `global.properties.language.${values.language}` })}</Box>}
                    options={languageOptions}
                    children={option => <Box pad="small">{option.name}</Box>}
                    onChange={({ option }) => setFieldValue('language', option.value)}
                  />
                </FormField>
              </Form>
            </Modal.Body>
            <Modal.Footer className="p-2">
              <div>
                <CheckBox
                  label={<FormattedMessage id="website.find.privacy" />}
                  name="privacy"
                  onChange={e => setFieldValue('privacy', !values.privacy)}
                  checked={values.privacy}
                />
                <Link to="/privacy" target="blank" className="m-0">
                  <Text size="sm" color="#ffc107">
                    Privacy
                  </Text>
                </Link>
              </div>

              <Button
                style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                primary
                onClick={handleSubmit}
                label={isLoading ? <Spinner animation="border" variant="dark" size="sm" /> : intl.formatMessage({ id: 'global.actions.save' })}
              ></Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    )
  }
}

NewClientDialog.propTypes = {
  loginWebUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { loginWebUser })(injectIntl(withRouter(NewClientDialog)))
