import React, { Component } from 'react'
import { RadioButton, Text } from 'grommet'
import { energyClassColor } from 'utils/utils'
import { injectIntl } from 'react-intl'
import { energyClassValues } from 'utils/utils'

/**
 * Component to select energy class
 *
 * @class EnergyClassSelector
 */
class EnergyClassSelector extends Component {
  constructor(props) {
    super(props)

    this.state = { value: props.value }
  }

  onChange(value) {
    this.setState({ value: value })
    this.props.onChange(value)
  }

  render() {
    const { value } = this.state
    const { intl, isReadOnly } = this.props
    const VALUES = energyClassValues(isReadOnly, intl)

    if (!isReadOnly || value === 'undefined') {
      VALUES.push({
        value: 'undefined',
        width: isReadOnly ? '82%' : '56%',
        description: ' ',
        consumption: ''
      })
    }
    const radioButtons = VALUES.map(radio =>
      isReadOnly ? (
        <div key={radio.value} className="ui-energy-selector__line d-flex  align-items-center mb-1 w-100">
          <div
            className="mr-2"
            style={{
              width: '16px',
              height: '16px',
              borderRadius: '100%',
              border: '1px solid grey',
              background: value === radio.value ? 'grey' : 'transparent'
            }}
          ></div>
          <div
            className="ui-energy-selector__line-color p-1 d-flex justify-content-between"
            style={{ width: radio.width, backgroundColor: energyClassColor(radio.value) }}
          >
            <Text size="xsmall" color="white">
              {radio.description}
            </Text>
            <Text className="pr-1" weight="bold" size="small" color="white">
              {radio.value === 'gold' || radio.value === 'undefined'
                ? intl.formatMessage({ id: `admin.property.energyClass.${radio.value}` })
                : radio.value}
            </Text>
          </div>
        </div>
      ) : (
        <RadioButton
          checked={value === radio.value}
          key={radio.value}
          name={radio.value}
          className="w-100"
          label={
            <div className="ui-energy-selector__line w-100 d-flex justify-content-between align-items-center mb-1 w-100">
              <div
                className="ui-energy-selector__line-color p-1 d-flex flex-column"
                style={{ width: radio.width, backgroundColor: energyClassColor(radio.value) }}
              >
                <Text size="xsmall" color="white">
                  {radio.description}
                </Text>
                <Text weight="bold" size="small" color="white">
                  {radio.value === 'gold' || radio.value === 'undefined'
                    ? intl.formatMessage({ id: `admin.property.energyClass.${radio.value}` })
                    : radio.value}
                </Text>
              </div>
              <Text>{radio.consumption}</Text>
            </div>
          }
          onChange={() => this.onChange(radio.value)}
        />
      )
    )

    return <div className="ui-energy-selector w-100">{radioButtons}</div>
  }
}

export default injectIntl(EnergyClassSelector)
