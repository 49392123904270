import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { Heading, Button, Text } from 'grommet'
import { ReactSVG } from 'react-svg'
import { FormattedMessage } from 'react-intl'

class ErrorRoute extends Component {
  render() {
    const { history } = this.props

    return (
      <div className="website software">
        <Row className="justify-content-center align-items-center bg-light vh-100 overflow-hidden w-100 m-0">
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <Heading textAlign="center">
              <FormattedMessage id="website.error.title" />
            </Heading>
            <ReactSVG
              src="/website/sale.svg"
              className="w-md-25 w-75 p-3"
              style={{ maxWidth: '250px' }}
              beforeInjection={svg => svg.setAttribute('style', 'width: 100%; height: 100%')}
            />
            <Text className="pt-2 pb-2">
              <FormattedMessage id="website.error.description" />
            </Text>
            <Button primary label="Home" onClick={() => history.push('/')} />
          </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(ErrorRoute)
