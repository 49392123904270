import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import ImageCarousel from './ImageCarousel'
import SlideShowModal from './SlideShowModal'
import styled from 'styled-components'
import { Image, Box } from 'grommet'

const Grid = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 50vh;
`

class CookieConsent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
      startIndex: 0
    }
  }

  render() {
    const { isModalOpen, startIndex } = this.state
    const images = [...(this.props.images || [])].splice(0, 5)

    return (
      <>
        <Grid
          className="ui-property-images bg-dark"
          style={{ gridTemplateColumns: `${images.lengh > 2 && '2fr'} repeat(${images.length < 5 ? '1' : '2'}, 1fr)` }}
        >
          {images.map((image, i) => (
            <Box className={`img${i + 1} img-container`} style={{ height: '100%' }} key={i}>
              <Image src={image} fit="cover" style={{ height: '100%' }} onClick={() => this.setState({ isModalOpen: true, startIndex: i })} />
            </Box>
          ))}
        </Grid>

        <ImageCarousel
          images={this.props.images}
          onClickImage={(i) => this.setState({ isModalOpen: true, startIndex: i })}
          className="d-sm-none d-flex"
        />
        <SlideShowModal images={this.props.images} isOpen={isModalOpen} start={startIndex} onClose={() => this.setState({ isModalOpen: false })} />
      </>
    )
  }
}

export default injectIntl(CookieConsent)
