import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Heading, Image, Text, DropButton, Box, TextInput } from 'grommet'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactSVG } from 'react-svg'
import { Helmet } from 'react-helmet'

import { authFetch } from 'utils/website-utils'
import LetterIcon from 'components/LetterIcon'
import RoomNumberSelector from 'components/RoomNumberSelector'
import AreaSelector from 'components/AreaSelector'
import PropertyImages from 'components/PropertyImages'
import CultivationSelector from 'components/CultivationSelector'
import EnergyClassSelector from 'components/EnergyClassSelector'
import OrientationSelector from 'components/OrientationSelector'
import PositionSelector from 'components/PositionSelector'
import UploadView from 'containers/hocs/UploadView'
import ContactCard from 'components/ContactCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faShare } from '@fortawesome/free-solid-svg-icons'

const YOUTUBE_EMBED_URL = `https://www.youtube.com/embed/`

class Property extends Component {
  constructor(props) {
    super(props)

    this.state = {
      property: null,
      carouselItems: [],
      rooms: null,
      areas: null,
      videoId: null
    }
  }

  async componentDidMount() {
    try {
      const { type, id } = this.props.match.params
      if (!type || !id) throw new Error()
      const property = await authFetch(
        'get',
        `/api/v1/properties/${type}/${id}?populate=[{"path":"type"},{"path":"location.zone"},{"path":"images"},{"path":"plans"}]`
      ).then((result) => result.data)

      const carouselItems = property.images.map((image) => <Image className="slide-wrapper" key={image.url} src={image.url} fit="cover" />)
      if (property && property.type.type === 'residential') {
        property['numOfRooms'] = property.rooms.bed + property.rooms.living + property.rooms.dining
      }
      const rooms = {}
      if (property && property.rooms) {
        for (let key of Object.keys(property.rooms)) {
          if (property.rooms[key] > 0) rooms[key] = property.rooms[key]
        }
      }
      const areas = {}
      if (property && property.areas) {
        for (let key of Object.keys(property.areas)) {
          if (property.areas[key].amount > 0) areas[key] = property.areas[key]
        }
      }

      let videoId
      if (property.video) {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = property.video.match(regExp)
        if (match && match[2].length === 11) {
          videoId = match[2]
        }
      }

      this.setState({ property, carouselItems, rooms, areas, videoId })
    } catch {
      this.props.history.push('/')
    }
  }

  render() {
    const { property, rooms, areas, videoId } = this.state
    const { intl } = this.props

    const pageTitle = property
      ? `${property.type.name[intl.locale]} - ${property.location.address.municipality.name[intl.locale === 'en' ? 'it' : intl.locale]}, ${
          property.location.zone.properties.name[intl.locale]
        }`
      : ''

    const videoUrl = videoId ? `${YOUTUBE_EMBED_URL}${videoId}` : null

    return (
      <div>
        <Helmet>
          <title>
            {pageTitle} - {intl.formatMessage({ id: 'website.pageTitle' })}
          </title>
          <meta
            name="description"
            content={property && property.description ? property.description[intl.locale] : intl.formatMessage({ id: 'website.description' })}
          />
          <meta property="og:title" content={property?.title ? property.title[intl.locale] : intl.formatMessage({ id: 'website.title' })} />
          <meta
            property="og:description"
            content={property?.description ? property.description[intl.locale] : intl.formatMessage({ id: 'website.description' })}
          />
          <meta property="og:image" content={property?.mainImage?.url || property?.images.length ? property.images[0].url : null} />
        </Helmet>
        <Container fluid className="property-container bg-light">
          {property && property.images && property.images.length > 0 && <PropertyImages images={property.images.map((img) => img.url)} />}
          {property && (
            <div>
              <Container className="pt-5 pb-3">
                {/* HEADER */}
                <div className="d-flex justify-content-between flex-wrap pb-3 mb-3" style={{ borderBottom: '1px solid #ddd' }}>
                  <div className="d-flex align-items-end mb-0 pt-2">
                    <Text size="xxlarge" weight="bold" className="d-flex text-primary">
                      {intl.formatNumber(property.price, { format: 'EUR' })}
                    </Text>
                    <Text size="medium" className="text-grey mt-auto">
                      {property.rent ? intl.formatMessage({ id: 'website.property.perMonth' }) : ''}
                    </Text>
                  </div>
                  <div className="d-flex justify-content-end pt-2 flex-wrap">
                    {property.numOfRooms && (
                      <div className="d-flex flex-column align-items-center  mr-4">
                        <ReactSVG
                          src="/website/room.svg"
                          beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          className="mr-1"
                        />
                        <Text size="xsmall" color="dark-6">
                          <FormattedMessage values={{ count: property.numOfRooms }} id="website.property.numOfRooms"></FormattedMessage>
                        </Text>
                      </div>
                    )}
                    {property.rooms && property.rooms.bath && (
                      <div className="d-flex flex-column align-items-center mr-4">
                        <ReactSVG
                          src="/website/bath.svg"
                          beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          className="mr-1"
                        />
                        <Text size="xsmall" color="dark-6">
                          <FormattedMessage values={{ count: property.rooms.bath }} id="website.property.numOfBaths"></FormattedMessage>
                        </Text>
                      </div>
                    )}
                    {property.area > 0 && (
                      <div className="d-flex flex-column align-items-center">
                        <ReactSVG
                          src="/website/area.svg"
                          beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          className="mr-1"
                        />
                        <Text size="xsmall" color="dark-6">
                          <FormattedMessage values={{ area: property.area }} id="format.area"></FormattedMessage>
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
                {/* TITLE */}
                <div className="d-flex w-100 mb-2 justify-content-between flex-wrap">
                  <div className="d-flex">
                    <Link
                      to={`/search?locationId=${property.location.zone._id}&locationType=zone&locationName=${
                        property.location.zone.properties.name[intl.locale]
                      }`}
                      className="pr-2"
                    >
                      {property.location.zone.properties.name[intl.locale]}
                    </Link>
                    <Link
                      to={`/search?locationId=${property.location.address.municipality.name[intl.locale]}&locationType=municipality`}
                      className="pl-2 pr-2"
                    >
                      {property.location.address.municipality.name[intl.locale]}
                    </Link>
                    <span className="pl-2">{property.type.name[intl.locale]}</span>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <DropButton
                      label={
                        <Text size="small">
                          <FontAwesomeIcon icon={faFilePdf} className="mr-2" /> Download Exposè
                        </Text>
                      }
                      className="expose-download-menu mb2"
                      dropAlign={{ top: 'bottom', left: 'left' }}
                      dropContent={
                        <Box background="light-2" pad="small" direction="column">
                          <a
                            className="pl-2 pr-2 pt-2 pb-3 text-dark"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`/api/v1/expose/pdf?type=${property.type.type}&id=${property._id}&lang=${intl.locale}`}
                          >
                            <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                            <FormattedMessage id="admin.property.expose.short" />
                          </a>
                          <a
                            className="p-2 text-dark"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`/api/v1/expose/pdf?type=${property.type.type}&id=${property._id}&lang=${intl.locale}&long=1`}
                          >
                            <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                            <FormattedMessage id="admin.property.expose.long" />
                          </a>
                        </Box>
                      }
                    />
                    <DropButton
                      label={
                        <Text size="small">
                          <FontAwesomeIcon icon={faShare} className="mr-2" /> Share
                        </Text>
                      }
                      className="expose-download-menu"
                      dropAlign={{ top: 'bottom', left: 'left' }}
                      dropContent={
                        <Box background="light-2" pad="small" direction="column">
                          <TextInput value={`https://www.profiimmobilien.com/${property.type.type}/${property._id}`} />
                        </Box>
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  {property.title && property.title[intl.locale] ? (
                    <Heading pad="medium" level="3">
                      {property.title[intl.locale]}
                    </Heading>
                  ) : (
                    <Heading level={2} pad="medium">
                      {property.type.name[intl.locale]}
                      {property.numOfRooms && (
                        <span>
                          , <FormattedMessage id="website.property.numOfRooms" values={{ count: property.numOfRooms }}></FormattedMessage>
                        </span>
                      )}
                    </Heading>
                  )}
                  <Text>Nr. {property.property_id}</Text>
                </div>

                <Text color="dark-2">
                  {/* DONT SHOW STREET HERE FOR NOW */}
                  {/* {property.location.address.street[intl.locale === 'en' ? 'it' : intl.locale]},{' '} */}
                  {property.location.address.municipality.name[intl.locale === 'en' ? 'it' : intl.locale]},{' '}
                  {property.location.zone.properties.name[intl.locale]}
                </Text>
                <Row>
                  <Col sm={12} md={8}>
                    {property.description && property.description[intl.locale] && (
                      <p className="pt-3">
                        <Text size="small">
                          <FormattedMessage id="admin.property.description" />
                        </Text>
                        <br></br>
                        <Text dangerouslySetInnerHTML={{ __html: property.description[intl.locale].split('\n').join('<br>') }}></Text>
                      </p>
                    )}
                  </Col>
                  <Col sm={12} md={4} className="d-flex justify-content-end">
                    <ContactCard property={property} />
                  </Col>
                </Row>

                {/* AMENITIES */}
                {property.type.type !== 'buildingland' && (
                  <div className="d-flex flex-column pt-3">
                    <Heading level={3}>
                      <FormattedMessage id="website.property.amenities"></FormattedMessage>
                    </Heading>
                    <div className="d-flex flex-wrap">
                      {property.garden && property.garden.type !== 'no' && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <ReactSVG
                            src="/website/garden.svg"
                            className="mr-1"
                            beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          />
                          <Text size="small" color="dark-2">
                            <FormattedMessage
                              id={property.garden.type === 'community' ? 'admin.property.garden.community' : 'admin.property.garden.private'}
                            ></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.parking.garage?.number > 0 && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <ReactSVG
                            src="/website/garage.svg"
                            className="mr-1"
                            beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id="admin.property.garage"></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.lift && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <LetterIcon
                            letter={
                              <ReactSVG
                                src="/website/elevator.svg"
                                beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                              />
                            }
                          />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id="admin.property.lift"></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.furnishing && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <ReactSVG src="/website/furniture.svg" className="mr-1" />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id={`admin.property.furnishing.${property.furnishing}`}></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.heating && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <ReactSVG
                            src="/website/thermometer.svg"
                            className="mr-1"
                            beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id={`admin.property.heating`}></FormattedMessage>:{' '}
                            <FormattedMessage id={`admin.property.heating.${property.heating}`}></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.power && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <ReactSVG
                            src="/website/power.svg"
                            className="mr-1"
                            beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id="admin.property.power"></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.runningWater && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <ReactSVG
                            src="/website/water.svg"
                            className="mr-1"
                            beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id="admin.property.runningWater"></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.light && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <ReactSVG
                            src="/website/light.svg"
                            className="mr-1"
                            beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id="admin.property.light"></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.fountains > 0 && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <LetterIcon letter={property.fountains} />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id="admin.property.fountains"></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.hailnets && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <ReactSVG src="/website/hail.svg" className="mr-1" />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id="admin.property.hailnets"></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.sprinklers && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center">
                          <ReactSVG src="/website/sprinkler.svg" className="mr-1" />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id="admin.property.sprinklers"></FormattedMessage>
                          </Text>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* PROPERTIES */}
                {property.type.type !== 'buildingland' && (
                  <div className="d-flex flex-column pt-5">
                    <Heading level={3}>
                      <FormattedMessage id="website.property.properties"></FormattedMessage>
                    </Heading>
                    <div className="d-flex flex-wrap">
                      {property.orientation && property.orientation.length > 0 && (
                        <div className="d-flex flex-row align-items-center justify-content-center flex-grow-1">
                          <OrientationSelector isReadOnly={true} selected={property.orientation} />
                        </div>
                      )}
                      {property.energyClass && (
                        <div
                          style={{ maxWidth: '300px' }}
                          className="d-flex flex-row pr-4 pt-2 align-items-center flex-grow-1 justify-content-center"
                        >
                          <EnergyClassSelector value={property.energyClass} isReadOnly={true} />
                        </div>
                      )}
                      {property.conventionated && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center justify-content-center">
                          <ReactSVG
                            src="/website/check.svg"
                            className="mr-1"
                            beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id="admin.property.conventionated"></FormattedMessage>
                          </Text>
                        </div>
                      )}
                      {property.condition && (
                        <div className="d-flex flex-row pr-4 pt-2 align-items-center justify-content-center">
                          <ReactSVG
                            src="/website/state.svg"
                            className="mr-1"
                            beforeInjection={(svg) => svg.setAttribute('style', 'width: 32px; height: 32px')}
                          />
                          <Text size="small" color="dark-2">
                            <FormattedMessage id={`admin.property.condition`}></FormattedMessage>:{' '}
                            <FormattedMessage id={`admin.property.condition.${property.condition}`}></FormattedMessage>
                          </Text>
                        </div>
                      )}
                    </div>
                    {Object.keys(rooms).length > 0 && (
                      <div className="d-flex flex-column pl-2">
                        <Heading level={4} className="pt-3">
                          <FormattedMessage id="admin.property.rooms.title"></FormattedMessage>
                        </Heading>
                        <div className="d-flex flex-wrap pt-2 ">
                          <RoomNumberSelector value={rooms} isReadOnly={true} />
                        </div>
                      </div>
                    )}
                    {Object.keys(areas).length > 0 && (
                      <div className="d-flex flex-column pl-2">
                        <Heading level={4} className="pt-3">
                          <FormattedMessage id="admin.property.areas"></FormattedMessage>
                        </Heading>
                        <div className="d-flex flex-wrap pt-2 ">
                          <AreaSelector value={areas} isReadOnly={true} />
                        </div>
                      </div>
                    )}
                    {property.cultivation && property.cultivation.length > 0 && (
                      <CultivationSelector value={property.cultivation} isReadOnly={true} />
                    )}
                  </div>
                )}
                {property.plans.length > 0 && (
                  <UploadView
                    images={property.plans}
                    isReadOnly={true}
                    title={<FormattedMessage id="admin.property.floorplans" />}
                    isWeb={true}
                    className="pt-5"
                  />
                )}
                {videoUrl && (
                  <div className="d-flex flex-column pt-5">
                    <Heading level={3}>Video</Heading>
                    <iframe title="video" height="400" style={{ border: 'none' }} src={videoUrl}></iframe>
                  </div>
                )}
                <div className="d-flex flex-column pt-5">
                  <Heading level={3}>
                    <FormattedMessage id="website.property.location"></FormattedMessage>
                  </Heading>
                  <PositionSelector location={property.location} isReadOnly={true} hideList={true} maxZoom={16} />
                </div>
              </Container>
            </div>
          )}
        </Container>
      </div>
    )
  }
}

export default injectIntl(withRouter(Property))
