import React, { Component } from 'react'
import Spinner from './Spinner'
import { authFetch } from 'utils/website-utils';
import { Text, TextInput, Button, Heading, Box } from 'grommet';
import { debounce } from 'utils/utils';
import { injectIntl } from 'react-intl';
import { Modal } from 'react-bootstrap';

class SelectSellerDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      sellers: [],
      searchTerm: '',
      show: false
    }

    this.debouncedSearch = debounce(this.onSearch, 500)
  }

  async componentDidMount() {
    this.onSearch()
  }

  async onSearch(searchTerm = null) {
    this.setState({ isLoading: true })
    let sellers
    if (searchTerm && searchTerm.length > 0) {
      sellers = await authFetch('get', `/api/v1/seller/search?q=${searchTerm}`).then(res => res.data)
    } else {
      sellers = await authFetch('get', '/api/v1/sellers?limit=10').then(res => res.data)
    }
    this.setState({ sellers })
    this.setState({ isLoading: false })
  }

  selectSeller(seller) {
    this.setState({ show: false })
    this.props.selectSeller(seller)
  }

  onChange = (event) => {
    event.persist()
    this.setState({ searchTerm: event.target.value })
    this.debouncedSearch(event.target.value)
  }

  render() {
    const { isLoading, sellers, searchTerm, show } = this.state
    const { intl } = this.props

    const sellerItems = sellers.map(seller =>
      <div
        className={`ui-seller-item d-flex flex-column p-3 mt-1 ${seller.isSelected ? 'selected' : ''}`}
        key={seller._id}
        onClick={() => this.selectSeller(seller)}
      >
        <Text weight="bold">{seller.name} {seller.familyName}</Text>
        <Text size="small" color="dark">{seller.email}</Text>
      </div>
    )

    return (
      <Box pad="small">
        <Button label={intl.formatMessage({ id: 'website.sell.new.step0.existingCustomer' })} onClick={() => this.setState({ show: true })} />
        <Modal
          show={show}
          onHide={() => this.setState({ show: false })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          className="ui-select-seller-dialog"
          centered
        >
          <Modal.Header closeButton>
            <Heading level={3}>{intl.formatMessage({ id: 'website.sell.new.step0.existingCustomer' })}</Heading>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column">
              <TextInput placeholder="Search" value={searchTerm} onChange={this.onChange} />
              {isLoading ?
                <Spinner isSuspense={true} height={300} />
                :
                <div className="ui-seller-list d-flex flex-column">
                  {sellerItems}
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>
      </Box>
    )
  }
}

export default injectIntl(SelectSellerDialog)