import React, { Component } from 'react'
import { Text } from 'grommet'
import { injectIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'
import { FormLabel, Row, Col } from 'react-bootstrap'
import FormattedInput from './FormattedInput'
import TextInput from './TextInput'

/**
 * This component is used to input the amount and area of given rooms or facilities of a property
 *
 * @class AreaSelector
 */
class AreaSelector extends Component {
  constructor(props) {
    super(props)
    this.state = props.value
  }

  onChange(value, name, type) {
    const val = this.state[name]
    val[type] = value
    this.setState({ [name]: val })
    this.props.onChange(this.state)
  }

  onFocus = (value, name, type) => {
    if (value === '0') {
      const val = this.state[name]
      val[type] = ''
      this.setState({ [name]: val })
    }
  }

  onBlur = (value, name, type) => {
    if (value === '') {
      this.onChange(0, name, type)
    }
    // reset area if amount is 0
    if ((this.state[name].amount === 0 || this.state[name].amount === '0') && type === 'amount') {
      this.onChange(0, name, 'area')
    }
  }

  render() {
    const { intl, isReadOnly } = this.props
    const values = Object.keys(this.state)

    const inputs = values.map(input =>
      isReadOnly ? (
        <Col key={input} className="d-flex flex-column align-items-start justify-content-center pt-2 pb-2" md={4} xs={6}>
          <FormLabel className="font-weight-bold">{intl.formatMessage({ id: `admin.property.areas.${input}` })}</FormLabel>
          <div className="d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <Text size="large" className="p-3">
                {this.state[input].amount}
              </Text>
            </div>
            <ReactSVG
              src={`/website/rooms/${input}.svg`}
              className="mr-2 ml-1"
              beforeInjection={svg => {
                svg.setAttribute('style', 'opacity: 0.8')
              }}
            />
          </div>
        </Col>
      ) : (
        <Col key={input} className="d-flex align-items-end justify-content-center pt-2 pb-2" md={isReadOnly ? 4 : 6} xs={isReadOnly ? 6 : 12}>
          <div>
            <FormLabel className="font-weight-bold">{intl.formatMessage({ id: `admin.property.areas.${input}` })}</FormLabel>
            <TextInput
              type="number"
              value={this.state[input].amount}
              onChange={event => this.onChange(event.target.value, input, 'amount')}
              onFocus={event => this.onFocus(event.target.value, input, 'amount')}
              onBlur={event => this.onBlur(event.target.value, input, 'amount')}
            ></TextInput>
          </div>
          <ReactSVG
            src={`/website/rooms/${input}.svg`}
            className="mr-2 ml-1"
            beforeInjection={svg => {
              svg.setAttribute('style', 'opacity: 0.8')
            }}
          />
          <div>
            <FormLabel>{intl.formatMessage({ id: `admin.property.areas.totalArea` })}</FormLabel>
            <div className="d-flex">
              <FormattedInput
                disabled={this.state[input].amount < 1}
                suffix={'m²'}
                type="number"
                onChange={value => this.onChange(value, input, 'area')}
                value={this.state[input].area}
              ></FormattedInput>
            </div>
          </div>
        </Col>
      )
    )

    return <Row className="ui-room-number-selector w-100">{inputs}</Row>
  }
}

export default injectIntl(AreaSelector)
