import Axios from 'axios'
import { css } from 'styled-components'
import store from 'store'

export const authFetch = (type, url, data = {}) => {
  const token = localStorage.jwtWebToken

  const state = store.getState()
  const headers = { Authorization: token, 'Accept-Language': (state.language && state.language.locale) || getClientLanguage() }
  return Axios({ method: type, url, data, headers })
}

const accentColors = ['#2AD2C9', '#614767', '#ff8d6d']
const neutralColors = ['#425563', '#5F7A76', '#80746E', '#767676']
const statusColors = {
  critical: '#F04953',
  error: '#F04953',
  warning: '#FFD144',
  ok: '#01a982',
  unknown: '#CCCCCC',
  disabled: '#CCCCCC'
}

const colors = {
  brand: '#ffc107',
  focus: ''
}

const colorArray = (array, prefix) =>
  array.forEach((color, index) => {
    colors[`${prefix}-${index + 1}`] = color
  })

colorArray(accentColors, 'accent')
colorArray(neutralColors, 'neutral')
Object.keys(statusColors).forEach(color => {
  colors[`status-${color}`] = statusColors[color]
})

export const theme = {
  global: {
    colors,
    control: {
      border: {
        radius: '8px'
      }
    },
    hover: {
      background: '#FFC60C',
      color: '#495057'
    },
    font: {
      family: "'Poppins', Arial, sans-serif",
      size: '14px',
      height: '20px',
      face: `
        @font-face {
          font-family: "Poppins";
          font-display: swap;
          src: url("https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJfecg.woff2") format('woff2');
        }
        @font-face {
          font-family: "Poppins";
          font-display: swap;
          src: url("https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJfecg.woff2") format('woff2');
          font-weight: 400;
        }
        @font-face {
          font-family: "Poppins";
          font-display: swap;
          src: url("https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2") format('woff2');
          font-weight: 700;
        }
      `
    },
    drop: {
      // display language drop above navbar
      zIndex: '3001'
    }
  },
  button: {
    width: '100%',
    border: {
      radius: '0'
    },
    color: '#212529',
    padding: {
      vertical: '8px'
    },
    primary: {
      color: '#ffc107'
    },
    hover: {
      color: '#ffc107'
    },
    colors: {
      accent: '#ff8d6d',
      secondary: 'rgba(51,51,51,0.6)'
    },
    extend: css`
      letter-spacing: 0.04167em;
      :hover {
        box-shadow: none;
        background-color: '#FFF';
      }
    `,
    size: {
      small: {
        border: {
          radius: '0'
        }
      }
    }
  },
  formField: {
    label: {
      weight: 600,
      size: 'small',
      margin: {
        bottom: '8px',
        left: '4px',
        top: '8px'
      }
    },
    border: false,
    margin: {
      bottom: '16px'
    },
    error: {
      margin: {
        top: '0',
        left: '4px'
      },
      size: 'small'
    }
  },
  select: {
    icons: {
      color: '#333'
    }
  }
}

export const getClientLanguage = () => {
  const storeState = store.getState()

  if (window && window.navigator && storeState.language) {
    const languages = storeState.language.languages
    if (window.navigator.language) {
      const lang = window.navigator.language.slice(0, 2)
      if (languages.includes(lang)) return lang
    } else if (window.navigator.languages) {
      for (let locale in window.navigator.languages) {
        const lang = locale.slice(0, 2)
        if (languages.includes(lang)) return lang
      }
    }
  }
  return 'it'
}
