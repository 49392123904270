import React from 'react'
import { injectIntl } from 'react-intl'
import { Card } from 'react-bootstrap'
import { Text } from 'grommet'
import SearchPropertyCard from './SearchPropertyCard'

/**
 * displays some dummy searches to make the user believe we have
 * properties for him, while he inputs his informations
 *
 * @class DummySearch
 */
const DummySearch = props => (
  <>
    <Card className="w-100 mb-3">
      <Card.Body className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <Text size="large" weight="bold">
            Appartamento
          </Text>
          <Text size="medium" className="text-dark pt-2" level={4}>
            Merano
          </Text>
        </div>
        <div className="d-flex flex-column">
          <Text size="large" weight="bold">
            {props.intl.formatNumber(250000, { style: 'currency', currency: 'EUR' })}
          </Text>
        </div>
      </Card.Body>
    </Card>
    {props.properties &&
      props.properties.map(property => <SearchPropertyCard key={property._id} property={property} language={props.intl.locale}></SearchPropertyCard>)}
  </>
)

export default injectIntl(DummySearch)
