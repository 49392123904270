import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Container } from 'react-bootstrap'
import { Box, Heading, Text, TextInput, FormField, Button, Form } from 'grommet'
import { ReactSVG } from 'react-svg'
import { Formik } from 'formik'
import { authFetch } from 'utils/website-utils'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'

class Login extends Component {
  constructor(props) {
    super(props)

    const query = props.location.search
    const params = new URLSearchParams(query)

    this.state = {
      callback: params.get('callback'),
      isCaptchaVerified: false
    }
  }

  componentDidMount() {
    const { auth } = this.props

    if (auth.isWebsiteAuth) {
      this.props.history.push('/account')
    }
  }

  onValidate = values => {
    let errors = {}
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = this.props.intl.formatMessage({ id: 'global.errors.email' })
    }
    return errors
  }

  render() {
    const intl = this.props.intl
    const { callback, isCaptchaVerified } = this.state

    return (
      <Container className="main-container sell">
        <Box direction="column" alignContent="center" align="center" pad="medium">
          <Heading size="small">{callback ? intl.formatMessage({ id: `website.login.${callback}` }) : 'Login'}</Heading>
          <ReactSVG
            src="/website/sale.svg"
            className="w-md-25 w-75 p-3"
            style={{ maxWidth: '250px' }}
            beforeInjection={svg => svg.setAttribute('style', 'width: 100%; height: 100%;')}
          />
          {callback && (
            <Text className="text-center">
              <FormattedMessage id={`website.${callback}.text`}></FormattedMessage>
            </Text>
          )}
          <Box direction="column" align="center" pad="medium">
            <Formik
              initialValues={{ email: '' }}
              validate={this.onValidate}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                return authFetch('post', '/api/v1/account/login', { email: values.email, callback })
                  .then(() => {
                    this.props.history.push('/confirm')
                  })
                  .catch(() => {
                    setSubmitting(false)
                    setErrors({ email: intl.formatMessage({ id: 'global.errors.error' }) })
                  })
              }}
            >
              {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                  <FormField label="Email" htmlFor="email" error={errors.email}>
                    <TextInput type="email" name="email" placeholder="email@email.com" onChange={handleChange} value={values.email}></TextInput>
                  </FormField>
                  <Text className="mb-3" size="small">
                    {intl.formatMessage({ id: 'website.login.help' })}
                  </Text>
                  <div className="d-flex justify-content-center mb-3">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY} onChange={() => this.setState({ isCaptchaVerified: true })} />
                  </div>
                  <Button
                    type="submit"
                    disabled={!isCaptchaVerified || isSubmitting}
                    style={{ maxWidth: '150px', marginLeft: 'auto' }}
                    primary
                    label="Login"
                  ></Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Container>
    )
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(injectIntl(withRouter(Login)))
