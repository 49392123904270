import React, { Component } from 'react'
import { TextInput } from 'grommet'
import { injectIntl } from 'react-intl'

class TextInputNew extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    const input = this.inputRef.current
    input && input.addEventListener('wheel', this.onWheel.bind(this), { passive: false })
  }

  componentWillUnmount() {
    const input = this.inputRef.current
    input && input.removeEventListener('wheel', this.onWheel.bind(this), { passive: false })
  }

  onWheel(e) {
    e.preventDefault()

    return false
  }

  render() {
    return <TextInput ref={this.inputRef} {...this.props} />
  }
}

export default injectIntl(TextInputNew)
