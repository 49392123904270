import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal, Alert, Container } from 'react-bootstrap'
import { Heading } from 'grommet'
import Axios from 'axios'
import Spinner from './Spinner'
import { timeout } from 'utils/utils'
import { logError } from 'utils/sentry-log'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImageLoader from './ImageLoader'
import PropertyCard from './PropertyCard'

const ALERT_VARIANT = {
  not_interested: 'danger',
  wait: 'secondary',
  consulting: 'warning',
  visit: 'success'
}

/**
 *
 * @class PropertyDetailDialog
 */
class PropertyDetailDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      property: props.match && props.match.property,
      isFirst: true,
      isMobile: true
    }

    this.onButtonClick = this.onButtonClick.bind(this)
  }

  async componentDidUpdate(prevProps) {
    const { match, intl } = this.props

    const property = match && match.property
    if (property && (!prevProps.match || property._id !== prevProps.match.property._id)) {
      try {
        this.setState({ property, show: true })
      } catch (e) {
        logError(e)
      } finally {
        this.setState({ isLoading: false })
      }
    }
  }

  onButtonClick = (type) => {
    const { onConfirm, match } = this.props

    onConfirm && onConfirm(type, match)
    this.setState({ show: false })
  }

  onClose = () => {
    this.props.onClose()
    this.setState({ show: false, property: null })
  }

  render() {
    const { show, property } = this.state
    const { match } = this.props

    return (
      property && (
        <Modal show={show} onHide={this.onClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            {match && match.state !== 'new' && (
              <Alert className="mb-1 p-2" variant={ALERT_VARIANT[match.state]}>
                <FormattedMessage id={`website.find.matches.${match.state}`} />
              </Alert>
            )}
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center align-items-center flex-column pb-1">
            <Container className="flex-grow-1 flex-column d-flex">
              <div className="d-flex justify-content-center align-items-center flex-column flex-grow-1 text-dark">
                <PropertyCard property={property} language={this.props.intl.locale} isSearch={true} target="_blank"></PropertyCard>

                <a className="text-dark pt-4" href={`/property/${property.type.type}/${property._id}`} target="_blank" rel="noopener noreferrer">
                  <span className="mt-2">
                    <FormattedMessage id="website.find.detail" />
                  </span>
                </a>

                <a
                  className="text-dark p-2"
                  href={`/api/v1/expose/pdf?type=${property.type.type}&id=${property._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="mt-2">Download Exposè</span>
                </a>
              </div>
            </Container>
          </Modal.Body>
          {match && (match.state === 'new' || match.state === 'wait' || match.state === 'not_interested') && (
            <Modal.Footer className="d-flex p-2 justify-content-center align-items-center">
              <Alert className="mb-1 cpointer text-center p-2" variant="danger" role="button" onClick={() => this.onButtonClick('not_interested')}>
                <FormattedMessage id="website.find.matches.not_interested" />
              </Alert>

              <Alert className="mb-1 cpointer  text-center p-2" variant="secondary" role="button" onClick={() => this.onButtonClick('wait')}>
                <FormattedMessage id="website.find.matches.wait" />
              </Alert>

              <Alert className="mb-1 cpointer text-center p-2" variant="warning" role="button" onClick={() => this.onButtonClick('consulting')}>
                <FormattedMessage id="website.find.matches.consulting" />
              </Alert>
              <Alert className="mb-1 cpointer text-center p-2" variant="success" role="button" onClick={() => this.onButtonClick('visit')}>
                <FormattedMessage id="website.find.matches.visit" />
              </Alert>
            </Modal.Footer>
          )}
        </Modal>
      )
    )
  }
}

export default injectIntl(PropertyDetailDialog)
