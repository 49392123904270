import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import { Row, Col, Container, Modal } from 'react-bootstrap'
import { Box, Button, Heading, Text, Tabs, Tab } from 'grommet'

import store from 'store/index'
import { setLanguage } from 'store/actions/language'
import { loginWebUser, logoutWebUser } from 'store/actions/authentication-web'
import { authFetch } from 'utils/website-utils'
import PropertyCard from 'components/PropertyCard'
import LoadingProperties from 'components/LoadingProperties'
import EditUserDialog from 'components/EditUserDialog'
import ConfirmDialog from 'components/ConfirmDialog'
import SearchCard from 'components/SearchCard'
import { logError } from 'utils/sentry-log'

/**
 * Account page, handles auth token set and displays user profile
 */
class Account extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isLoadingProperties: false,
      seller: null,
      client: null,
      me: null,
      confirmDialog: null,
      isDeleteDialog: false,
      deleteProperty: null,
      activeIndex: 0
    }
  }

  async componentDidMount() {
    const { loginWebUser, history, location } = this.props

    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    const callback = params.get('callback')
    const lang = params.get('lang')

    if (lang && ['it', 'de', 'en'].indexOf(lang) >= 0) {
      await store.dispatch(setLanguage(lang))
    }

    if (token) {
      await loginWebUser(token, history)
      if (callback === 'sell') {
        return history.push('/sell')
      } else if (callback === 'telephone') {
        return history.push('/account?edit=1')
      } else if (callback && callback !== 'null') {
        return history.push(`/${callback.split('.').join('/')}`)
      } else {
        return (window.location.href = '/account')
      }
    }
    const { auth, logoutWebUser } = this.props
    if (auth.isWebsiteAuth) {
      const me = await authFetch('get', '/api/v1/account/me').then(result => result.data)
      if (!me) {
        await logoutWebUser()
      }
      this.setState({ me })
      await this.loadProperties()

      if (me && me.admin) {
        const searchParams = new URLSearchParams(this.props.location.search)
        if (searchParams.get('newproperty') && searchParams.get('type')) {
          this.setState({
            confirmDialog: { type: searchParams.get('type'), id: searchParams.get('newproperty'), confirmed: true }
          })
        }
      }
    } else {
      return history.push('/')
    }

    const edit = new URLSearchParams(this.props.location.search).get('edit')
    if (edit) {
      this.setState({ isFixed: true })
    }
    this.setState({ isLoading: false })
  }

  async loadProperties() {
    this.setState({ isLoadingProperties: true })
    const { me } = this.state
    const { intl, history } = this.props
    const isAdmin = me && me.admin

    try {
      const response = await authFetch('get', '/api/v1/account')

      if (!response || !response.data) throw new Error()
      if (isAdmin) {
        const newProperties = (response.data.properties || []).map(property => (
          <PropertyCard key={property._id} property={property} isAdmin={isAdmin} language={this.props.intl.locale} isSearch={true}></PropertyCard>
        ))
        this.setState({ newProperties })
      } else if (me && me.client) {
        const searches = response.data.searches || []
        const searchCards = searches.map(search => (
          <SearchCard key={search._id} search={search} language={intl.locale} onClick={history.push} isAdmin={isAdmin} />
        ))
        this.setState({ searches: searchCards })
      }
      const properties = (isAdmin ? response.data.uncofirmed : response.data.properties) || []
      const cards = (properties || []).map(property => (
        <PropertyCard
          key={property._id}
          property={property}
          isAdmin={isAdmin}
          language={this.props.intl.locale}
          onDelete={property => this.setState({ isDeleteDialog: true, deleteProperty: property })}
          onEdit={prop => this.props.history.push(`/edit/${prop.type.type}/${prop._id}`)}
          isEdit={!isAdmin && property.isConfirmed}
        ></PropertyCard>
      ))

      this.setState({ properties: cards })

      const { searches } = this.state
      if (properties && searches && searches.length > 0 && properties.length < 1) {
        this.setState({ activeIndex: 1 })
      }
    } catch (e) {
      logError(e)
    } finally {
      this.setState({ isLoadingProperties: false })
    }
  }

  onUserEditSave = ({ me, token }) => {
    this.setState({ me })
    return this.props.loginWebUser(token)
  }

  onConfirmClose = () => {
    this.setState({ confirmDialog: null })
    this.props.history.push('/account')
  }

  async onDeleteProperty() {
    const { _id, type } = this.state.deleteProperty
    await authFetch('delete', `/api/v1/properties/${type.id}/${_id}/delete`)

    const cards = this.state.properties.filter(card => card.key !== _id)
    this.setState({ properties: cards, isDeleteDialog: false })
  }

  render() {
    const {
      isLoading,
      isLoadingProperties,
      properties,
      me,
      newProperties,
      confirmDialog,
      isDeleteDialog,
      isFixed,
      searches,
      activeIndex
    } = this.state
    const { intl, history } = this.props

    const user = me ? me.seller || me.client || me.admin : null

    return (
      <Box className="account main-container" pad="small">
        {me && me.admin && <ConfirmDialog {...confirmDialog} onClose={this.onConfirmClose} />}
        <div className="account-header flex-wrap d-flex pt-4 pl-4 pr-4 pb-3 align-items-end justify-content-between bg-light">
          {me && (
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center">
                <Text size="xlarge" weight="bold">
                  {user && user.name ? `${user.name} ${user.familyName}` : user && user.companyName ? user.companyName : me.email}
                </Text>
                {me.admin && me.admin.isAdmin && (
                  <Button weight="bold" color="status-critical" className="pl-2" onClick={() => history.push('/admin')}>
                    Admin
                  </Button>
                )}
                {!me.admin && <EditUserDialog me={me} onSave={this.onUserEditSave} isFixed={isFixed} />}
              </div>
              <Text color="dark-2">{me.email}</Text>
            </div>
          )}
          <Button
            primary
            label={intl.formatMessage({ id: activeIndex === 0 ? 'website.sell.new.title' : 'website.login.buy' })}
            onClick={() => history.push(activeIndex === 0 ? '/sell' : '/find')}
          />
        </div>
        {me &&
          (me.admin ? (
            <Box direction="column" pad="small">
              {me.admin.isAdmin && (
                <>
                  <Heading level={3} className="pt-2">
                    <FormattedMessage id="website.account.unconfirmed"></FormattedMessage>
                  </Heading>
                  <Container>
                    {isLoadingProperties && (
                      <Row className="pt-5 pb-5 w-100">
                        <LoadingProperties count={3} />
                      </Row>
                    )}
                    <Row>
                      {properties && properties.length > 0 && !isLoadingProperties ? (
                        properties
                      ) : (
                        <Heading level="3" style={{ margin: 'auto', paddingTop: '32px', paddingBottom: '32px' }} color="light-5">
                          <FormattedMessage id="website.account.empty"></FormattedMessage>
                        </Heading>
                      )}
                    </Row>
                  </Container>
                </>
              )}

              <Heading level={3} className="pt-">
                <FormattedMessage id="website.account.newProperties"></FormattedMessage>
              </Heading>
              <Container>
                {isLoadingProperties && (
                  <Row className="pt-5 pb-5 w-100">
                    <LoadingProperties count={3} />
                  </Row>
                )}
                <Row>
                  {newProperties && newProperties.length > 0 && !isLoadingProperties ? (
                    newProperties
                  ) : (
                    <Heading level="3" style={{ margin: 'auto', paddingTop: '16px' }} color="light-5">
                      <FormattedMessage id="website.account.empty"></FormattedMessage>
                    </Heading>
                  )}
                </Row>
              </Container>
            </Box>
          ) : (
            <Container>
              {isLoading ? (
                <Row className="pt-5 pb-5">
                  <LoadingProperties count={3} />
                </Row>
              ) : (
                <Row>
                  <Col>
                    <Tabs className="pt-3" activeIndex={activeIndex} onActive={i => this.setState({ activeIndex: i })}>
                      <Tab title={intl.formatMessage({ id: 'website.account.properties' })}>
                        <Box direction="column" pad="small">
                          <Row>
                            {properties && properties.length > 0 ? (
                              properties
                            ) : (
                              <Heading level="3" style={{ margin: 'auto', paddingTop: '16px' }} color="light-5">
                                <FormattedMessage id="website.account.empty"></FormattedMessage>
                              </Heading>
                            )}
                          </Row>
                        </Box>
                      </Tab>
                      <Tab title={intl.formatMessage({ id: 'website.account.searches' })}>
                        <Box direction="column" pad="small">
                          {searches && searches.length > 0 ? (
                            searches
                          ) : (
                            <Heading level="3" style={{ margin: 'auto', paddingTop: '16px' }} color="light-5">
                              <FormattedMessage id="website.account.emptySearches"></FormattedMessage>
                            </Heading>
                          )}
                        </Box>
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              )}
            </Container>
          ))}
        <Modal size="md" show={isDeleteDialog} onHide={() => this.setState({ isDeleteDialog: false, deleteProperty: null })} centered={true}>
          <Modal.Header />
          <Modal.Body>
            <Text>
              <FormattedMessage id="website.account.deleteProperty" />
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button primary label={<FormattedMessage id="admin.property.true" />} onClick={this.onDeleteProperty.bind(this)} />
            <Button
              label={<FormattedMessage id="admin.property.false" />}
              onClick={() => this.setState({ isDeleteDialog: false, deleteProperty: null })}
            />
          </Modal.Footer>
        </Modal>
      </Box>
    )
  }
}

Account.propTypes = {
  loginWebUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  logoutWebUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { loginWebUser, logoutWebUser })(injectIntl(withRouter(Account)))
