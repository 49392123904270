import React from 'react'
import { ReactSVG } from 'react-svg'
import { Text } from 'grommet'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

export default props => {
  const { rooms, areas } = props.property

  const activeRooms = []
  const activeAreas = []
  const activeParking = []

  Object.keys(rooms || {}).forEach(key => {
    if (rooms[key] && rooms[key] > 0) {
      activeRooms.push(
        <OverlayTrigger
          key={key}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${key}`}>
              <FormattedMessage id={`admin.property.rooms.${key}`} />
            </Tooltip>
          }
        >
          <div className="d-flex flex-column justify-content-center align-items-center pl-2 pr-2">
            <ReactSVG
              src={`/website/rooms/${key}.svg`}
              beforeInjection={svg => svg.setAttribute('style', 'opacity: 0.8; width: 24px; height: 24px')}
            />
            <Text size="small">{rooms[key]}</Text>
          </div>
        </OverlayTrigger>
      )
    }
  })

  Object.keys(areas || {}).forEach(key => {
    if (areas[key] && areas[key].amount > 0) {
      activeAreas.push(
        <OverlayTrigger
          key={key}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${key}`}>
              <FormattedMessage id={`admin.property.areas.${key}`} />
            </Tooltip>
          }
        >
          <div className="d-flex flex-column justify-content-center align-items-center pl-2 pr-2">
            <ReactSVG
              src={`/website/rooms/${key}.svg`}
              beforeInjection={svg => svg.setAttribute('style', 'opacity: 0.8; width: 24px; height: 24px')}
            />
            <Text size="small">{areas[key].amount}</Text>
          </div>
        </OverlayTrigger>
      )
    }
  })

  return activeRooms.concat(activeAreas).concat(activeParking)
}
