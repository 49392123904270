import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import { Heading } from 'grommet'
import { Container } from 'react-bootstrap'

class Privacy extends Component {
  render() {
    return (
      <Container className="main-container w-100 pb-4" direction="column">
        <Heading level={1} className="pt-2 pb-2 text-center">
          <FormattedMessage id="website.privacy.title" />
        </Heading>
        <FormattedHTMLMessage id="website.privacy.text" />
      </Container>
    )
  }
}

export default injectIntl(withRouter(Privacy))
