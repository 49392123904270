import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { logoutWebUser } from 'store/actions/authentication-web'

class Logout extends Component {
  async componentDidMount() {
    await this.props.logoutWebUser()
    this.props.history.push('/')
  }

  render() {
    return <></>
  }
}

Logout.propTypes = {
  logoutWebUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(
  mapStateToProps,
  { logoutWebUser }
)(withRouter(Logout))
