import React from 'react'
import { injectIntl } from 'react-intl'
import NumberFormat from 'react-number-format'

/**
 * This component can be used to enter formatted values with a suffix e.g. m²
 *
 * @class NumberField
 */
class NumberField extends React.Component {
  onChange = values => {
    const { value } = values
    this.props.onChange && this.props.onChange(parseFloat(value))
  }

  render() {
    const { name, suffix, disabled, value, style } = this.props

    return (
      <NumberFormat
        className="ui-currency-input"
        name={name}
        value={value}
        disabled={disabled}
        thousandSeparator={'.'}
        decimalSeparator={','}
        suffix={suffix}
        decimalScale={2}
        allowNegative={false}
        allowLeadingZeros={false}
        onValueChange={this.onChange}
        style={style}
      />
    )
  }
}

export default injectIntl(NumberField)
