import { combineReducers } from 'redux'

import errors from './errors'
import auth from './auth'
import language from './language'


export default combineReducers({
	errors,
	auth,
	language
})
