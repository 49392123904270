import React, { Component } from 'react'
import Toast from './Toast'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { Text } from 'grommet'

/**
 * Simple component that shows the cookie consent the first time the user
 * visits the website. Data is stored in localStorage
 *
 * @class CookieConsent
 */
class CookieConsent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      didAccept: true
    }
  }

  componentDidMount() {
    if (localStorage) {
      const didAccept = localStorage.getItem('cookies')
      this.setState({ didAccept })
    }
  }

  handleAccept = () => {
    if (localStorage) {
      localStorage.setItem('cookies', true)
      this.setState({ didAccept: true })
    }
  }

  render() {
    const { intl } = this.props
    const { didAccept } = this.state
    const title = (
      <Text size="small">
        {intl.formatMessage({ id: 'website.cookies.title' })}
        <Link to="/privacy">
          <br></br>
          {intl.formatMessage({ id: 'website.cookies.more' })}
        </Link>
      </Text>
    )
    return !didAccept ? (
      <Toast title={title} isWebsite={true}>
        <Button variant="warning" onClick={this.handleAccept}>
          {intl.formatMessage({ id: 'website.cookies.accept' })}
        </Button>
      </Toast>
    ) : (
      <></>
    )
  }
}

export default injectIntl(CookieConsent)
