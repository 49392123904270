import { MapControl, withLeaflet } from 'react-leaflet'
import L from 'leaflet'
// eslint-disable-next-line
import Draw from 'leaflet-draw'
import { injectIntl } from 'react-intl'

const options = {
  position: 'topleft',
  draw: {
    rectangle: false,
    polyline: false,
    circle: false,
    marker: false,
    circlemarker: false,
    polygon: {
      showArea: false,
      showLength: false
    }
  }
}

const controlTranslations = intl => {
  return {
    toolbar: {
      actions: {
        title: intl.formatMessage({ id: 'leaflet.cancelDrawing' }),
        text: intl.formatMessage({ id: 'leaflet.cancel' })
      },
      finish: {
        title: intl.formatMessage({ id: 'leaflet.finishDrawing' }),
        text: intl.formatMessage({ id: 'leaflet.finish' })
      },
      undo: {
        title: intl.formatMessage({ id: 'leaflet.undoPoint' }),
        text: intl.formatMessage({ id: 'leaflet.undo' })
      },
      buttons: {
        polygon: intl.formatMessage({ id: 'leaflet.polygon' })
      }
    },
    handlers: {
      polygon: {
        tooltip: {
          start: intl.formatMessage({ id: 'leaflet.start' }),
          cont: intl.formatMessage({ id: 'leaflet.cont' }),
          end: intl.formatMessage({ id: 'leaflet.end' })
        }
      }
    }
  }
}

class EditControl extends MapControl {
  onDrawCreate = e => {
    this.props.onChange(e.layer)
  }

  componentDidMount() {
    super.componentDidMount()
    const { map } = this.props.leaflet
    map.on(L.Draw.Event.CREATED, this.onDrawCreate)
  }

  createLeafletElement(props) {
    const { intl } = this.props
    const translation = controlTranslations(intl)
    L.drawLocal.draw.toolbar = translation.toolbar
    L.drawLocal.draw.toolbar.buttons.polygon = translation.toolbar.buttons.polygon
    L.drawLocal.draw.handlers.polygon.tooltip = translation.handlers.polygon.tooltip

    var drawControl = new L.Control.Draw(options)

    return drawControl
  }
}

export default injectIntl(withLeaflet(EditControl))
