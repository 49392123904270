import React, { Component } from 'react'
import { RadioButton, Box } from 'grommet'
import { injectIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'
import { FormLabel, Row, Col } from 'react-bootstrap'
import CurrencyInput from './CurrencyInput'
import TextInput from './TextInput'

class ParkingSelector extends Component {
  constructor(props) {
    super(props)
    this.state = props.value

    this.onChange = this.onChange.bind(this)
  }

  async onChange(value, name, type = null) {
    let val = this.state[name]
    if (type) {
      val[type] = value
    } else {
      val = value
    }
    await this.setState({ [name]: val })
    this.props.onChange(this.state)
  }

  onFocus = (value, name, type) => {
    if (value === '0' && type === 'number') {
      const val = this.state[name]
      val[type] = ''
      this.setState({ [name]: val })
    }
  }

  onBlur = (value, name, type) => {
    if (value === '' && type === 'number') {
      this.onChange(0, name, type)
    }
    // reset price if amount is 0
    if ((this.state[name].number === 0 || this.state[name].number === '0') && type === 'number') {
      this.onChange(0, name, 'price')
    }
  }

  render() {
    const { intl, types, hideCommon, hidePrice } = this.props
    const values = types || ['garage', 'parkingLot']

    const inputs = values.map(input => (
      <Col
        key={input}
        className={`d-flex align-items-end pt-2 pb-2 w-100 ${hidePrice ? 'flex-row-reverse justify-content-end' : 'justify-content-center'}`}
      >
        <div>
          <FormLabel className="font-weight-bold">{intl.formatMessage({ id: `admin.property.parking.${input}` })}</FormLabel>
          <TextInput
            type="number"
            value={this.state[input].number}
            onChange={event => this.onChange(event.target.value, input, 'number')}
            onFocus={event => this.onFocus(event.target.value, input, 'number')}
            onBlur={event => this.onBlur(event.target.value, input, 'number')}
          ></TextInput>
        </div>
        <ReactSVG
          src={`/website/${input}.svg`}
          className="mr-2 ml-1"
          beforeInjection={svg => svg.setAttribute('style', 'opacity: 0.8; margin-top: -64px')}
        />
        {!hidePrice && (
          <div>
            <FormLabel htmlFor={input}>{intl.formatMessage({ id: `admin.property.parking.price` })}</FormLabel>
            <CurrencyInput
              disabled={this.state[input].number < 1}
              name={input}
              value={this.state[input].price}
              onChange={val => this.onChange(val, input, 'price')}
              onFocus={event => this.onFocus(event.target.value, input, 'price')}
              onBlur={event => this.onBlur(event.target.value, input, 'price')}
            />
          </div>
        )}
      </Col>
    ))

    return (
      <Row className="ui-room-number-selector d-flex flex-column">
        {inputs}
        {!hideCommon && (
          <>
            <Col className="d-flex flex-column justify-content-center align-items-start pt-2 pb-2 w-100">
              <FormLabel className="font-weight-bold">{intl.formatMessage({ id: `admin.property.parking.common` })}</FormLabel>
              <div className="d-flex justify-content-start align-items-center w-100">
                <ReactSVG
                  src={`/website/rooms/parking.svg`}
                  className="mr-2 ml-1"
                  beforeInjection={svg => svg.setAttribute('style', 'opacity: 0.8')}
                />
                <Box>
                  <RadioButton
                    label={intl.formatMessage({ id: 'admin.property.true' })}
                    name="common1"
                    value={true}
                    checked={this.state.common === true}
                    onChange={() => this.onChange(true, 'common')}
                  />
                  <div className="mb-1"></div>
                  <RadioButton
                    label={intl.formatMessage({ id: 'admin.property.false' })}
                    name="common2"
                    value={false}
                    checked={this.state.common === false}
                    onChange={() => this.onChange(false, 'common')}
                  />
                </Box>
              </div>
            </Col>
            <Col className="d-flex flex-column justify-content-center align-items-start pt-2 pb-2 w-100">
              <FormLabel className="font-weight-bold">{intl.formatMessage({ id: `admin.property.parking.bike` })}</FormLabel>
              <div className="d-flex justify-content-start align-items-center w-100">
                <ReactSVG src={`/website/rooms/bike.svg`} className="mr-2 ml-1" beforeInjection={svg => svg.setAttribute('style', 'opacity: 0.8')} />
                <Box>
                  <RadioButton
                    label={intl.formatMessage({ id: 'admin.property.true' })}
                    name="bike1"
                    value={true}
                    checked={this.state.bike === true}
                    onChange={() => this.onChange(true, 'bike')}
                  />
                  <div className="mb-1"></div>
                  <RadioButton
                    label={intl.formatMessage({ id: 'admin.property.false' })}
                    name="bike2"
                    value={false}
                    checked={this.state.bike === false}
                    onChange={() => this.onChange(false, 'bike')}
                  />
                </Box>
              </div>
            </Col>
          </>
        )}
      </Row>
    )
  }
}

export default injectIntl(ParkingSelector)
