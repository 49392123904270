import React from 'react'

const Spinner = props => {
  if (props.isSuspense) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: props.height || '500px' }}>
        <div className="ui-spinner"></div>
      </div>
    )
  }
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Spinner
