import React, { PureComponent } from 'react'
import { Col, Card } from 'react-bootstrap'

/**
 * loading skeleton for property cards
 */
class LoadingProperties extends PureComponent {
  render() {
    const loadingPlacehoders = []
    const count = this.props.count || 10

    for (let i = 0; i < count; i++) {
      loadingPlacehoders.push(
        <Col key={i} sm={12}>
          <Card style={{ height: '250px', border: 'none' }} className="ui-property-card w-100 flex-sm-row pb-5 flex-column">
            <div className="ui-loading-placeholder__row" style={{ height: '100%', width: '300px' }}></div>
            <div className="d-flex flex-column w-100 pl-sm-2 justify-content-end">
              <div className="ui-loading-placeholder__row w-50 mb-1 p-2" style={{ height: '24px' }}></div>
              <div className="ui-loading-placeholder__row w-100 mb-1 p-2" style={{ height: '12px' }}></div>
              <div className="ui-loading-placeholder__row w-100" style={{ height: '12px' }}></div>
            </div>
          </Card>
        </Col>
      )
    }

    return <>{loadingPlacehoders}</>
  }
}

export default LoadingProperties
