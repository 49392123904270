import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { TextInput, Box, Text, FormField, Button } from 'grommet'
import { Spinner, ListGroup, ListGroupItem } from 'react-bootstrap'

import LoadingPlaceholder from './LoadingPlaceholder'
import Map from './Map'

import { debounce } from 'utils/utils'
import { authFetch } from 'utils/website-utils'
import { logError } from 'utils/sentry-log'

/**
 * used to select a location as a point
 */
class PositionSelector extends Component {
  constructor(props) {
    super(props)

    let address
    if (props.location && props.location.address) {
      address = props.location.address
    }
    const locale = props.intl.locale === 'en' ? 'it' : props.intl.locale
    this.state = {
      search: '',
      locationSuggestions: [],
      municipality: address ? address.municipality.name[locale] : '',
      street: address ? address.street[locale] : '',
      streetNumber: address ? String(address.streetNumber) : '',
      isMunicipalitySelected: false,
      isLoading: false,
      location: props.location
    }

    this.handleMunInputChange = this.handleMunInputChange.bind(this)
    this.handleStreetChange = this.handleStreetChange.bind(this)
    this.onVerify = this.onVerify.bind(this)
    this.debouncedVerification = debounce(this.onVerify, 500)
  }

  async handleMunInputChange(event) {
    try {
      const { intl } = this.props
      const municipality = event.target.value
      this.setState({ municipality })
      const response = await authFetch('get', `/api/v1/locations/municipalities/search?q=${municipality}&lang=${intl.locale}`)
      const locationSuggestions = response.data || []
      this.setState({ locationSuggestions, street: '', streetNumber: '' })
    } catch (e) {
      logError(e)
      this.setState({ locationSuggestions: [] })
    }
  }

  async handleStreetChange(event) {
    const value = event.target.value
    this.setState({ [event.target.name]: value })

    const { street, streetNumber } = this.state
    if (street && street.length > 0 && streetNumber && streetNumber.length > 0) {
      this.debouncedVerification()
    }
  }

  async onVerify() {
    this.setState({ isError: false, location: null })

    try {
      this.setState({ isLoading: true })
      const { municipality, street, streetNumber } = this.state
      const response = await authFetch('post', '/api/v1/locations/geocode', { municipality, street, streetNumber })

      if (!response || response.status === 400) throw new Error()
      this.props.onLocationChange(response.data)
      this.setState({ location: response.data })
    } catch (e) {
      this.setState({ isError: true, location: null })
      this.props.onLocationChange(null)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  onSelectMunicipality = e => {
    const { locationSuggestions } = this.state

    const municipality = locationSuggestions.find(sug => sug.id === e.suggestion.value)

    this.setState({ municipality: municipality.name, isMunicipalitySelected: true })
  }

  render() {
    const { municipality, locationSuggestions, location, street, streetNumber, isLoading, isError } = this.state
    const { isReadOnly, intl, exactPosition, hideList, maxZoom } = this.props

    const locale = intl.locale === 'en' ? 'it' : intl.locale

    const suggestions = locationSuggestions.map(suggestion => {
      const type = intl.formatMessage({ id: `website.${suggestion.type}` })
      return {
        label: (
          <Box margin="xsmall">
            {suggestion.name}
            <Text size="xsmall"> {type}</Text>
          </Box>
        ),
        value: suggestion.id
      }
    })
    return (
      <div className="pb-3">
        {!isReadOnly && (
          <>
            <FormField label={intl.formatMessage({ id: 'website.location.municipality' })} htmlFor="municipality">
              <TextInput
                id="municipality"
                name="municipality"
                value={municipality}
                onChange={this.handleMunInputChange}
                onSelect={this.onSelectMunicipality}
                suggestions={suggestions}
              />
            </FormField>
            {municipality && municipality.length > 3 && (
              <Box>
                <FormField label={intl.formatMessage({ id: 'website.location.street' })} htmlFor="street">
                  <TextInput name="street" value={street} onChange={this.handleStreetChange} />
                </FormField>
                <FormField label="Nr." htmlFor="streetNumber">
                  <TextInput name="streetNumber" value={streetNumber} type="text" onChange={this.handleStreetChange} />
                </FormField>
                {isError && (
                  <Text color="red" className="mb-2">
                    <FormattedMessage id="global.errors.address" />
                  </Text>
                )}

                <div>
                  <Button
                    label={
                      isLoading ? <Spinner className="mb-1" animation="border" size="sm" /> : intl.formatMessage({ id: 'global.actions.verify' })
                    }
                    disabled={isLoading}
                    onClick={this.onVerify}
                  ></Button>
                </div>
              </Box>
            )}
          </>
        )}
        {location && location.address && !isLoading && (
          <>
            {!hideList && (
              <ListGroup className="mt-3 mb-3">
                <ListGroupItem className="d-flex justify-content-between">
                  <div>
                    <b>
                      <FormattedMessage id="website.location.street" />
                    </b>
                    <br />
                    {location.address.street[locale]} {location.address.streetNumber}
                  </div>
                </ListGroupItem>
                {location.address.municipality && (
                  <ListGroupItem className="d-flex">
                    <div>
                      <b>
                        <FormattedMessage id="website.location.municipality" />
                      </b>
                      <br />
                      {location.address.municipality.name[locale]}
                    </div>
                  </ListGroupItem>
                )}
                {(location.zoneName || location.zone.properties) && (
                  <ListGroupItem className="d-flex">
                    <div>
                      <b>
                        <FormattedMessage id="website.location.zone" />
                      </b>
                      <br />
                      {location.zoneName || location.zone.properties.name.it}
                    </div>
                  </ListGroupItem>
                )}
                <ListGroupItem className="d-flex">
                  <div>
                    <b>
                      <FormattedMessage id="website.location.region" />
                    </b>
                    <br />
                    {location.address.region}
                  </div>
                </ListGroupItem>
              </ListGroup>
            )}
            <Map markerPosition={[...location.coordinates].reverse()} exactPosition={exactPosition} maxZoom={maxZoom} />
          </>
        )}
        {isLoading && <LoadingPlaceholder count={3} className="mt-2" />}
      </div>
    )
  }
}

export default injectIntl(PositionSelector)
