import React, { Component } from 'react'
import { Text } from 'grommet'
import { injectIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'
import { FormLabel, Row, Col } from 'react-bootstrap'
import TextInput from './TextInput'

class RoomNumberSelector extends Component {
  constructor(props) {
    super(props)

    Object.keys(props.value).forEach(key => {
      if (props.value[key] === undefined) {
        delete props.value[key]
      }
    })

    this.state = props.value

    this.onChange = this.onChange.bind(this)
  }

  async onChange(event) {
    await this.setState({ [event.target.name]: event.target.value })
    this.props.onChange(this.state)
  }

  onFocus = event => {
    if (event.target.value === '0') {
      this.setState({ [event.target.name]: '' })
    }
  }

  onBlur = event => {
    if (event.target.value === '') {
      this.setState({ [event.target.name]: 0 })
    }
  }

  render() {
    const { intl, isReadOnly } = this.props
    const values = Object.keys(this.state)

    const inputs = values.map(input => (
      <Col key={input} className="d-flex flex-column justify-content-center align-items-start" md={isReadOnly ? 2 : 4} xs={isReadOnly ? 4 : 6}>
        <div className="d-flex">
          <ReactSVG src={`/website/rooms/${input}.svg`} className="mr-2" beforeInjection={svg => svg.setAttribute('style', 'opacity: 0.8')} />
          {isReadOnly ? (
            <div className="d-flex justify-content-center align-items-center">
              <Text size="large" className="pl-2">
                {this.state[input]}
              </Text>
            </div>
          ) : (
            <TextInput
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              type="number"
              value={this.state[input]}
              onChange={this.onChange}
              name={input}
            ></TextInput>
          )}
        </div>
        <FormLabel>{intl.formatMessage({ id: `admin.property.rooms.${input}` })}</FormLabel>
      </Col>
    ))

    return <Row className="ui-room-number-selector w-100">{inputs}</Row>
  }
}

export default injectIntl(RoomNumberSelector)
