import React, { useState } from 'react'
import styled from 'styled-components'
import { Text, Button, Form, FormField, TextInput, TextArea, Heading } from 'grommet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Modal } from 'react-bootstrap'
import Toast from 'components/Toast'
import { Formik } from 'formik'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

const StyledImg = styled.img`
  width: 120px !important;
  height: 120px;
  border-radius: 100%;
  object-fit: cover;
  box-shadow: 0 14px 40px rgba(0, 22, 84, 0.08);
  margin-bottom: 20px;
`
/**
 * Contact card shown in property detail on website
 *
 * @class ContactCard
 */
const ContactCard = (props) => {
  const { intl, property } = props

  const [isOpen, setOpen] = useState(false)
  const [isContactError, setContactError] = useState(false)
  const [isContactSuccess, setContactSuccess] = useState(false)

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const payload = { ...values, id: props.property.property_id, type: property.type.name.it }
    const message = `Immobile Nr. ${property.property_id} \n\n${payload.message}`
    payload.message = message
    Axios.post('/api/v1/contact', payload)
      .then((response) => {
        if (response.status !== 200) throw new Error()
        setSubmitting(false)
        resetForm()
        setContactSuccess(true)
        setTimeout(() => setContactSuccess(true), 4000)
        setOpen(false)
      })
      .catch(() => {
        setContactError(true)
        setTimeout(() => setContactError(false), 4000)
        setSubmitting(false)
      })
  }
  return (
    <>
      <div className="ui-property-card__box d-flex flex-column p-4 align-items-center w-100 bg-white" style={{ maxHeight: '350px' }}>
        <StyledImg widt src={'/logo-square.jpg'} />
        <Text>+39 0473 201095</Text>
        <a href="tel:+390473201095">
          <Button
            className="mt-3"
            label={
              <>
                <FontAwesomeIcon icon={faPhoneAlt} className="pr-1" />
                <FormattedMessage id="contact.call" />
              </>
            }
            style={{ borderRadius: '20px' }}
          />
        </a>
        <Button
          onClick={() => setOpen(true)}
          className="mt-3"
          label={<FormattedMessage id="website.menu.contact" />}
          primary
          style={{ borderRadius: '20px' }}
        />
      </div>
      <Formik initialValues={{ name: '', email: '', message: '', tel: '' }} onSubmit={onSubmit}>
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <Modal
            show={isOpen}
            onHide={() => setOpen(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            className="ui-select-seller-dialog"
            centered
          >
            <Modal.Header closeButton>
              <Heading level="3">
                <FormattedMessage id="website.menu.contact" />
              </Heading>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                <FormField label={intl.formatMessage({ id: 'global.properties.name' })} htmlFor="name">
                  <TextInput type="text" name="name" id="name" onChange={handleChange} value={values.name} required></TextInput>
                </FormField>
                <FormField label={intl.formatMessage({ id: 'global.properties.email' })} htmlFor="email">
                  <TextInput type="email" name="email" id="email" onChange={handleChange} value={values.email} required></TextInput>
                </FormField>
                <FormField label={intl.formatMessage({ id: 'global.properties.tel' })} htmlFor="tel">
                  <TextInput type="tel" name="tel" id="email" onChange={handleChange} value={values.tel}></TextInput>
                </FormField>
                <FormField label={intl.formatMessage({ id: 'global.properties.message' })} htmlFor="message">
                  <TextArea className="mb-3" name="message" id="message" onChange={handleChange} value={values.message} required />
                </FormField>
                <Button
                  type="submit"
                  style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: 'auto' }}
                  primary
                  disabled={isSubmitting}
                  label={<FormattedMessage id="global.actions.send" />}
                ></Button>
              </Form>

              {isContactError && <Toast title={intl.formatMessage({ id: 'contact.error' })} isWebsite={true} isError={true} />}
              {isContactSuccess && <Toast title={intl.formatMessage({ id: 'contact.success' })} isWebsite={true} isSuccess={true} />}
            </Modal.Body>
          </Modal>
        )}
      </Formik>
    </>
  )
}

export default injectIntl(ContactCard)
