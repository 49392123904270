import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import UploadView from 'containers/hocs/UploadView'

/**
 * This component combines three upload-views and splits the content of the given plans into
 *  - floorplans
 *  - cataster
 *  - landregister
 *
 * @class CatasterUploadView
 */
class CatasterUploadView extends Component {
  /**
   * each upload view returns only one set of plans, in order to avoid duplicates
   * these are filtered and all new ones are passed to the callback
   *
   * @function onChange
   */
  onChange = (plans, type, file, deleted) => {
    const oldPlans = this.props.plans.filter(plan => plan.type !== type)
    this.props.onChange(oldPlans.concat(plans), file, deleted)
  }

  render() {
    const { plans, isWeb, isReadOnly } = this.props
    const floorplans = [],
      cataster = [],
      landregister = []

    // each plan is mapped to the correct view
    plans.forEach(plan => {
      const type = plan ? plan.type : 'cataster'

      if (type === 'floorplan') {
        floorplans.push(plan)
      } else if (type === 'cataster') {
        cataster.push(plan)
      } else {
        landregister.push(plan)
      }
    })

    return (
      <div className="d-flex flex-column pt-2 w-100">
        {((floorplans && floorplans.length > 0) || !isReadOnly) && (
          <UploadView
            images={floorplans}
            onChange={(plans, file, deleted) => this.onChange(plans, 'floorplan', file, deleted)}
            isReadOnly={isReadOnly}
            title={<FormattedMessage id="admin.property.floorplans" />}
            filetypes={['image/*', 'application/pdf']}
            id="floorplans-upload"
            type="floorplan"
            isWeb={isWeb}
          >
            <FormattedMessage id="admin.property.uploadPlans"></FormattedMessage>
          </UploadView>
        )}
        {((cataster && cataster.length > 0) || !isReadOnly) && (
          <UploadView
            images={cataster}
            onChange={plans => this.onChange(plans, 'cataster')}
            isReadOnly={isReadOnly}
            title={<FormattedMessage id="admin.property.catasters" />}
            filetypes={['image/*', 'application/pdf']}
            id="cataster-upload"
            type="cataster"
            isWeb={isWeb}
            className="pt-2"
          >
            <FormattedMessage id="admin.property.uploadPlans"></FormattedMessage>
          </UploadView>
        )}
        {((landregister && landregister.length > 0) || !isReadOnly) && (
          <UploadView
            images={landregister}
            onChange={(plans, file, deleted) => this.onChange(plans, 'landregister', file, deleted)}
            isReadOnly={isReadOnly}
            title={<FormattedMessage id="admin.property.landregisters" />}
            filetypes={['image/*', 'application/pdf']}
            id="landregister-upload"
            type="landregister"
            isWeb={isWeb}
            className="pt-2"
          >
            <FormattedMessage id="admin.property.uploadPlans"></FormattedMessage>
          </UploadView>
        )}
      </div>
    )
  }
}

export default withRouter(CatasterUploadView)
