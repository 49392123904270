import React from 'react'
import { Text } from 'grommet'

const RadioButton = props => {
  const labelSize = props.labelSize || 'medium'
  const inputProps = { ...props }
  delete inputProps.labelSize
  return (
    <label className="ui-radio-button d-flex" style={props.style || {}}>
      <input type="checkbox" {...inputProps} />
      <Text size={labelSize}>{props.label}</Text>
    </label>
  )
}

export default RadioButton
