import axios from 'axios'
import { GET_ERRORS, SET_WEBSITE_USER } from './types'
import jwtDecoded from 'jwt-decode'
import setAuthToken from '../../setAuthToken'

export const registerWebUser = (user, history) => dispatch => {
  axios
    .post('/api/v1/account/login', user)
    .then(res => history.push('/confirm'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    )
}

export const loginWebUser = token => dispatch => {
  const newToken = `Bearer ${token}`
  const decoded = jwtDecoded(newToken)

  const currentTime = new Date() / 1000
  if (decoded.exp < currentTime) {
    logoutWebUser()
    window.location.href = '/'
  } else {
    localStorage.setItem('jwtWebToken', newToken)

    if (decoded.isAdmin || decoded.isAgent) {
      setAuthToken(newToken)
    }

    dispatch(setWebUser(decoded))
  }
}

export const setWebUser = decoded => ({
  type: SET_WEBSITE_USER,
  payload: decoded
})

export const logoutWebUser = () => dispatch => {
  localStorage.removeItem('jwtWebToken')
  setAuthToken(false)
  dispatch(setWebUser({}))
}
