import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Button, Form, Heading, FormField, Box, RadioButtonGroup, Text } from 'grommet'
import { Formik } from 'formik'
import { ReactSVG } from 'react-svg'
import { authFetch } from 'utils/website-utils'
import PropertyTypeSelector from './PropertyTypeSelector'
import FormLabel from './FormLabel'
import LocationSelector from './LocationSelector'
import CurrencyInput from './CurrencyInput'
import TextInput from './TextInput'
import BudgetDialog from './BudgetDialog'
import { logError } from 'utils/sentry-log'

const initialFormValues = {
  rent: 'false',
  budget: 0,
  type: null,
  locations: [],
  rooms: {
    living: 0,
    bed: 0,
    bath: 0
  }
}

class SearchForm extends Component {
  constructor(props) {
    super(props)

    const initialSearchValues = Object.assign({}, initialFormValues, props.search || {})

    this.state = {
      types: null,
      initialValues: initialSearchValues,
      isBudgetHelp: false
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  onValidate = values => {
    const { intl } = this.props
    let errors = {}

    if (!values.type) errors.type = intl.formatMessage({ id: 'global.errors.required' })
    if (!values.locations || values.locations.length < 1) errors.locations = intl.formatMessage({ id: 'global.errors.required' })

    return errors
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    try {
      const typeResponse = await authFetch('get', '/api/v1/types?sort=-type')
      if (!typeResponse || !typeResponse.status === 200 || !typeResponse.data) throw new Error()

      this.setState({ isLoading: false, types: typeResponse.data })
    } catch (e) {
      this.setState({ isLoading: false })
      logError(e)
    }
  }

  async onSubmit(values) {
    const type = values.type
    const payload = { ...values }

    if (type.type !== 'residential' || type.id === 'house') delete payload.rooms
    payload.rent = payload.rent === 'false' ? false : true

    return this.props.onSubmit(payload)
  }

  render() {
    const { intl } = this.props
    const { types, initialValues, isBudgetHelp } = this.state
    return (
      <div>
        <Formik initialValues={initialValues} validate={this.onValidate} onSubmit={this.onSubmit}>
          {({ values, errors, handleSubmit, handleChange, isSubmitting, setFieldValue }) => (
            <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
              <Heading level="4" className="d-flex w-100 justify-content-center" alignSelf="center">
                {/* TITLE TODO */}
              </Heading>
              <FormField className="d-flex align-items-center" htmlFor="rent" error={errors.rent}>
                <RadioButtonGroup
                  name="rent"
                  value={values.rent}
                  options={[
                    { disabled: false, id: 'false', value: 'false', label: intl.formatMessage({ id: `admin.property.buy` }) },
                    { disabled: false, id: 'true', className: 'ml-2', value: 'true', label: intl.formatMessage({ id: `admin.property.rent.true` }) }
                  ]}
                  onChange={event => setFieldValue('rent', event.target.value)}
                />
              </FormField>
              {types && <PropertyTypeSelector types={types} onChange={type => setFieldValue('type', type)} value={initialValues.type} />}

              {values.type && (
                <>
                  <FormField
                    className="pt-2"
                    label={<FormLabel label="admin.search.locations"></FormLabel>}
                    htmlFor="locations"
                    error={errors.locations}
                  >
                    <Text size="sm" className="mb-1">
                      <FormattedMessage id="webiste.find.position.text" />
                    </Text>
                    <LocationSelector locations={values.locations} onLocationsChange={locations => setFieldValue('locations', locations)} />
                  </FormField>

                  <Box align="center" direction="row-responsive" alignContent="between" className="pt-2">
                    {values.type.type === 'residential' && values.type.id !== 'house' && (
                      <FormField className="ml-2 mr-2" label={<FormLabel label="admin.property.rooms.room"></FormLabel>} htmlFor="bed">
                        <Box align="center" direction="row" alignContent="center">
                          <ReactSVG className="mr-2 ml-2" src={`/website/rooms/bed.svg`} />
                          <TextInput
                            type="number"
                            name="bed"
                            onChange={e => setFieldValue('rooms.bed', e.target.value)}
                            value={values.rooms.bed}
                          ></TextInput>
                        </Box>
                      </FormField>
                    )}
                  </Box>
                  <Box align="center" direction="row" alignContent="center">
                    <FormField
                      label={<FormattedMessage id="admin.search.budget" values={{ type: values.type.name[intl.locale] }} />}
                      htmlFor="budget"
                      error={errors.budget}
                    >
                      <CurrencyInput name="budget" onChange={value => setFieldValue('budget', value)} value={values.budget}></CurrencyInput>
                    </FormField>
                  </Box>
                  <div className="d-flex justify-content-end flex-wrap">
                    {/* <Button
                      className="m-2"
                      onClick={() => this.setState({ isBudgetHelp: true })}
                      label={intl.formatMessage({ id: 'website.find.help' })}
                    ></Button> */}

                    <Button
                      className="m-2"
                      type="submit"
                      primary
                      disabled={isSubmitting}
                      label={intl.formatMessage({ id: 'global.actions.save' })}
                    ></Button>
                  </div>
                  {isBudgetHelp && (
                    <BudgetDialog onClose={() => this.setState({ isBudgetHelp: false })} onSave={value => setFieldValue('budget', value)} />
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    )
  }
}

export default injectIntl(SearchForm)
