import React, { Component } from 'react'
import { Button, Heading, Box, Form, FormField, Select } from 'grommet'
import { injectIntl } from 'react-intl'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Formik } from 'formik'

import { validateEmail } from 'utils/validations'
import { authFetch } from 'utils/website-utils'
import TextInput from './TextInput'

/**
 * this component enables the user to change his personal details apart from the email, which is fixed,
 * because it may be associated with multiple models (webuser, seller, buyer)
 *
 * @class EditUserDialog
 */
class EditUserDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      show: props.isFixed
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(values) {
    const { me, onSave } = this.props
    if (values && values.sellerType === 'private') {
      delete values.pec
    }
    const response = await authFetch('patch', `/api/v1/account/me`, { me, values }).then(res => res.data)

    await onSave(response)
    this.setState({ show: false })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isFixed && this.props.isFixed) this.setState({ show: true })
  }

  onValidate = values => {
    const { intl } = this.props
    let errors = {}
    if (!validateEmail(values.email)) errors.email = intl.formatMessage({ id: 'global.errors.email' })

    if (values.sellerType === 'construction') {
      if ((!values.name || !values.familyName) && !values.companyName) {
        if (!values.name) errors.name = intl.formatMessage({ id: 'global.errors.required' })
        if (!values.familyName) errors.familyName = intl.formatMessage({ id: 'global.errors.required' })
        if (!values.companyName) errors.companyName = intl.formatMessage({ id: 'global.errors.required' })
      }
      return errors
    }

    if (!values.name) errors.name = intl.formatMessage({ id: 'global.errors.required' })
    if (!values.familyName) errors.familyName = intl.formatMessage({ id: 'global.errors.required' })

    return errors
  }

  render() {
    const { show } = this.state
    const { intl, me, isFixed } = this.props
    const user = me.seller || me.client
    const isClient = !!me.client && !me.seller

    // TODO: Move these to util
    const sellerTypeOptions = [
      { name: intl.formatMessage({ id: 'global.properties.sellerType.private' }), value: 'private' },
      { name: intl.formatMessage({ id: 'global.properties.sellerType.agent' }), value: 'agent' },
      { name: intl.formatMessage({ id: 'global.properties.sellerType.construction' }), value: 'construction' }
    ]
    const salutationOptions = [
      { name: intl.formatMessage({ id: 'global.properties.salutation.m' }), value: 'Signor' },
      { name: intl.formatMessage({ id: 'global.properties.salutation.f' }), value: 'Signora' }
    ]
    const languageOptions = [
      { name: intl.formatMessage({ id: 'global.properties.language.it' }), value: 'it' },
      { name: intl.formatMessage({ id: 'global.properties.language.de' }), value: 'de' },
      { name: intl.formatMessage({ id: 'global.properties.language.en' }), value: 'en' }
    ]
    return (
      <Box>
        <Button icon={<FontAwesomeIcon icon={faEdit} />} onClick={() => this.setState({ show: true })} />

        <Formik
          initialValues={{
            sellerType: user ? user.sellerType : 'private',
            email: user ? user.email : me.email,
            salutation: user ? user.salutation : 'Signor',
            name: user ? user.name : '',
            familyName: user ? user.familyName : '',
            companyName: user ? user.companyName : '',
            tel: user ? user.tel : '',
            language: user ? user.language : 'it',
            location: user && user.location && user.location.address ? user.location : null,
            pec: (user && user.pec) || '',
            taxCode: (user && user.taxCode) || ''
          }}
          validate={this.onValidate}
          onSubmit={this.onSubmit}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
            <Modal
              show={show}
              onHide={isFixed ? () => {} : () => this.setState({ show: false })}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              className="ui-select-seller-dialog"
              centered
            >
              <Modal.Header closeButton>
                <Heading level={3}>{user ? `${user.name} ${user.familyName}` : intl.formatMessage({ id: 'website.sell.new.step0.title' })}</Heading>
              </Modal.Header>
              <Modal.Body className="pl-4 pr-4" style={{ maxHeight: '70vh', overflow: 'auto' }}>
                <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column">
                  <FormField label="Email" htmlFor="email" error={errors.email}>
                    <TextInput type="email" name="email" disabled onChange={handleChange} value={values.email}></TextInput>
                  </FormField>
                  {!isClient && (
                    <FormField label={intl.formatMessage({ id: 'global.properties.sellerType' })} htmlFor="sellerType">
                      <Select
                        id="sellerType"
                        size="small"
                        name="sellerType"
                        valueLabel={<Box pad="small">{intl.formatMessage({ id: `global.properties.sellerType.${values.sellerType}` })}</Box>}
                        options={sellerTypeOptions}
                        onChange={({ option }) => setFieldValue('sellerType', option.value)}
                      >
                        {option => <Box pad="small">{option.name}</Box>}
                      </Select>
                    </FormField>
                  )}
                  <FormField label={intl.formatMessage({ id: 'global.properties.salutation' })} htmlFor="salutation">
                    <Select
                      id="salutation"
                      size="small"
                      name="salutation"
                      valueLabel={
                        <Box pad="small">
                          {intl.formatMessage({ id: `global.properties.salutation.${values.salutation === 'Signor' ? 'm' : 'f'}` })}
                        </Box>
                      }
                      options={salutationOptions}
                      onChange={({ option }) => setFieldValue('salutation', option.value)}
                    >
                      {option => <Box pad="small">{option.name}</Box>}
                    </Select>
                  </FormField>
                  <FormField label={intl.formatMessage({ id: 'global.properties.name' }) + ' *'} htmlFor="name" error={errors.name}>
                    <TextInput type="text" name="name" onChange={handleChange} value={values.name}></TextInput>
                  </FormField>
                  <FormField label={intl.formatMessage({ id: 'global.properties.familyName' }) + ' *'} htmlFor="familyName" error={errors.familyName}>
                    <TextInput type="text" name="familyName" onChange={handleChange} value={values.familyName}></TextInput>
                  </FormField>
                  {values.sellerType === 'construction' && (
                    <FormField label={intl.formatMessage({ id: 'global.properties.companyName' })} htmlFor="companyName" error={errors.companyName}>
                      <TextInput type="text" name="companyName" onChange={handleChange} value={values.companyName}></TextInput>
                    </FormField>
                  )}
                  <FormField label={intl.formatMessage({ id: 'global.properties.tel' })} htmlFor="tel">
                    <TextInput type="tel" name="tel" onChange={handleChange} value={values.tel}></TextInput>
                  </FormField>
                  <FormField label={intl.formatMessage({ id: 'global.properties.language' })} htmlFor="language">
                    <Select
                      id="language"
                      size="small"
                      name="language"
                      valueLabel={<Box pad="small">{intl.formatMessage({ id: `global.properties.language.${values.language}` })}</Box>}
                      options={languageOptions}
                      onChange={({ option }) => setFieldValue('language', option.value)}
                    >
                      {option => <Box pad="small">{option.name}</Box>}
                    </Select>
                  </FormField>
                  {!isClient && values.sellerType !== 'private' && (
                    <FormField label="PEC" htmlFor="pec">
                      <TextInput type="text" name="pec" onChange={handleChange} value={values.pec}></TextInput>
                    </FormField>
                  )}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  style={{ maxWidth: '150px', marginLeft: 'auto', marginTop: '16px' }}
                  primary
                  onClick={handleSubmit}
                  label={intl.formatMessage({ id: 'global.actions.save' })}
                ></Button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </Box>
    )
  }
}

export default injectIntl(EditUserDialog)
