import React, { Component, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import 'style/main.scss'
import * as Sentry from '@sentry/browser'

// redux
import { Provider } from 'react-redux'
import { setLanguage } from 'store/actions/language'
import { setWebUser, logoutWebUser } from 'store/actions/authentication-web'
import store from 'store/index'
import setAuthToken from './setAuthToken'
import ConnectedIntlProvider from 'containers/hocs/ConnectedIntlProvider'

// theme
import { Grommet } from 'grommet'
import { theme, getClientLanguage } from './utils/website-utils'

// components
import ScrollToTop from 'components/ScrollToTop'
import Axios from 'axios'

// routes
import Website from './containers/Website'
const Admin = lazy(() => import('./containers/Admin'))

// setup sentry in production
if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://752e82b1e54e413ab01d66ebd36166a6@sentry.io/1474159' })
}

let lang
// langauge is saved in localstorage and retrieved on reload
if (localStorage.language) {
  lang = localStorage.getItem('language')
} else {
  lang = getClientLanguage()
}
store.dispatch(setLanguage(lang))

if (window && window.localStorage && localStorage.jwtWebToken) {
  const decoded = jwtDecode(localStorage.jwtWebToken)

  const currentTime = new Date() / 1000
  if (decoded.exp < currentTime) {
    store.dispatch(logoutWebUser())
    window.location.href = '/'
  }

  if (decoded.isAdmin) {
    setAuthToken(localStorage.jwtWebToken)
  } else {
    setAuthToken(false)
  }

  store.dispatch(setWebUser(decoded))
}

Axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      store.dispatch(logoutWebUser())
      window.location.href = '/'
    }
    return error
  }
)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedIntlProvider>
          <Router>
            <ScrollToTop>
              <Suspense fallback={<></>}>
                <Switch>
                  <Grommet theme={theme}>
                    <Route path="/admin" render={(props) => <Admin {...props} />} />
                    <div className="website">
                      <Route path="/" render={(props) => <Website {...props} />} />
                    </div>
                  </Grommet>
                </Switch>
              </Suspense>
            </ScrollToTop>
          </Router>
        </ConnectedIntlProvider>
      </Provider>
    )
  }
}

export default App
