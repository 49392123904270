import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Axios from 'axios'

import { Card, Form } from 'react-bootstrap'
import { RadioButtonGroup, TextInput, Box, Select, Text, Button, DropButton } from 'grommet'
import { injectIntl, FormattedNumber, FormattedMessage } from 'react-intl'
import { logError } from 'utils/sentry-log'

class SearchBox extends Component {
  // inside the constructor the options of the url-search-params are set
  constructor(props) {
    super(props)
    this.state = {
      locationSuggestions: [],
      typeOptions: [],
      type: { name: props.intl.formatMessage({ id: 'global.all' }), value: 'ALL' }
    }

    // use try, catch to catch possible errors if search params are not valid
    try {
      const searchParams = new URLSearchParams(props.location.search)
      // set location
      let location = null
      if (searchParams.get('locationId')) {
        // municipality locId is the name so the is is not set
        if (searchParams.get('locationType') === 'municipality') {
          location = { name: searchParams.get('locationId'), type: searchParams.get('locationType') }
        } else if (searchParams.get('locationName')) {
          // zone has additional name param to set input value
          location = { name: searchParams.get('locationName'), _id: searchParams.get('locationId'), type: searchParams.get('locationType') }
        }
      }
      this.state['location'] = location
      this.state['inputValue'] = location && location.name ? location.name : ''
      // set rent radiobutton
      this.state['buyRent'] = props.intl.formatMessage({ id: `website.${searchParams.get('rent') ? 'rent' : 'buy'}` })

      // set budget
      this.state['budget'] = searchParams.get('budget')
        ? searchParams
            .get('budget')
            .split(',')
            .map(str => (str === 'null' ? str : Number(str)))
        : [0, 'null']

      // set numOfRooms
      this.state['numOfRooms'] = searchParams.get('numOfRooms') ? searchParams.get('numOfRooms') : { name: '+1', value: 1 }
    } catch (e) {
      // TODO: search params invalid page?
      logError(e)
    }

    this.onChangeInput = this.onChangeInput.bind(this)
  }

  /**
   * fetches the suggestions on input change
   */
  async onChangeInput(event) {
    const inputValue = event.target.value
    this.setState({ inputValue })
    const locationSuggestions = await Axios.get(`/api/v1/locations?q=${inputValue}&lang=${this.props.intl.locale}`).then(result =>
      result.data ? result.data : []
    )
    this.setState({ locationSuggestions })
  }

  onSelectLocation = event => {
    const location = this.state.locationSuggestions.find(sug => sug._id === event.suggestion.value)
    this.setState({ location, inputValue: location.name, locationSuggestions: [] })
  }

  componentDidMount() {
    Axios.get('/api/v1/types?sort=-type')
      .then(result => {
        const typeOptions = result.data
        this.setState({ typeOptions })

        // preselect type if it is specified in search params
        const searchParams = new URLSearchParams(this.props.location.search)
        if (searchParams.get('type')) {
          const selectedType = typeOptions.find(option => option._id === searchParams.get('type'))
          if (selectedType) {
            const type = { name: selectedType['name'][this.props.intl.locale], value: selectedType._id, type: selectedType.type }
            this.setState({ type })
          }
        }
      })
      .catch(e => logError(e))
  }

  /**
   * sets the default option to the current locale
   * on language switch
   */
  componentDidUpdate(nextProps) {
    if (this.props.intl.locale !== nextProps.intl.locale && this.state.type.value === 'ALL') {
      this.setState({ type: { name: this.props.intl.formatMessage({ id: 'global.all' }), value: 'ALL' } })
    }
  }

  onSubmit = () => {
    const { location, buyRent, budget, type, numOfRooms, inputValue } = this.state
    const { intl } = this.props
    const buyRentOptions = [intl.formatMessage({ id: 'website.rent' }), intl.formatMessage({ id: 'website.buy' })]
    const rent = buyRentOptions.indexOf(buyRent) === 0 ? true : false
    const searchParams = new URLSearchParams()

    rent && searchParams.append('rent', rent)
    if (location && inputValue) {
      searchParams.append('locationId', location.type === 'municipality' ? location.name : location._id)
      searchParams.append('locationType', location.type)
      // pass location name if zone to show inside input
      location.type === 'zone' && searchParams.append('locationName', location.name)
    }
    type.value !== 'ALL' && searchParams.append('type', type.value)
    type.value !== 'ALL' && searchParams.append('propertyType', type.type ? type.type : null)

    if (numOfRooms && numOfRooms.value > 1) {
      searchParams.append('numOfRooms', numOfRooms.value.toString())
    }

    if (budget[0] !== 0 || budget[1] !== 1000000) {
      searchParams.append('budget', budget.toString())
    }

    const urlParams = searchParams.toString()
    // redirect to search with params
    this.props.history.push(`/search?${urlParams}`)
  }

  render() {
    const { intl, hasTitle } = this.props
    const { budget, numOfRooms, buyRent, inputValue, locationSuggestions, typeOptions, type } = this.state

    // set buyRentOptions dynamically in case the locale changes
    const buyRentOptions = [intl.formatMessage({ id: 'website.rent' }), intl.formatMessage({ id: 'website.buy' })]

    // suggiestions displayed under the input
    const suggestions = locationSuggestions.map(suggestion => {
      const type = intl.formatMessage({ id: `website.${suggestion.type}` })
      return {
        label: (
          <Box margin="xsmall">
            {suggestion.name}
            <Text size="xsmall"> {type}</Text>
          </Box>
        ),
        value: suggestion._id
      }
    })

    const priceLabel = `${intl.formatNumber(budget[0], { format: 'EUR' })} - ${
      budget[1] === 'null' ? '+1.000.000 €' : intl.formatNumber(budget[1], { format: 'EUR' })
    }`

    const typeNames = [{ name: intl.formatMessage({ id: 'global.all' }), value: 'ALL' }].concat(
      (typeOptions || []).map(type => {
        return { name: type['name'][intl.locale], value: type._id, type: type.type }
      })
    )

    const numOfRoomsOptions = [
      { name: '+1', value: 1 },
      { name: '+2', value: 2 },
      { name: '+3', value: 3 },
      { name: '+4', value: 4 },
      { name: '+5', value: 5 },
      { name: '+6', value: 6 }
    ]

    const priceOptions = [
      { value: 0 },
      { value: 100000 },
      { value: 200000 },
      { value: 300000 },
      { value: 400000 },
      { value: 500000 },
      { value: 600000 },
      { value: 700000 },
      { value: 800000 },
      { value: 900000 },
      { value: 1000000 },
      { value: 'null' }
    ]

    return (
      <Card className="ui-searchbox w-100">
        <Card.Body>
          {hasTitle && (
            <div className="pb-3 pt-3 text-uppercase text-dark text-center">
              <Text size="xxlarge" color="#ffc107" weight="bold">
                PROFI{' '}
              </Text>
              <Text size="xxlarge" color="#ffc107" weight="normal">
                IMMOBILIEN
              </Text>
            </div>
          )}
          <Form>
            <Form.Row className="p-2 d-flex justify-content-center">
              <RadioButtonGroup
                name="doc"
                direction="row"
                options={buyRentOptions}
                value={buyRent}
                onChange={event => this.setState({ buyRent: event.target.value })}
              />
            </Form.Row>
            <Form.Row>
              <div className="col p-2">
                <label htmlFor="location" className="m-0">
                  <Text size="xsmall" pad="small" color="dark-2">
                    <FormattedMessage id="website.property.location" />
                  </Text>
                </label>
                <TextInput
                  id="location"
                  placeholder={intl.formatMessage({ id: 'website.search.example' })}
                  value={inputValue}
                  onChange={this.onChangeInput}
                  onSelect={this.onSelectLocation}
                  suggestions={suggestions}
                />
              </div>
              <div className="col-lg-2 col-md-2 col-xs-6 p-2 pt-4 justify-content-center align-items-center d-none d-md-flex ">
                <Button
                  hoverIndicator
                  primary
                  alignSelf="center"
                  label={intl.formatMessage({ id: 'global.actions.search' })}
                  onClick={this.onSubmit}
                />
              </div>
            </Form.Row>
            <Form.Row className="d-flex justify-content-between">
              <div className="d-flex col-lg-3 col-md-6 col-xs-6  p-2">
                <Box className="w-100">
                  <label htmlFor="select" className="m-0">
                    <Text size="xsmall" pad="small" color="dark-2">
                      <FormattedMessage id="admin.property.type" />
                    </Text>
                  </label>
                  <Select
                    id="select"
                    size="small"
                    name="select"
                    valueLabel={<Box pad="small">{type.name}</Box>}
                    options={typeNames}
                    onChange={({ option }) => this.setState({ type: option })}
                  >
                    {option => <Box pad="small">{option.name}</Box>}
                  </Select>
                </Box>
              </div>
              <div className="col-lg-3 col-md-6 col-xs-6  p-2">
                <Box className="w-100">
                  <label htmlFor="numOfRooms" className="m-0">
                    <Text size="xsmall" pad="small" color="dark-2">
                      <FormattedMessage id="admin.property.numOfRooms" />
                    </Text>
                  </label>
                  <Select
                    id="numOfRooms"
                    size="small"
                    name="numOfRooms"
                    placeholder={intl.formatMessage({ id: 'website.search.select' })}
                    valueLabel={<Box pad="small">{numOfRooms ? numOfRooms.name : ''}</Box>}
                    options={numOfRoomsOptions}
                    onChange={({ option }) => this.setState({ numOfRooms: option })}
                  >
                    {option => <Box pad="small">{option.name}</Box>}
                  </Select>
                </Box>
              </div>
              <div className="col-lg-6 col-md-12 col-xs-12  p-2">
                <Box className="w-100">
                  <Text size="xsmall" pad="small" color="dark-2">
                    <FormattedMessage id="admin.property.price" />
                  </Text>
                  <Box className="pt-1 pb-1">
                    <DropButton
                      alignSelf="stretch"
                      label={priceLabel}
                      dropAlign={{ top: 'bottom', right: 'right' }}
                      dropContent={
                        <Box pad="xsmall" background="light-2">
                          <Select
                            id="budget0"
                            size="small"
                            name="budget0"
                            margin="small"
                            valueLabel={<Box pad="small">{intl.formatNumber(budget[0], { format: 'EUR' })}</Box>}
                            options={priceOptions.filter(price => price.value !== 'null')}
                            onChange={({ option }) => this.setState({ budget: [option.value, budget[1]] })}
                          >
                            {option => (
                              <Box pad="small">
                                <FormattedNumber value={option.value} format="EUR"></FormattedNumber>
                              </Box>
                            )}
                          </Select>
                          <Select
                            id="budget1"
                            size="small"
                            name="budget1"
                            margin="small"
                            valueLabel={
                              <Box pad="small">{budget[1] === 'null' ? '+1.000.000 €' : intl.formatNumber(budget[1], { format: 'EUR' })}</Box>
                            }
                            options={priceOptions}
                            onChange={({ option }) => this.setState({ budget: [budget[0], option.value] })}
                          >
                            {option => (
                              <Box pad="small">
                                {option.value === 'null' ? '+ 1.000.000 €' : <FormattedNumber value={option.value} format="EUR"></FormattedNumber>}
                              </Box>
                            )}
                          </Select>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </div>
              <div className="col p-2 justify-content-center align-items-center d-md-none d-flex ">
                <Button hoverIndicator alignSelf="end" label={intl.formatMessage({ id: 'global.actions.search' })} onClick={this.onSubmit} />
              </div>
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
    )
  }
}

export default injectIntl(withRouter(SearchBox))
