import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import ImageLoader from './ImageLoader'

class SlideShowModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: props.start || 0
    }
  }

  changeImg = index => {
    this.setState({ index })
  }

  render() {
    const { isOpen, images } = this.props
    const { index } = this.state

    const imgs = images.map((img, idx) => {
      if (/jpg/.test(img)) {
        return (
          <ImageLoader
            key={img + idx}
            className={`mr-2 ${index === idx ? 'active' : ''}`}
            width="100px"
            height="60px"
            src={img}
            fit="cover"
            onClick={() => this.changeImg(idx)}
          />
        )
      } else {
        return <div key={img + idx}></div>
      }
    })

    const translate = (index - 1) * 108

    return isOpen ? (
      <Modal
        show={true}
        onHide={() => this.props.onClose()}
        size="xl"
        dialogClassName="ui-slideshow-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center align-items-center">
            <Col sm={12} md={8} className="justify-content-center align-items-center">
              <div className="d-flex flex-column">
                <ImageLoader className="w-100 ui-slideshow-modal__preview" src={images[index]} fit="contain" />
                <div className="slider-wrapper">
                  <div className="d-flex pt-4 slider justify-content-center" style={{ transform: `translateX(-${translate}px)` }}>
                    {imgs}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    ) : (
      <></>
    )
  }
}

export default SlideShowModal
