import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { oneOf } from './utils'

// fields of properties table
export const propertiesFields = (type) => {
  return [
    { value: 'property_id', title: 'ID' },
    { value: 'type.name.it', title: 'Tipologia' },
    { value: 'location.address.street.it', title: 'Via' },
    { value: 'location.address.municipality.name.it', title: 'Comune' },
    { value: type === 'buildingland' ? 'catastralArea' : 'area', title: 'Superficie', intlKey: 'format.area', intlValue: 'area', align: 'right' },
    { value: 'price', title: 'Prezzo', intlFormatNumber: 'EUR', align: 'right' },
    { value: 'isConfirmed', title: 'Confermato', icons: { true: faCheck, false: faTimes }, align: 'center' }
  ]
}

// fields of detail property
export const propertyFields = (type, exactType) => {
  if (type === 'residential') {
    return {
      list: [
        { value: 'rent', title: 'Contratto', intlKey: 'admin.property.rent' },
        { value: 'type.name.it', title: 'Tipologia' },
        { value: 'price', title: 'Prezzo', intlFormatNumber: 'EUR' },
        { value: 'area', title: 'Superficie di vendita', intlKey: 'format.area', intlValue: 'area' },
        exactType !== 'house' && { value: 'nettoArea', title: 'Superficie netta', intlKey: 'format.area', intlValue: 'area' },
        exactType !== 'villa' && { value: 'numOfUnits', title: 'Nr. di unità immobiliari' },
        exactType === 'townhouse' && {
          value: 'townhousePosition',
          title: 'Posizione Villetta a schiera',
          intlKey: 'admin.property.townhousePosition'
        },
        {
          value: 'minFloor',
          title: exactType === 'appartment' ? 'Piani' : 'Piani sotto terra'
        },
        {
          value: 'maxFloor',
          title: exactType === 'appartment' ? 'di (piani)' : 'Piani fuori terra'
        }
      ],
      table: [
        { value: 'orientation', title: 'Orientamento', array: true },
        { value: 'condition', title: 'Stato', intlKey: 'admin.property.condition' },
        { value: 'energyClass', title: 'Consumo energetico' },
        { value: 'constructionYear', title: 'Anno di costruzione' },
        exactType !== 'house' && { value: 'furnishing', title: 'Arredamento', intlKey: 'admin.property.furnishing' },
        exactType !== 'house' && { value: 'heating', title: 'Riscaldamento', intlKey: 'admin.property.heating' },
        exactType !== 'house' && { value: 'heatingType', title: 'Tipo di Riscaldamento', intlKey: 'admin.property.heatingType' },
        { value: 'lift', title: 'Ascensore', intlKey: 'admin.property' },
        { value: 'conventionated', title: 'Convenzionato', intlKey: 'admin.property' },
        { value: 'fees', title: 'Spese (annuali)', intlFormatNumber: 'EUR' }
      ],
      rooms: [
        { value: 'rooms.bed', title: 'Camere da letto' },
        { value: 'rooms.living', title: 'Soggiorni' },
        { value: 'rooms.dining', title: 'Sala Pranzo' },
        { value: 'rooms.kitchen', title: 'Cucine Separate' },
        { value: 'rooms.storage', title: 'Ripostigli' },
        { value: 'rooms.wc', title: 'WC' },
        { value: 'rooms.bath', title: 'Bagni' },
        { value: 'rooms.closet', title: 'Vani Armadio' }
      ],
      areas: [
        { value: 'areas.cellar', title: 'Cantina' },
        { value: 'areas.hobby', title: 'Stanze Hobby' },
        { value: 'areas.underroof', title: 'Sottotetto' },
        { value: 'areas.wintergarden', title: 'Wintergarten' },
        { value: 'areas.terrace', title: 'Terrazzo' },
        { value: 'areas.balcony', title: 'Balcone' },
        { value: 'areas.coveredTerrace', title: 'Terrazzo coperto' }
      ]
    }
  } else if (type === 'garage') {
    return {
      list: [
        { value: 'rent', title: 'Contratto', intlKey: 'admin.property.rent' },
        { value: 'type.name.it', title: 'Tipologia' },
        { value: 'numOfPlaces', title: 'Numero di posti auto' },
        { value: 'area', title: 'Superficie di vendita', intlKey: 'format.area', intlValue: 'area' },
        { value: 'price', title: 'Prezzo', intlFormatNumber: 'EUR' }
      ],
      table: [
        { value: 'condition', title: 'Stato', intlKey: 'admin.property.condition' },
        { value: 'constructionYear', title: 'Anno di costruzione' },
        { value: 'fees', title: 'Spese (annuali)', intlFormatNumber: 'EUR' },
        { value: 'runningWater', title: 'Acqua', intlKey: 'admin.property' },
        { value: 'power', title: 'Corrente', intlKey: 'admin.property' },
        { value: 'light', title: 'Luce', intlKey: 'admin.property' }
      ]
    }
  } else if (type === 'commercial') {
    return {
      list: [
        { value: 'rent', title: 'Contratto', intlKey: 'admin.property.rent' },
        { value: 'type.name.it', title: 'Tipologia' },
        {
          value: 'area',
          title:
            exactType === 'industry' ? 'Superfici lorde fuori terra' : exactType === 'warehouse' ? 'Superficie netta interna' : 'Superficie netta',
          intlKey: 'format.area',
          intlValue: 'area'
        },
        exactType === 'industry' || exactType === 'warehouse'
          ? {
              value: 'exteriorArea',
              title: exactType === 'industry' ? 'Superfici lorde sotto terra' : 'Superficie netta esterna',
              intlKey: 'format.area',
              intlValue: 'area'
            }
          : null,
        { value: 'price', title: 'Prezzo', intlFormatNumber: 'EUR' },
        { value: 'maintenenceCost', title: 'Spese annue di manutenzione', intlFormatNumber: 'EUR' },
        { value: 'floor', title: exactType === 'hotel' || exactType === 'industry' ? 'Piani' : 'Piano' }
      ],
      table: [
        exactType !== 'hotel' && exactType !== 'warehouse' && exactType !== 'industry'
          ? { value: 'lift', title: 'Ascensore', intlKey: 'admin.property' }
          : null,
        exactType === 'workshop' || exactType === 'warehouse' ? { value: 'reachableTruck', title: 'Raggiungibile in camion' } : null,
        exactType !== 'hotel' && exactType !== 'workshop' && exactType !== 'industry' && exactType !== 'warehouse'
          ? { value: 'orientation', title: 'Orientamento', array: true }
          : null,
        { value: 'condition', title: 'Stato', intlKey: 'admin.property.condition' },
        { value: 'energyClass', title: 'Consumo energetico' },
        { value: 'constructionYear', title: 'Anno di costruzione' },
        { value: 'furnishing', title: 'Arredamento', intlKey: 'admin.property.furnishing' },
        exactType === 'hotel' ? { value: 'hotel.stars', title: 'Stelle' } : null,
        exactType === 'hotel' ? { value: 'hotel.spa', title: 'SPA' } : null,
        exactType === 'hotel' ? { value: 'hotel.conferenceRooms', title: 'Sala Conferenze' } : null
      ],
      rooms: [
        { value: 'rooms.wc', title: 'WC' },
        { value: 'rooms.bath', title: 'Bagni' },
        { value: 'rooms.kitchen', title: 'Cucine Separate' },
        { value: 'rooms.seats', title: 'Posti da sedere interni' },
        { value: 'rooms.externalSeats', title: 'Posti da sedere esterni' },
        { value: 'hotel.barPlaces', title: 'Posti Bar' },
        { value: 'hotel.restaurantPlaces', title: 'Posti Ristorante' },
        { value: 'hotel.sleepingPlaces', title: 'Posti letto' }
      ],
      areas: [{ value: 'areas.cellar', title: 'Cantina' }]
    }
  } else if (type === 'buildingland') {
    return {
      list: [
        { value: 'type.name.it', title: 'Tipologia' },
        { value: 'catastralArea', title: 'Superficie catastrale lotto', intlKey: 'format.area', intlValue: 'area' },
        { value: 'cubic', title: 'Cubatura massima', intlKey: 'format.cubic', intlValue: 'cubic' },
        exactType === 'industrialbuildingland'
          ? { value: 'grossArea', title: 'Somma massima superfici lorde', intlKey: 'format.cubic', intlValue: 'cubic' }
          : null,
        { value: 'price', title: 'Prezzo', intlFormatNumber: 'EUR' }
      ],
      table: [
        { value: 'landUseZone', title: 'Zona piano urbanistico communale' },
        { value: 'cubicIndex', title: 'Indice di cubatura', intlKey: 'format.cubicIndex', intlValue: 'cubic' },
        exactType !== 'residentialbuildingland' && {
          value: 'surfaceIndex',
          title: 'Indice di superfici lorde',
          intlKey: 'format.areaIndex',
          intlValue: 'area'
        },
        { value: 'maxHeight', title: 'Altezza massima edificio', intlKey: 'format.meter', intlValue: 'meter' },
        { value: 'maxGroundCover', title: 'Superficie coperta massima', intlKey: 'format.percent', intlValue: 'percent' },
        { value: 'maxSoilSeal', title: 'Superficie impermeabile massima', intlKey: 'format.percent', intlValue: 'percent' },
        exactType === 'hotelbuildingland' ? { value: 'maxBeds', title: 'Numero massimo di letti' } : null
      ]
    }
  } else if (type === 'agriculture') {
    return {
      list: [
        { value: 'type.name.it', title: 'Tipologia' },
        { value: 'area', title: 'Superficie terreno', intlKey: 'format.area', intlValue: 'area' },
        { value: 'price', title: 'Prezzo', intlFormatNumber: 'EUR' }
      ],
      table: [
        { value: 'fountains', title: 'Fontane' },
        { value: 'sprinklers', title: 'Irrigazione', intlKey: 'admin.property' },
        { value: 'hailnets', title: 'Antigrandine', intlKey: 'admin.property' }
      ]
    }
  }
}

export function ObjectFieldTemplate({ properties, title, description, idSchema }) {
  return (
    <div className="ui-form-object  d-flex flex-column" id={idSchema.$id}>
      <div className="ui-form-object__title d-flex flex-column">{description === 'nested' ? <h4>{title}</h4> : <h5>{title}</h5>}</div>
      <div className={`ui-form-object__content ${description === 'nested' ? 'd-flex flex-wrap' : ''}`}>
        {properties.map((prop) => (
          <div className={`ui-form-object__property ${description === 'nested' ? 'd-flex w-md-25 w-50 pr-1 pl-1' : ''}`} key={prop.content.key}>
            {prop.content}
          </div>
        ))}
      </div>
    </div>
  )
}

export const uiSchema = {
  residential: {
    rent: {
      'ui:widget': 'select'
    },
    description: {
      it: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } },
      de: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } },
      en: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } }
    },
    parking: {
      bike: { 'ui:widget': 'select' }
    },
    area: { 'ui:widget': 'hidden' },
    informations: { 'ui:widget': 'textarea', 'ui:options': { rows: 5 } },
    cataster: { 'ui:widget': 'textarea' }
  },
  garage: {
    description: {
      it: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } },
      de: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } },
      en: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } }
    },
    rent: { 'ui:widget': 'select' },
    power: { 'ui:widget': 'select' },
    light: { 'ui:widget': 'select' },
    runningWater: { 'ui:widget': 'select' },
    informations: { 'ui:widget': 'textarea', 'ui:options': { rows: 5 } },
    cataster: { 'ui:widget': 'textarea' }
  },
  commercial: {
    description: {
      it: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } },
      de: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } },
      en: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } }
    },
    rent: { 'ui:widget': 'select' },
    reachableTruck: { 'ui:widget': 'select' },
    lift: { 'ui:widget': 'select' },
    informations: { 'ui:widget': 'textarea', 'ui:options': { rows: 5 } },
    cataster: { 'ui:widget': 'textarea' }
  },
  buildingland: {
    description: {
      it: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } },
      de: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } },
      en: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } }
    },
    informations: { 'ui:widget': 'textarea', 'ui:options': { rows: 5 } },
    cataster: { 'ui:widget': 'textarea' }
  },
  agriculture: {
    description: {
      it: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } },
      de: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } },
      en: { 'ui:widget': 'textarea', 'ui:options': { rows: 10 } }
    },
    rent: { 'ui:widget': 'select' },
    sprinklers: { 'ui:widget': 'select' },
    hailnets: { 'ui:widget': 'select' },
    informations: { 'ui:widget': 'textarea', 'ui:options': { rows: 5 } },
    cataster: { 'ui:widget': 'textarea' }
  }
}

// property add and edit schema
export const propertySchema = (type, exactType, types, isNew, rent) => {
  if (type === 'residential') {
    return {
      type: 'object',
      //TODO
      required: ['rent', 'price'],
      properties: {
        property_id: {
          type: 'integer',
          title: 'Id',
          default: null
        },
        title: {
          type: 'object',
          title: 'Titolo',
          properties: {
            de: {
              title: 'Tedesco',
              type: 'string'
            },
            it: {
              title: 'Italiano',
              type: 'string'
            },
            en: {
              title: 'Inglese',
              type: 'string'
            }
          }
        },
        description: {
          type: 'object',
          title: 'Descrizione',
          properties: {
            de: {
              title: 'Tedesco',
              type: 'string'
            },
            it: {
              title: 'Italiano',
              type: 'string'
            },
            en: {
              title: 'Inglese',
              type: 'string'
            }
          }
        },
        type: {
          type: 'string',
          title: 'Tipologia',
          enum: types ? types.map((type) => type._id) : [],
          enumNames: types ? types.map((type) => type.name.it) : []
        },
        rent: {
          type: 'boolean',
          title: 'Contratto',
          enumNames: ['Affitto', 'Vendita'],
          default: false
        },
        numOfUnits:
          exactType !== 'villa'
            ? {
                type: 'integer',
                title: 'Nr. di unità immobiliari',
                default: exactType === 'townhouse' ? 3 : 8
              }
            : {},
        townhousePosition:
          exactType === 'townhouse'
            ? {
                type: 'string', // center, outside
                title: 'Posizione Villetta a schiera',
                enumNames: ['esterno', 'centrale'],
                enum: ['outside', 'center'],
                default: 'outside'
              }
            : {},
        minFloor: {
          type: 'integer',
          title: exactType === 'appartment' ? 'Piani' : 'Piani sotto terra',
          default: exactType === 'appartment' ? 1 : -1
        },
        maxFloor: {
          type: 'integer',
          title: exactType === 'appartment' ? 'di (piani)' : 'Piani fuori terra',
          default: exactType === 'appartment' ? 3 : 1
        },
        orientation: {
          type: 'array',
          title: 'Orientamento',
          items: {
            enum: ['N', 'S', 'W', 'E', 'NE', 'NW', 'SE', 'SW'],
            enumNames: ['Nord', 'Sud', 'Ovest', 'Est', 'Nord-Est', 'Nord-Ovest', 'Sud-Est', 'Sud-Ovest'],
            default: 'S'
          }
        },
        condition: {
          type: 'integer',
          title: 'Stato',
          enum: [1, 2, 3, 4], //Perfekt, gut, bewohnbar, zu renovieren
          enumNames: ['Perfetto', 'Buono', 'Abitabile', 'Da rinnovare'],
          default: 1
        },
        energyClass: {
          type: 'string',
          title: 'Consumo energetico',
          enum: ['gold', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'undefined'],
          enumNames: ['Oro', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'In fase di definizione'],
          default: 'G'
        },
        constructionYear: {
          type: 'integer',
          title: 'Anno di costruzione',
          default: new Date().getFullYear()
        },
        rooms:
          exactType !== 'house'
            ? {
                type: 'object',
                title: 'Numero Vani',
                description: 'nested',
                properties: {
                  bed: {
                    type: 'integer',
                    title: 'Camere da letto',
                    default: 1
                  },
                  living: {
                    type: 'integer',
                    default: 1,
                    title: 'Soggiorni'
                  },
                  kitchen: {
                    type: 'integer',
                    default: 0,
                    title: 'Cucine separate'
                  },
                  dining: {
                    type: 'integer',
                    default: 0,
                    title: 'Sale pranzo'
                  },
                  bath: {
                    type: 'integer',
                    default: 1,
                    title: 'Bagni'
                  },
                  wc: {
                    type: 'integer',
                    default: 0,
                    title: 'WC'
                  },
                  closet: {
                    type: 'integer',
                    default: 0,
                    title: 'Vani armadio'
                  },
                  storage: {
                    type: 'integer',
                    default: 0,
                    title: 'Ripostigli'
                  }
                }
              }
            : {},
        nettoArea:
          exactType !== 'house'
            ? {
                type: 'integer',
                title: 'Superficie netta',
                default: 0
              }
            : {},
        areas:
          exactType !== 'house'
            ? {
                type: 'object',
                title: 'Superfici accessorie',
                description: 'nested',
                properties: {
                  cellar: {
                    type: 'object',
                    title: 'Cantine',
                    properties: {
                      amount: {
                        type: 'integer',
                        default: 0,
                        title: 'Numero'
                      },
                      area: {
                        type: 'integer',
                        default: 0,
                        title: 'Area'
                      }
                    }
                  },
                  underroof: {
                    type: 'object',
                    title: 'Sottotetti',
                    properties: {
                      amount: {
                        type: 'integer',
                        default: 0,
                        title: 'Numero'
                      },
                      area: {
                        type: 'integer',
                        default: 0,
                        title: 'Area'
                      }
                    }
                  },
                  hobby: {
                    type: 'object',
                    title: 'Stanze hobby',
                    properties: {
                      amount: {
                        type: 'integer',
                        default: 0,
                        title: 'Numero'
                      },
                      area: {
                        type: 'integer',
                        default: 0,
                        title: 'Area'
                      }
                    }
                  },
                  wintergarden: {
                    type: 'object',
                    title: 'Wintergarten',
                    properties: {
                      amount: {
                        type: 'integer',
                        default: 0,
                        title: 'Numero'
                      },
                      area: {
                        type: 'integer',
                        default: 0,
                        title: 'Area'
                      }
                    }
                  },
                  terrace: {
                    type: 'object',
                    title: 'Terrazzi',
                    properties: {
                      amount: {
                        type: 'integer',
                        default: 0,
                        title: 'Numero'
                      },
                      area: {
                        type: 'integer',
                        default: 0,
                        title: 'Area'
                      }
                    }
                  },
                  balcony: {
                    type: 'object',
                    title: 'Balconi',
                    properties: {
                      amount: {
                        type: 'integer',
                        default: 0,
                        title: 'Numero'
                      },
                      area: {
                        type: 'integer',
                        default: 0,
                        title: 'Area'
                      }
                    }
                  },
                  coveredTerrace: {
                    type: 'object',
                    title: 'Terrazzi Coperti',
                    properties: {
                      amount: {
                        type: 'integer',
                        default: 0,
                        title: 'Numero'
                      },
                      area: {
                        type: 'integer',
                        default: 0,
                        title: 'Area'
                      }
                    }
                  }
                }
              }
            : {},
        garden:
          exactType !== 'house'
            ? {
                type: 'object',
                title: 'Giardino',
                description: 'nested',
                properties: {
                  type: {
                    title: 'Tipo',
                    type: 'string',
                    enum: ['no', 'private', 'community'],
                    enumNames: ['Nessun Giardino', 'Giardino privato', 'Giardino Condominiale'],
                    default: 'no'
                  },
                  area: {
                    title: 'Area',
                    default: 0,
                    type: 'integer'
                  }
                }
              }
            : {},
        area:
          exactType !== 'house'
            ? {
                type: 'integer',
                title: 'Superficie di vendita',
                default: 0
              }
            : {},
        parking: {
          title: 'Parcheggi',
          description: 'nested',
          type: 'object',
          properties: {
            garage: {
              title: 'Garage',
              type: 'object',
              properties: {
                number: {
                  type: 'integer',
                  default: 0,
                  title: 'Numero'
                },
                price: {
                  type: 'integer',
                  title: 'Prezzo',
                  default: 0
                }
              }
            },
            parkingLot: {
              title: 'Parcheggi Privati',
              type: 'object',
              properties: {
                number: {
                  type: 'integer',
                  title: 'Numero',
                  default: 0
                },
                price: {
                  type: 'integer',
                  title: 'Prezzo',
                  default: 0
                }
              }
            },
            common: {
              title: 'Parcheggi Condominiali',
              type: 'boolean',
              enumNames: ['Si', 'No'],
              default: false
            },
            bike: {
              title: 'Posti bicicletta',
              type: 'boolean',
              enumNames: ['Si', 'No'],
              default: false
            }
          }
        },
        furnishing:
          exactType !== 'house'
            ? {
                type: 'string',
                title: 'Arredamento',
                enum: ['empty', 'part', 'full'],
                enumNames: ['Vuoto', 'Parzialmente Ammobiliato', 'Ammobiliato'],
                default: 'empty'
              }
            : {},
        heating:
          exactType !== 'house' && exactType !== 'villa'
            ? {
                type: 'string',
                title: 'Riscaldamento',
                enum: ['central', 'centralWithCal', 'autonom'],
                enumNames: ['Centrale', 'Centrale con Contacalorie', 'Autonomo'],
                default: 'central'
              }
            : {},
        heatingType: {
          type: 'string',
          title: 'Tipo di Riscaldamento',
          enum: ['gas', 'oil', 'district', 'pellets', 'renewable'],
          enumNames: ['Gas', 'Olio', 'Teleriscaldamento', 'Pellets', 'Energia rinnovabile'],
          default: 'gas'
        },
        price: {
          type: 'integer',
          title: 'Prezzo',
          default: 0
        },
        fees: {
          type: 'integer',
          title: 'Spese (annuali)',
          default: 0
        },
        house:
          exactType === 'house'
            ? {
                type: 'object',
                title: 'Informazioni Casa',
                properties: {
                  purpose: {
                    type: 'array',
                    title: 'Scopo attuale dell`immobile',
                    items: {
                      enum: ['residential', 'service', 'productive'],
                      enumNames: ['Residenziale', 'Servizio', 'Produttivo']
                    }
                  },
                  overGroundArea: {
                    title: 'Somma superfici lorde fuori terra',
                    type: 'number',
                    default: 0
                  },
                  underGroundArea: {
                    title: 'Somma superfici lorde sotto terra',
                    type: 'number',
                    default: 0
                  },
                  catastralArea: {
                    title: 'Superficie Catastrale lotto',
                    type: 'number',
                    default: 0
                  },
                  landUseZone: {
                    type: 'string',
                    default: '',
                    title: 'Zona piano urbanistico communale'
                  },
                  cubicIndex: {
                    type: 'number',
                    default: 0,
                    title: 'Indice di cubatura'
                  },
                  maxHeight: {
                    type: 'number',
                    default: 0,
                    title: 'Altezza massima edificio'
                  },
                  maxGroundCover: {
                    type: 'number',
                    default: 0,
                    title: 'Superficie copertura massima'
                  },
                  maxSoilSeal: {
                    type: 'number',
                    default: 0,
                    title: 'Superficie impermeabile massima'
                  }
                }
              }
            : {},
        administrator:
          exactType === 'appartment'
            ? {
                type: 'object',
                title: 'Amministratore',
                properties: {
                  name: {
                    type: 'string',
                    title: 'Nome',
                    default: null
                  },
                  tel: {
                    type: 'string',
                    title: 'Tel.',
                    default: null
                  },
                  email: {
                    type: 'string',
                    title: 'Email',
                    default: null
                  }
                }
              }
            : {},
        informations: {
          type: 'string',
          title: 'Informazioni Aggiuntive'
        },
        cataster: {
          type: 'string',
          title: 'Valori catastali e tavolari'
        },
        lift:
          exactType === 'appartment' || exactType === 'house'
            ? {
                type: 'boolean',
                title: 'Ascensore',
                default: false
              }
            : {},
        conventionated: {
          type: 'boolean',
          title: 'Convenzionato',
          default: false
        },
        video: {
          type: 'string',
          title: 'Link Video',
          default: null
        }
      }
    }
  } else if (type === 'garage') {
    return {
      type: 'object',
      //TODO
      required: [],
      properties: {
        property_id: {
          type: 'integer',
          title: 'Id',
          default: null
        },
        title: {
          type: 'object',
          title: 'Titolo',
          properties: {
            de: {
              title: 'Tedesco',
              type: 'string'
            },
            it: {
              title: 'Italiano',
              type: 'string'
            },
            en: {
              title: 'Inglese',
              type: 'string'
            }
          }
        },
        description: {
          type: 'object',
          title: 'Descrizione',
          properties: {
            de: {
              title: 'Tedesco',
              type: 'string'
            },
            it: {
              title: 'Italiano',
              type: 'string'
            },
            en: {
              title: 'Inglese',
              type: 'string'
            }
          }
        },
        type: {
          type: 'string',
          title: 'Tipologia',
          enum: types ? types.map((type) => type._id) : [],
          enumNames: types ? types.map((type) => type.name.it) : []
        },
        rent: {
          type: 'boolean',
          title: 'Affitto',
          enumNames: ['Affitto', 'Vendita'],
          default: false
        },
        numOfPlaces: {
          type: 'number',
          title: 'Posti Auto'
        },
        floor:
          exactType === 'garage'
            ? {
                type: 'number',
                default: -1,
                title: 'Piano'
              }
            : {},
        floors:
          exactType === 'garage'
            ? {
                type: 'number',
                default: -1,
                title: 'di Piani'
              }
            : {},
        area: {
          type: 'number',
          title: 'Superficie'
        },
        price: {
          type: 'number',
          title: 'Prezzo'
        },
        rentPrice: {
          type: 'number',
          title: 'Prezzo/Introito affitto'
        },
        condition: {
          type: 'number',
          title: 'Stato',
          enum: [1, 2, 3, 4], //Perfekt, gut, bewohnbar, zu renovieren
          enumNames: ['Perfetto', 'Buono', 'Abitabile', 'Da rinnovare']
        },
        constructionYear: {
          type: 'number',
          title: 'Anno di costruzione'
        },
        fees: {
          type: 'number',
          title: 'Spese (annuali)'
        },
        informations: {
          type: 'string',
          title: 'Informazioni Aggiuntive'
        },
        cataster: {
          type: 'string',
          title: 'Valori catastali e tavolari'
        },
        power: {
          type: 'boolean',
          title: 'Corrente',
          enumNames: ['Si', 'No'],
          default: false
        },
        light: {
          type: 'boolean',
          title: 'Luce',
          enumNames: ['Si', 'No'],
          default: false
        },
        runningWater: {
          type: 'boolean',
          title: 'Acqua',
          enumNames: ['Si', 'No'],
          default: false
        },
        video: {
          type: 'string',
          title: 'Link Video',
          default: null
        }
      }
    }
  } else if (type === 'commercial') {
    return {
      type: 'object',
      required: ['rent', 'price'],
      properties: {
        property_id: {
          type: 'integer',
          title: 'Id',
          default: null
        },
        title: {
          type: 'object',
          title: 'Titolo',
          properties: {
            de: {
              title: 'Tedesco',
              type: 'string'
            },
            it: {
              title: 'Italiano',
              type: 'string'
            },
            en: {
              title: 'Inglese',
              type: 'string'
            }
          }
        },
        description: {
          type: 'object',
          title: 'Descrizione',
          properties: {
            de: {
              title: 'Tedesco',
              type: 'string'
            },
            it: {
              title: 'Italiano',
              type: 'string'
            },
            en: {
              title: 'Inglese',
              type: 'string'
            }
          }
        },
        type: {
          type: 'string',
          title: 'Tipologia',
          enum: types ? types.map((type) => type._id) : [],
          enumNames: types ? types.map((type) => type.name.it) : []
        },
        rent: {
          type: 'boolean',
          title: 'Affitto',
          enumNames: ['Affitto', 'Vendita'],
          default: false
        },
        floor: {
          type: 'integer',
          title: oneOf(exactType, ['hotel', 'industry']) ? 'Piani fuori terra' : 'Piano/i',
          default: 1
        },
        lowerFloors: oneOf(exactType, ['hotel', 'industry'])
          ? {
              type: 'integer',
              title: 'Piani sotto terra',
              default: -1
            }
          : {},
        lift:
          exactType !== 'hotel' && exactType !== 'warehouse'
            ? {
                type: 'boolean',
                title: 'Ascensore',
                default: false
              }
            : {},
        reachableTruck:
          exactType === 'workshop' || exactType === 'warehouse'
            ? {
                type: 'boolean',
                title: 'Raggiungibile in camion?',
                default: false
              }
            : {},
        orientation:
          exactType !== 'hotel' && exactType !== 'workshop' && exactType !== 'industry' && exactType !== 'warehouse'
            ? {
                type: 'array',
                title: 'Orientamento',
                items: {
                  enum: ['N', 'S', 'W', 'E', 'NE', 'NW', 'SE', 'SW'],
                  enumNames: ['Nord', 'Sud', 'Ovest', 'Est', 'Nord-Est', 'Nord-Ovest', 'Sud-Est', 'Sud-Ovest'],
                  default: 'S'
                }
              }
            : {},
        condition: {
          type: 'integer',
          title: 'Stato',
          enum: [1, 2, 3, 4], //Perfekt, gut, bewohnbar, zu renovieren
          enumNames: ['Perfetto', 'Buono', 'Abitabile', 'Da rinnovare'],
          default: 1
        },
        energyClass: {
          type: 'string',
          title: 'Consumo energetico',
          enum: ['gold', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'undefined'],
          enumNames: ['Oro', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'In fase di definizione'],
          default: 'G'
        },
        constructionYear: {
          type: 'integer',
          title: 'Anno di costruzione',
          default: new Date().getFullYear()
        },
        rooms:
          exactType !== 'hotel' && exactType !== 'industry'
            ? {
                type: 'object',
                title: 'Numero Vani',
                description: 'nested',
                properties: {
                  bath: {
                    type: 'integer',
                    title: 'Bagni'
                  },
                  wc: {
                    type: 'integer',
                    title: 'WC'
                  },
                  kitchen:
                    exactType === 'office'
                      ? {
                          type: 'integer',
                          title: 'Cucine separate'
                        }
                      : {},
                  seats:
                    exactType === 'bar' || exactType === 'restaurant' || exactType === 'office'
                      ? {
                          type: 'integer',
                          title: 'Posti da sedere interni'
                        }
                      : {},
                  externalSeats:
                    exactType === 'bar' || exactType === 'restaurant'
                      ? {
                          type: 'integer',
                          title: 'Posti da sedere esterni'
                        }
                      : {}
                }
              }
            : {},
        hotel:
          exactType === 'hotel'
            ? {
                type: 'object',
                title: 'Informazioni Hotel',
                description: 'nested',
                properties: {
                  stars: {
                    type: 'integer',
                    title: 'Stelle',
                    enum: [1, 2, 3, 4, 4.5, 5, 5.5],
                    enumNames: ['*', '**', '***', '****', '****S', '*****', '*****S'],
                    default: 3
                  },
                  barPlaces: {
                    type: 'integer',
                    default: 0,
                    title: 'Posti bar'
                  },
                  restaurantPlaces: {
                    type: 'integer',
                    default: 0,
                    title: 'Posti Ristorante'
                  },
                  spa: {
                    type: 'integer',
                    title: 'Area SPA',
                    default: 0
                  },
                  conferenceRooms: {
                    type: 'integer',
                    title: 'Area Sala Conferenze',
                    default: 0
                  },
                  sleepingPlaces: {
                    type: 'integer',
                    default: 0,
                    title: 'Posti Letto'
                  }
                }
              }
            : {},
        industry:
          exactType === 'industry'
            ? {
                type: 'object',
                title: 'Informazioni Immobile',
                properties: {
                  overGroundArea: {
                    title: 'Somma superfici lorde fuori terra',
                    type: 'number',
                    default: 0
                  },
                  underGroundArea: {
                    title: 'Somma superfici lorde sotto terra',
                    type: 'number',
                    default: 0
                  },
                  catastralArea: {
                    title: 'Superficie Catastrale lotto',
                    type: 'number',
                    default: 0
                  },
                  landUseZone: {
                    type: 'string',
                    default: '',
                    title: 'Zona piano urbanistico communale'
                  },
                  cubicIndex: {
                    type: 'number',
                    default: 0,
                    title: 'Indice di cubatura'
                  },
                  maxHeight: {
                    type: 'number',
                    default: 0,
                    title: 'Altezza massima edificio'
                  },
                  maxGroundCover: {
                    type: 'number',
                    default: 0,
                    title: 'Superficie copertura massima'
                  },
                  maxSoilSeal: {
                    type: 'number',
                    default: 0,
                    title: 'Superficie impermeabile massima'
                  }
                }
              }
            : {},
        furnishing: {
          type: 'string',
          title: 'Arredamento',
          enum: ['empty', 'part', 'full'],
          enumNames: ['Vuoto', 'Parzialmente Ammobiliato', 'Ammobiliato'],
          default: 'empty'
        },
        area:
          exactType !== 'industry'
            ? {
                type: 'integer',
                title:
                  exactType === 'warehouse' ? 'Superficie netta interna' : exactType === 'hotel' ? 'Superfici lorde fuori terra' : 'Superficie netta',
                default: 0
              }
            : {},
        exteriorArea: oneOf(exactType, ['warehouse', 'hotel'])
          ? {
              type: 'integer',
              title: exactType === 'warehouse' ? 'Superficie netta esterna' : 'Superfici lorde sotto terra'
            }
          : {},
        areas:
          exactType !== 'hotel' && exactType !== 'industry' && exactType !== 'workshop'
            ? {
                type: 'object',
                title: 'Superfici accessorie',
                description: 'nested',
                properties: {
                  cellar: {
                    type: 'object',
                    title: 'Cantine',
                    properties: {
                      amount: {
                        type: 'integer',
                        title: 'Numero'
                      },
                      area: {
                        type: 'integer',
                        title: 'Area'
                      }
                    }
                  }
                }
              }
            : {},
        commercialDescription:
          exactType !== 'hotel'
            ? {
                type: 'string',
                title:
                  exactType === 'store' || exactType === 'bar' || exactType === 'restaurant'
                    ? 'Descrizione della merce attualmente venduta'
                    : 'Descrizione dell`attivià attuale',
                default: ''
              }
            : {},
        commercialInfo:
          exactType !== 'office' && exactType !== 'warehouse'
            ? {
                type: 'object',
                title: 'Informazioni economiche',
                description: 'nested',
                properties: {
                  avgAnnualTurnover: {
                    type: 'integer',
                    default: 0,
                    title: 'Fatturato annuo medio (2 anni)'
                  },
                  avgAnnualProfit: {
                    type: 'integer',
                    default: 0,
                    title: 'Utile annuo medio (2 anni)'
                  }
                }
              }
            : {},
        sellType:
          exactType !== 'office'
            ? {
                type: 'string',
                title: rent ? 'Affitto' : 'Vendita',
                enum: rent ? ['activity', 'walls'] : ['both', 'activity', 'walls'],
                enumNames: rent ? ['Affitto attività', 'Affitto muri'] : ['Vendita muri e attività', 'Vendita attività', 'Vendita muri'],
                default: rent ? 'activity' : 'both'
              }
            : {},
        price: {
          type: 'integer',
          default: 0,
          title: 'Prezzo'
        },
        rentPrice: {
          type: 'number',
          title: 'Prezzo/Introito affitto'
        },
        maintenenceCost: {
          type: 'integer',
          default: 0,
          title: 'Spese annue di manutenzione'
        },
        parking: {
          title: 'Parcheggi',
          description: 'nested',
          type: 'object',
          properties: {
            garage: !oneOf(exactType, ['workshop', 'industry', 'warehouse'])
              ? {
                  title: 'Garage',
                  type: 'object',
                  properties: {
                    number: {
                      type: 'integer',
                      default: 0,
                      title: 'Numero'
                    }
                  }
                }
              : {},
            parkingLot: {
              title: 'Parcheggi Privati',
              type: 'object',
              properties: {
                number: {
                  type: 'integer',
                  title: 'Numero',
                  default: 0
                }
              }
            },
            truckLot: oneOf(exactType, ['workshop', 'industry', 'warehouse'])
              ? {
                  title: 'Parcheggi Camion',
                  type: 'object',
                  properties: {
                    number: {
                      type: 'integer',
                      title: 'Numero',
                      default: 0
                    }
                  }
                }
              : {}
          }
        },
        informations: {
          type: 'string',
          title: 'Informazioni Aggiuntive'
        },
        cataster: {
          type: 'string',
          title: 'Valori catastali e tavolari'
        },
        video: {
          type: 'string',
          title: 'Link Video',
          default: null
        }
      }
    }
  } else if (type === 'buildingland') {
    return {
      type: 'object',
      //TODO
      required: [],
      properties: {
        property_id: {
          type: 'integer',
          title: 'Id',
          default: null
        },
        title: {
          type: 'object',
          title: 'Titolo',
          properties: {
            de: {
              title: 'Tedesco',
              type: 'string'
            },
            it: {
              title: 'Italiano',
              type: 'string'
            },
            en: {
              title: 'Inglese',
              type: 'string'
            }
          }
        },
        description: {
          type: 'object',
          title: 'Descrizione',
          properties: {
            de: {
              title: 'Tedesco',
              type: 'string'
            },
            it: {
              title: 'Italiano',
              type: 'string'
            },
            en: {
              title: 'Inglese',
              type: 'string'
            }
          }
        },
        type: {
          type: 'string',
          title: 'Tipologia',
          enum: types ? types.map((type) => type._id) : [],
          enumNames: types ? types.map((type) => type.name.it) : []
        },
        catastralArea: {
          type: 'number',
          title: 'Superficie catastrale lotto (m²)'
        },
        cubic: {
          type: 'number',
          title: 'Cubatura massima (m³)'
        },
        grossArea:
          exactType === 'industrialbuildingland'
            ? {
                type: 'number',
                title: 'Somma massima di superfici lorde (m²)'
              }
            : {},
        landUseZone: {
          type: 'string',
          title: 'Zona piano urbanistico communale'
        },
        cubicIndex: {
          type: 'number',
          title: 'Indice di cubatura (m³/m²)'
        },
        surfaceIndex:
          exactType !== 'residentialbuildingland'
            ? {
                type: 'number',
                title: 'Indice di superfici lorde (m²/m²)'
              }
            : {},
        maxHeight: {
          type: 'number',
          title: 'Altezza massima edificio (m)'
        },
        maxGroundCover: {
          type: 'number',
          title: 'Superficie coperta massima (%)'
        },
        maxSoilSeal: {
          type: 'number',
          title: 'Superficie impermeabile massima (%)'
        },
        maxBeds:
          exactType === 'hotelbuildingland'
            ? {
                type: 'number',
                title: 'Nr. posti letto realizzabili'
              }
            : {},
        price: {
          type: 'number',
          title: 'Prezzo'
        },
        informations: {
          type: 'string',
          title: 'Informazioni Aggiuntive'
        },
        cataster: {
          type: 'string',
          title: 'Valori catastali e tavolari'
        },
        video: {
          type: 'string',
          title: 'Link Video',
          default: null
        }
      }
    }
  } else if (type === 'agriculture') {
    return {
      type: 'object',
      //TODO
      required: [],
      properties: {
        property_id: {
          type: 'integer',
          title: 'Id',
          default: null
        },
        title: {
          type: 'object',
          title: 'Titolo',
          properties: {
            de: {
              title: 'Tedesco',
              type: 'string'
            },
            it: {
              title: 'Italiano',
              type: 'string'
            },
            en: {
              title: 'Inglese',
              type: 'string'
            }
          }
        },
        description: {
          type: 'object',
          title: 'Descrizione',
          properties: {
            de: {
              title: 'Tedesco',
              type: 'string'
            },
            it: {
              title: 'Italiano',
              type: 'string'
            },
            en: {
              title: 'Inglese',
              type: 'string'
            }
          }
        },
        type: {
          type: 'string',
          title: 'Tipologia',
          enum: types ? types.map((type) => type._id) : [],
          enumNames: types ? types.map((type) => type.name.it) : []
        },
        rent: {
          type: 'boolean',
          title: 'Affitto',
          enumNames: ['Affitto', 'Vendita'],
          default: false
        },
        area: {
          type: 'number',
          title: 'Superficie terreno',
          default: 0
        },
        fountains: {
          type: 'number',
          title: 'Numero di fontane',
          default: 0
        },
        sprinklers: {
          type: 'boolean',
          title: 'Irrigazione',
          enumNames: ['Si', 'No'],
          default: false
        },
        hailnets: {
          type: 'boolean',
          title: 'Antigrandine',
          enumNames: ['Si', 'No'],
          default: false
        },
        cultivation: {
          type: 'array',
          title: 'Dettagli della coltivazione',
          items: {
            type: 'object',
            properties: {
              description: {
                type: 'string',
                title: 'Descrizione'
              },
              area: {
                type: 'integer',
                title: 'Area (ha)'
              }
            }
          }
        },
        price: {
          type: 'number',
          title: 'Prezzo',
          default: 0
        },
        informations: {
          type: 'string',
          title: 'Informazioni Aggiuntive'
        },
        cataster: {
          type: 'string',
          title: 'Valori catastali e tavolari'
        },
        video: {
          type: 'string',
          title: 'Link Video',
          default: null
        }
      }
    }
  }
}

export const propertyFormik = (id, rent) => {
  return {
    residential: {
      property_id: '',
      title: '',
      description: '',
      numOfUnits: id === 'townhouse' ? 3 : id === 'villa' ? undefined : 8,
      townhousePosition: id === 'townhouse' ? 'center' : undefined,
      minFloor: id === 'appartment' ? 1 : -1,
      maxFloor: id === 'appartment' ? 3 : 1,
      area: 0,
      nettoArea: id !== 'house' ? 0 : undefined,
      price: 0,
      furnishing: id !== 'house' ? 'empty' : undefined,
      heating: id === 'house' || id === 'villa' ? 'autonom' : 'central',
      heatingtype: undefined,
      garden: id !== 'house' ? { type: 'no', area: 0 } : undefined,
      conventionated: false,
      condition: '1',
      lift: false,
      orientation: [],
      energyClass: 'G',
      constructionYear: new Date().getFullYear(),
      fees: 0,
      rooms: id !== 'house' ? { room: 1, kitchen: 0, /* dining: 0, */ bath: 1, wc: 0 /* closet: 0, storage: 0 */ } : undefined,
      areas:
        id !== 'house'
          ? {
              cellar: { amount: 0, area: 0 },
              hobby: { amount: 0, area: 0 },
              underroof: { amount: 0, area: 0 },
              // coveredTerrace: { amount: 0, area: 0 },
              terrace: { amount: 0, area: 0 },
              balcony: { amount: 0, area: 0 },
              wintergarden: { amount: 0, area: 0 }
            }
          : undefined,
      parking: {
        garage: { number: 0, price: 0 },
        parkingLot: { number: 0, price: 0 },
        common: false,
        bike: false
      },
      informations: '',
      cataster: '',
      administrator: id === 'appartment' ? { name: '', tel: '', email: '' } : undefined,
      images: [],
      house:
        id === 'house'
          ? {
              purpose: ['residential'],
              overGroundArea: 0,
              underGroundArea: 0,
              catastralArea: 0,
              landUseZone: '',
              cubicIndex: 0,
              maxHeight: 0,
              maxGroundCover: 0,
              maxSoilSeal: 0
            }
          : undefined
    },
    garage: {
      property_id: '',
      title: '',
      description: '',
      area: 0,
      price: 0,
      rentPrice: rent === 'false' ? 0 : undefined,
      numOfPlaces: 0,
      floor: id === 'garage' ? -1 : undefined,
      floors: id === 'garage' ? -1 : undefined,
      constructionYear: new Date().getFullYear(),
      fees: 0,
      condition: '1',
      power: false,
      light: false,
      runningWater: false,
      informations: '',
      cataster: '',
      images: []
    },
    commercial: {
      property_id: '',
      title: '',
      description: '',
      floor: oneOf(id, ['hotel', 'industry']) ? 1 : 0,
      lowerFloors: oneOf(id, ['hotel', 'industry']) ? -1 : undefined,
      lift: id !== 'hotel' && id !== 'warehouse' ? false : undefined,
      reachableTruck: id === 'workshop' || id === 'warehouse' ? false : undefined,
      orientation: id !== 'hotel' && id !== 'workshop' && id !== 'industry' && id !== 'warehouse' ? ['S'] : undefined,
      condition: '1',
      energyClass: 'G',
      constructionYear: new Date().getFullYear(),
      rooms:
        id !== 'hotel' && id !== 'industry'
          ? {
              bath: 0,
              wc: 0,
              kitchen: id === 'office' ? 0 : undefined,
              seats: id === 'bar' || id === 'restaurant' || id === 'office' ? 0 : undefined,
              externalSeats: id === 'bar' || id === 'restaurant' ? 0 : undefined
            }
          : undefined,
      hotel: id === 'hotel' ? { stars: '3', barPlaces: 0, restaurantPlaces: 0, spa: 0, conferenceRooms: 0, sleepingPlaces: 0 } : undefined,
      industry:
        id === 'industry'
          ? {
              overGroundArea: 0,
              underGroundArea: 0,
              catastralArea: 0,
              landUseZone: '',
              cubicIndex: 0,
              maxHeight: 0,
              maxGroundCover: 0,
              maxSoilSeal: 0
            }
          : null,
      furnishing: 'empty',
      area: 0,
      exteriorArea: oneOf(id, ['warehouse', 'hotel']) ? 0 : undefined,
      areas: id !== 'hotel' && id !== 'industry' && id !== 'workshop' ? { cellar: { amount: 0, area: 0 } } : undefined,
      commercialDescription: '',
      commercialInfo: id !== 'office' && id !== 'warehouse' ? { avgAnnualTurnover: 0, avgAnnualProfit: 0 } : undefined,
      sellType: rent === 'false' ? 'both' : 'activity',
      price: 0,
      rentPrice: 0,
      maintenenceCost: 0,
      parking: {
        garage: id !== 'industry' && id !== 'warehouse' && id !== 'workshop' ? { number: 0 } : undefined,
        parkingLot: { number: 0 },
        truckLot: id === 'industry' || id === 'workshop' || id === 'warehouse' ? { number: 0 } : undefined
      },
      informations: '',
      cataster: '',
      images: []
    },
    buildingland: {
      property_id: '',
      title: '',
      description: '',
      catastralArea: 0,
      cubic: 0,
      grossArea: id === 'industrialbuildingland' ? 0 : undefined,
      landUseZone: '',
      cubicIndex: 0,
      surfaceIndex: id === 'residentialbuildingland' ? undefined : 0,
      maxHeight: 0,
      maxGroundCover: 0,
      maxSoilSeal: 0,
      maxBeds: id === 'hotelbuildingland' ? 0 : undefined,
      price: 0,
      informations: '',
      cataster: '',
      images: []
    },
    agriculture: {
      property_id: '',
      title: '',
      description: '',
      area: 0,
      fountains: 0,
      sprinklers: false,
      hailnets: false,
      cultivation: [],
      price: 0,
      informations: '',
      cataster: '',
      images: []
    }
  }
}

export const searchKeys = [
  'owner.name',
  'owner.familyName',
  'externalAgent.name',
  'externalAgent.familyName',
  'location.address.municipality.name.it',
  'location.address.municipality.name.de',
  'type.name.it',
  'name',
  'familyName',
  'tel',
  'email',
  '_id',
  'property_id',
  'location.address.street.it',
  'location.address.street.de'
]
