import React from 'react'
import { Box, Text, Button, Heading } from 'grommet'
import { FormattedNumber, FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ReactSVG } from 'react-svg'

const SearchCard = (props) => {
  const { _id, client, budget, locations, type, rooms } = props.search

  const href = props.isAdmin ? `/admin/searches/${client._id}/${_id}` : `/find/searches/${_id}`

  const onEditClick = (e) => {
    e.preventDefault()
    props.onEdit && props.onEdit()
  }

  const onDeleteClick = (e) => {
    e.preventDefault()
    props.onDelete && props.onDelete()
  }

  const onClick = () => {
    props.onClick && props.onClick(href)
  }

  return (
    <div className="ui-property-card w-100 mb-3">
      <Box className="ui-property-card__box  mb-2" direction="row">
        <Box pad="small">
          <ReactSVG src={`/website/types/${type.id}.svg`} />
        </Box>
        <Box pad="medium" direction="row" justify="between" width="100%" onClick={onClick}>
          <div className="d-flex flex-column flex-grow-1">
            <Heading level="3">{type.name[props.language]}</Heading>
            <div className="w-100 d-flex flex-column justify-content-between pb-2">
              {locations.map((location) => (
                <Text key={location.name} size="small">
                  {location.name}
                </Text>
              ))}
            </div>
            {rooms && rooms.bed && rooms.bed > 0 && (
              <div className="w-100 d-flex flex-column justify-content-between pb-2">
                <FormattedMessage id="admin.property.rooms.room" /> {rooms.bed}
              </div>
            )}
            <div className="w-100 d-flex justify-content-between pt-1"></div>
          </div>
          <div className="d-flex flex-column align-items-end pb-2">
            <div className="w-100 d-flex justify-content-end pb-2">
              {props.onEdit && <Button className="p-0 pl-2 pr-2" icon={<FontAwesomeIcon icon={faEdit} />} onClick={onEditClick} />}
              {props.onDelete && <Button className="p-0 pr-1" icon={<FontAwesomeIcon icon={faTrash} />} onClick={onDeleteClick} />}
            </div>
            <Heading level="4">{<FormattedNumber value={budget || 0} format="EUR" />}</Heading>
          </div>
        </Box>
      </Box>
    </div>
  )
}

export default SearchCard
