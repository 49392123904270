import React, { Component } from 'react'
import { FormField, Box } from 'grommet'
import { injectIntl, FormattedMessage } from 'react-intl'
import FormLabel from './FormLabel'
import RadioButton from './RadioButton'
import { ReactSVG } from 'react-svg'
import FormattedInput from './FormattedInput'
import TextInput from './TextInput'

/**
 * this component is used to enter all informations that regard the house, and is also used to enter
 * Industry type informations, with the `hidePurpose` flag on true
 *
 * @class HouseValues
 */
class HouseValues extends Component {
  constructor(props) {
    super(props)

    this.state = props.value
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
    this.props.onChange(this.state)
  }

  onChangeValue = (name, value) => {
    this.setState({ [name]: value })
    this.props.onChange(this.state)
  }

  onFocus = event => {
    if (event.target.value === '0') {
      this.setState({ [event.target.name]: '' })
    }
  }

  onBlur = event => {
    if (event.target.value === '') {
      this.setState({ [event.target.name]: 0 })
    }
  }

  onChangeRadio = event => {
    const value = event.target.value
    const { purpose } = this.state
    const index = purpose.indexOf(value)

    if (index < 0) {
      purpose.push(value)
    } else if (purpose.length > 0) {
      purpose.splice(index, 1)
    }
    this.setState({ purpose })

    this.props.onChange(this.state)
  }

  render() {
    const { intl, isReadOnly, hidePurpose } = this.props
    const values = this.state

    return (
      <div className="d-flex flex-column">
        {!hidePurpose && (
          <FormField label={intl.formatMessage({ id: 'admin.property.purpose' })} htmlFor="purpose">
            <div className="d-flex flex-row justify-content-between flex-wrap">
              <RadioButton
                name="purpose"
                value="residential"
                checked={values.purpose.indexOf('residential') > -1}
                onChange={this.onChangeRadio}
                label={<FormattedMessage id="admin.property.purpose.residential" />}
                labelSize={isReadOnly ? 'xsmall' : null}
                disabled={isReadOnly}
              />
              <RadioButton
                name="purpose"
                value="service"
                checked={values.purpose.indexOf('service') > -1}
                onChange={this.onChangeRadio}
                label={<FormattedMessage id="admin.property.purpose.service" />}
                labelSize={isReadOnly ? 'xsmall' : null}
                disabled={isReadOnly}
              />
              <RadioButton
                name="purpose"
                value="productive"
                checked={values.purpose.indexOf('productive') > -1}
                onChange={this.onChangeRadio}
                label={<FormattedMessage id="admin.property.purpose.productive" />}
                labelSize={isReadOnly ? 'xsmall' : null}
                disabled={isReadOnly}
              />
            </div>
          </FormField>
        )}
        <FormField label={<FormLabel label="admin.property.overGroundArea"></FormLabel>} htmlFor="overGroundArea">
          <Box align="center" direction="row" alignContent="center">
            <ReactSVG src="/website/area-inner.svg" />

            <FormattedInput
              suffix={'m²'}
              type="number"
              name="overGroundArea"
              onChange={value => this.onChangeValue('overGroundArea', value)}
              value={values.overGroundArea}
            ></FormattedInput>
          </Box>
        </FormField>
        <FormField label={<FormLabel label="admin.property.underGroundArea"></FormLabel>} htmlFor="underGroundArea">
          <Box align="center" direction="row" alignContent="center">
            <ReactSVG src="/website/area-inner.svg" />

            <FormattedInput
              suffix={'m²'}
              type="number"
              name="underGroundArea"
              onChange={value => this.onChangeValue('underGroundArea', value)}
              value={values.underGroundArea}
            ></FormattedInput>
          </Box>
        </FormField>
        <FormField label={<FormLabel label="admin.property.catastralArea"></FormLabel>} htmlFor="catastralArea">
          <Box align="center" direction="row" alignContent="center">
            <ReactSVG src="/website/area-outer.svg" />
            <FormattedInput
              suffix={'m²'}
              type="number"
              name="catastralArea"
              onChange={value => this.onChangeValue('catastralArea', value)}
              value={values.catastralArea}
            ></FormattedInput>
          </Box>
        </FormField>

        <FormField label={<FormLabel label="admin.property.landUseZone" icon="" />} htmlFor="landUseZone">
          <TextInput type="text" name="landUseZone" onChange={this.onChange} value={values.landUseZone}></TextInput>
        </FormField>

        <FormField label={<FormLabel label="admin.property.cubicIndex" icon="" />} htmlFor="cubicIndex">
          <FormattedInput
            suffix={'m³/m²'}
            type="number"
            name="cubicIndex"
            onChange={value => this.onChangeValue('cubicIndex', value)}
            value={values.cubicIndex}
          ></FormattedInput>
        </FormField>

        <FormField label={<FormLabel label="admin.property.maxHeight" icon="" />} htmlFor="maxHeight">
          <FormattedInput
            suffix={'m'}
            type="number"
            name="maxHeight"
            onChange={value => this.onChangeValue('maxHeight', value)}
            value={values.maxHeight}
          ></FormattedInput>
        </FormField>

        <FormField label={<FormLabel label="admin.property.maxGroundCover" icon="" />} htmlFor="maxGroundCover">
          <FormattedInput
            suffix={'%'}
            type="number"
            name="maxGroundCover"
            onChange={value => this.onChangeValue('maxGroundCover', value)}
            value={values.maxGroundCover}
          ></FormattedInput>
        </FormField>

        <FormField label={<FormLabel label="admin.property.maxSoilSeal" icon="" />} htmlFor="maxSoilSeal">
          <FormattedInput
            suffix={'%'}
            type="number"
            name="maxSoilSeal"
            onChange={value => this.onChangeValue('maxSoilSeal', value)}
            value={values.maxSoilSeal}
          ></FormattedInput>
        </FormField>
      </div>
    )
  }
}

export default injectIntl(HouseValues)
