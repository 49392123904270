import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Container } from 'react-bootstrap'
import { Heading, Text, Button } from 'grommet'

import SearchForm from 'components/SearchForm'
import { ReactSVG } from 'react-svg'
import { authFetch } from 'utils/website-utils'
import NewClientDialog from 'components/NewClientDialog'
import Spinner from 'components/Spinner'
import { logError } from 'utils/sentry-log'
import DummySearches from 'components/DummySearches'

class Find extends Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      me: null,
      showUserDialog: false,
      isLoading: true
    }

    this.createSearch = this.createSearch.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async componentDidMount() {
    const { auth } = this.props

    if (auth.isWebsiteAuth) {
      try {
        const response = await authFetch('get', '/api/v1/account/me')
        if (!response || !response.data || response.status !== 200) throw new Error('[FIND] fetch me')

        this.setState({ me: response.data })
      } catch (e) {
        logError(e)
      }
    }

    this.setState({ isLoading: false })
    const response = await authFetch(
      'get',
      '/api/v1/properties/residential?limit=5&populate=[{"path":"owner"},{"path":"externalAgent"},{"path":"type"},{"path":"location.zone"},{"path":"plans"},{"path":"images"},{"path":"mainImage"}]&query={"isConfirmed":"true"}'
    )
    this.setState({ properties: response.data })
  }

  async handleSubmit(values) {
    const { me } = this.state
    this.setState({ values })
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (me && me.seller && !me.client) {
      const client = { ...me.seller }
      delete client._id
      const response = await authFetch('post', '/api/v1/clients', client)
      this.createSearch(response.data)
    } else if (!me || !me.client) {
      // show dialog
      this.setState({ showUserDialog: true })
    } else {
      this.createSearch(me.client)
    }
  }

  async createSearch(client) {
    this.setState({ showUserDialog: false, isLoading: true })
    const { values } = this.state
    const { history } = this.props

    try {
      const payload = { ...values, client: client && client._id }
      const response = await authFetch('post', '/api/v1/searches', payload)
      if (!response || !response.data || response.status !== 201) throw new Error('[FIND] create search')

      const search = response.data

      return history.push(`/find/searches/${search._id}`)
    } catch (e) {
      this.setState({ isLoading: false })
      logError(e)
    }
  }

  render() {
    const { step, showUserDialog, me, isLoading, properties } = this.state
    const { intl } = this.props

    return (
      <Container className="main-container sell pb-5 d-flex flex-column align-items-center">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Heading level="2" className="mt-3 mb-3">
              <FormattedMessage id="website.find.title"></FormattedMessage>
            </Heading>
            {!showUserDialog && (
              <>
                {step === 1 && (
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <ReactSVG
                      src="/website/search.svg"
                      beforeInjection={svg => svg.setAttribute('style', 'width: 200px; height: 200px; padding-bottom: 8px;')}
                    />
                    <Text className="mt-4 mb-2">
                      <FormattedMessage id="website.find.text"></FormattedMessage>
                    </Text>
                    <div className="d-flex w-100 justify-content-center pt-4 pb-3">
                      <Button
                        onClick={() => this.props.history.push('/search')}
                        label={intl.formatMessage({ id: 'website.find.actionSearch' })}
                      ></Button>
                    </div>
                    <Button
                      className="mt-3"
                      primary
                      label={<FormattedMessage id="website.find.action" />}
                      onClick={() => this.setState({ step: 2 })}
                    ></Button>
                  </div>
                )}
                {step === 2 && <SearchForm onSubmit={this.handleSubmit} />}
              </>
            )}
            {showUserDialog && <DummySearches properties={properties} />}
            <NewClientDialog user={me} onSave={this.createSearch} show={showUserDialog} />
          </>
        )}
      </Container>
    )
  }
}

Find.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(injectIntl(withRouter(Find)))
