export const STATES = ['not_interested', 'wait', 'new', 'consulting', 'visit']

export const sortMatches = matches => {
  return (
    matches &&
    matches.sort((a, b) => {
      const stateSort = STATES.indexOf(b.state) - STATES.indexOf(a.state)
      if (stateSort === 0) {
        return a.property.rooms.living + a.property.rooms.bed - (b.property.rooms.living + b.property.rooms.bed)
      }
      return stateSort
    })
  )
}
