import React from 'react'
import { Box, Text, Button } from 'grommet'
import { FormattedNumber, FormattedMessage } from 'react-intl'
import { ReactSVG } from 'react-svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faQuestionCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import LazyLoad from 'react-lazy-load'

import PropertyCardRooms from './PropertyCardRooms'
import ImageLoader from './ImageLoader'

const PropertyCard = (props) => {
  const { _id, images, type, isConfirmed, price, rooms, rent, location, hotel, mainImage, property_id } = props.property

  const numOfRooms = type.type === 'residential' ? rooms.living + rooms.bed + rooms.dining : type.id === 'hotel' ? hotel.sleepingPlaces : null
  const numOfBaths = type.type === 'residential' ? rooms.wc + rooms.bath : null

  // set the language to 'it' if it is 'en' because we display italian municipality names when en is selected
  const language = props.language === 'en' ? 'it' : props.language
  const href = props.isAdmin && !isConfirmed ? `/admin/properties/${type.type}/${_id}` : isConfirmed ? `/property/${type.type}/${_id}` : null

  const mainCardImage = mainImage ? mainImage.url : images.length > 0 ? images[0].url : '/website/sale.svg'

  const onEditClick = (e) => {
    e.preventDefault()
    props.onEdit && props.onEdit(props.property)
  }

  const onDeleteClick = (e) => {
    e.preventDefault()
    props.onDelete && props.onDelete(props.property)
  }

  return (
    <a className="ui-property-card w-100 mb-3" href={href} target={props.target}>
      <Box className="ui-property-card__box  mb-2" direction="row">
        <ImageLoader fit={images.length > 0 ? 'cover' : 'contain'} src={mainCardImage} alt={'Immobilie'} height={250} width={300} />
        <Box pad="small" width="100%">
          <div className="d-flex justify-content-between flex-column flex-grow-1">
            <div className="w-100 d-flex justify-content-end pb-2">
              <Text size="small">Nr. {property_id}</Text>
              {props.isEdit && <Button className="p-0 pl-2 pr-2" icon={<FontAwesomeIcon icon={faEdit} />} onClick={onEditClick} />}
              {props.isEdit && <Button className="p-0 pr-1" icon={<FontAwesomeIcon icon={faTrash} />} onClick={onDeleteClick} />}
            </div>

            <div className="d-flex justify-content-end flex-column flex-grow-1">
              <Text size="large" weight="bold" className="pb-1">
                {type.name[props.language]}
              </Text>
              <div className="w-100 d-flex justify-content-between pb-2">
                <Text size="medium">
                  {location.address.municipality ? location.address.municipality.name[props.language === 'en' ? 'it' : props.language] + '' : ''},{' '}
                  {location.zone ? location.zone.properties.name[language] : ''}
                </Text>
              </div>
              <div className="ui-property-card__rooms w-100 d-flex flex-wrap">
                <PropertyCardRooms property={props.property} />
              </div>
              <div className="w-100 d-flex justify-content-between">
                <Text size="small">
                  <FormattedMessage id={`admin.property.rent.${rent}`} />
                </Text>
              </div>
              <div className="w-100 d-flex justify-content-between pt-1">
                <Text size="xlarge" color="brand" className="d-flex align-items-center">
                  <FormattedNumber value={price || 0} format="EUR" />
                </Text>
                <div className="d-flex flex-row">
                  {numOfRooms > 0 && (
                    <div className="d-flex flex-row pr-2 align-items-center">
                      <ReactSVG
                        src="/website/room.svg"
                        beforeInjection={(svg) => svg.setAttribute('style', 'width: 24px; height: 24px')}
                        className="mr-2"
                      />
                      <Text>{numOfRooms}</Text>
                    </div>
                  )}
                  {numOfBaths > 0 && (
                    <div className="d-flex flex-row pr-2 align-items-center">
                      <ReactSVG
                        src="/website/bath.svg"
                        beforeInjection={(svg) => svg.setAttribute('style', 'width: 24px; height: 24px')}
                        className="mr-2"
                      />
                      <Text>{numOfBaths}</Text>
                    </div>
                  )}
                </div>
              </div>
              {!props.isSearch && (
                <div className="d-flex flex-row align-items-center">
                  <FormattedMessage id="admin.property.confirmed" />
                  {isConfirmed ? (
                    <FontAwesomeIcon icon={faCheckCircle} color="#2ecc71" className="ml-2" />
                  ) : (
                    <FontAwesomeIcon icon={faQuestionCircle} color="#ffc107" className="ml-2" />
                  )}
                </div>
              )}
            </div>
          </div>
        </Box>
      </Box>
    </a>
  )
}

export default PropertyCard
